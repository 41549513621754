import {ApiPaths, ApiService, SetAuthToken, ApiStatus} from '../../utils/ApiService';
import {GET_PROFITABLE_REPORTS, GET_LIASONPROCESSOWNER, GET_PROJECT_NAMES, GET_TASK_DELAYS, GET_PROFITABLE_REPORT_COUNT, GET_PENDING_TASK, GET_LIAISON_PROJECT_NAMES, GET_DASHBOARD_DATA, GET_USER_DASHBOARD_DATA, GET_USER_DASHBOARD_TASK,GET_CIVIL_PROCESS_DATA,GET_ENQUIRY_SOURCE} from './types';
import {mainLoadingTrue, mainLoadingFalse} from '../User/action'
import { toast } from 'react-toastify';
import {getItem, setItem} from '../../utils/crypto';

const userLocal = getItem('enuseloc');
const LoginuserId = userLocal && Object.keys(userLocal).length > 0 ? userLocal['user_id'] : null;

export const getProfitableReports_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.PROFITABLE_REPORTS, data).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(profitableReportsSuccess(data.process))
                dispatch(profitableReportCountSuccess(data.totalCount))
            }
            else{
                dispatch(profitableReportsSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(profitableReportsSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const profitableReportsSuccess = (data) => {
    return {
        type : GET_PROFITABLE_REPORTS,
        payload : data
    }
}
export const profitableReportCountSuccess = (data) => {
    return {
        type : GET_PROFITABLE_REPORT_COUNT,
        payload : data
    }
}


export const getLiasonProcessOwner_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.LIASONPROCESSOWNER, data).then(response => {
            const {data}  = response;
            if(!data.error){
                dispatch(getLiasonProcessOwnerSuccess(data.user))
            }
            else{
                dispatch(getLiasonProcessOwnerSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getLiasonProcessOwnerSuccess([]))
            dispatch(mainLoadingFalse());
        }).finally(()=>{
            dispatch(mainLoadingFalse());
        })
    }
}


export const getLiasonProcessOwnerSuccess = (data) => {
    return {
        type : GET_LIASONPROCESSOWNER,
        payload : data
    }
}


export const getProjectsNames_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GETPROJECTS, data).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getProjectsNamesSuccess(data.project))
            }
            else{
                dispatch(getProjectsNamesSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getProjectsNamesSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getProjectsNamesSuccess = (data) => {
    return {
        type : GET_PROJECT_NAMES,
        payload : data
    }
}

export const getLiaisonProjectsNames_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GETLIAISONPROJECTS, data).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getLiaisonProjectsNamesSuccess(data.project))
            }
            else{
                dispatch(getLiaisonProjectsNamesSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getLiaisonProjectsNamesSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getLiaisonProjectsNamesSuccess = (data) => {
    return {
        type : GET_LIAISON_PROJECT_NAMES,
        payload : data
    }
}


export const getTaskDelayReason_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GETTASKDELAY, data).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getTaskDelayReasonSuccess(data.reason))
            }
            else{
                dispatch(getTaskDelayReasonSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getTaskDelayReasonSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getTaskDelayReasonSuccess = (data) => {
    return {
        type : GET_TASK_DELAYS,
        payload : data
    }
}


export const getPendingTasks_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GETPENDINGTASK, data).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getPendingTasksSuccess(data.tasks))
            }
            else{
                dispatch(getPendingTasksSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getPendingTasksSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getPendingTasksSuccess = (data) => {
    return {
        type : GET_PENDING_TASK,
        payload : data
    }
}

export const getDashboardData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GETDASHBOARDDATA, initialPayload).then(response => {
            const {data}  = response;
            console.log('DashboardData', data.dashboardData);
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getDashboardDataSuccess(data.dashboardData))
            }
            else{
                dispatch(getDashboardDataSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getDashboardDataSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getDashboardDataSuccess = (data) => {
    return {
        type : GET_DASHBOARD_DATA,
        payload : data
    }
}


export const getMyDashBoardData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "reportUserID":LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_MY_DASHBOARD_DATA, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getMyDashBoardDataSuccess(data))
            }
            else{
                dispatch(getMyDashBoardDataSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getMyDashBoardDataSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getMyDashBoardDataSuccess = (data) => {
    return {
        type : GET_USER_DASHBOARD_DATA,
        payload : data
    }
}

export const getMyDashBoardTask_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "reportUserID":LoginuserId,
        "projectFundID": "",
        "status": "",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_MY_DASHBOARD_TASK, initialPayload).then(response => {
            const {data}  = response;

            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getMyDashBoardTaskSuccess(data))
            }
            else{
                dispatch(getMyDashBoardTaskSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getMyDashBoardTaskSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getMyDashBoardTaskSuccess = (data) => {

    return {
        type : GET_USER_DASHBOARD_TASK,
        payload : data
    }
}


export const getCivilProcessData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectId":"",
        "processId": [],
        "fromDate": "",
        "toDate": "",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": 0,
        "iDisplayLength": 10,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_CIVIL_PROCESS_DATA, initialPayload).then(response => {
            const {data}  = response;

            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getCivilProcessDataSuccess(data?.dashboardData))
            }
            else{
                dispatch(getCivilProcessDataSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCivilProcessDataSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getCivilProcessDataSuccess = (data) => {

    return {
        type : GET_CIVIL_PROCESS_DATA,
        payload : data
    }
}
export const getEnquirySource_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "groupID": "",
        "categoryID": "",
        "sourceStatus": "",
        "sortOrder": "",
        "generalSearch": "",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_ENQUIRY_SOURCE, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getEnquirySourceSuccess(data.data))
            }
            else{
                dispatch(getEnquirySourceSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getEnquirySourceSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getEnquirySourceSuccess = (data) => {
    return {
        type : GET_ENQUIRY_SOURCE,
        payload : data
    }
}

