import * as React from 'react';
export const routes = [
	
	{
		path: '/',
		Component: React.lazy(() => import('../../Pages/Dashboard/UserDashboard')),
		exact: true,
		isNavbar : true
	},

	{
		path: '/dashboard/userDashboard',
		Component: React.lazy(() => import('../../Pages/Dashboard/UserDashboard')),
		exact: true,
		isNavbar : true
	},

	{
		path: '/dashboard/sales',
		Component: React.lazy(() => import('../../Pages/Dashboard/Sales')),
		exact: true,
		isNavbar : true
	},	
	{
		path: '/dashboard/crm',
		Component: React.lazy(() => import('../../Pages/Dashboard/Crm')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/dashboard/marketing',
		Component: React.lazy(() => import('../../Pages/Dashboard/Marketing')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/dashboard/procurment',
		Component: React.lazy(() => import('../../Pages/Dashboard/Procurment')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/dashboard/civil',
		Component: React.lazy(() => import('../../Pages/Dashboard/Civil')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/dashboard/civil-progress',
		Component: React.lazy(() => import('../../Pages/Dashboard/CivilProgress')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/dashboard/civil-process-progress',
		Component: React.lazy(() => import('../../Pages/Dashboard/CivilProcessProgress')),
		exact: true,
		isNavbar : true
	},






	{
		path: '/profitable-report',
		Component: React.lazy(() => import('../../Pages/Reports')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/liaison',
		Component: React.lazy(() => import('../../Pages/Liaison')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/login',
		Component: React.lazy(() => import('../../Pages/Login')),
		exact: true,
		isNavbar : false
	},
	{
		path: '/nextHearing/:reportDate',
		Component: React.lazy(() => import('../../Pages/Reports/CourtCase')),
		exact: true,
		isNavbar : false
	},
	{
		path: '/courtCase/:reportDate',
		Component: React.lazy(() => import('../../Pages/Reports/CourtReminder')),
		exact: true,
		isNavbar : false
	},
	{
		path: '/liaison-reports/:reportDate',
		Component: React.lazy(() => import('../../Pages/Reports/Liaison')),
		exact: true,
		isNavbar : false
	},
	{
		path: '/liaison-fallowups-reports/:reportDate',
		Component: React.lazy(() => import('../../Pages/Reports/Task')),
		exact: true,
		isNavbar : false
	},
	{
		path: '/liaison_kns_report/:reportDate',
		Component: React.lazy(() => import('../../Pages/Reports/LiaisonKnsReport')),
		exact: true,
		isNavbar : false
	},
	{
		path: '/liaison_shc_report/:reportDate',
		Component: React.lazy(() => import('../../Pages/Reports/liaisonShcReport')),
		exact: true,
		isNavbar : false
	},
	{
		path: '/liaison_all_report/:reportDate',
		Component: React.lazy(() => import('../../Pages/Reports/LiaisonAllReport')),
		exact: true,
		isNavbar : false
	},
	{
		path: '/liaison_own_report/:reportDate/:processOwnerID',
		Component: React.lazy(() => import('../../Pages/Reports/LiaisonOwnReport')),
		exact: true,
		isNavbar : false
	},
	{
		path: '/profitable',
		Component: React.lazy(() => import('../../Pages/Reports/Profitable')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/task-wise-quantity/:reportDate',
		Component: React.lazy(() => import('../../Pages/Reports/TaskWiseQuantity')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/civil/progress',
		Component: React.lazy(() => import('../../Pages/Civil/ProjectProgress')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/civil/budget',
		Component: React.lazy(() => import('../../Pages/Civil/ProjectBudget')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/civil/resource-nmr-planning',
		Component: React.lazy(() => import('../../Pages/Civil/ManPowerPlanning')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/civil/resource-nmr-planning-details',
		Component: React.lazy(() => import('../../Pages/Civil/ManPowerDetailPlanning')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/civil/resource-machine-planning',
		Component: React.lazy(() => import('../../Pages/Civil/MachinePlanning')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/civil/resource-machine-planning-details',
		Component: React.lazy(() => import('../../Pages/Civil/MachinDetailPlanning')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/civil/resource-type-planning',
		Component: React.lazy(() => import('../../Pages/Civil/MachinePlanning')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/civil/resource-planning',
		Component: React.lazy(() => import('../../Pages/Civil/ResourcePlanning')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/proposed',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProposedProject')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/up-coming',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/UpcomingProject')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/up-coming/ReviewForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/UpcomingProject/ReviewForm')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/up-coming/editForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/UpcomingProject/editForm')),
		exact: true,
		isNavbar : true
	},

	{
		path: '/business-development/on-going',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/OngoingProject')),
		exact: true,
		isNavbar : true
	},

	{
		path: '/business-development/on-going/ReviewForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/OngoingProject/ReviewForm')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/on-going/editForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/OngoingProject/editForm')),
		exact: true,
		isNavbar : true
	},

	{
		path: '/business-development/completed',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/CompletedProject')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/completed/ReviewForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/CompletedProject/ReviewForm')),
		exact: true,
		isNavbar : true
	},
		{
		path: '/business-development/completed/editForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/CompletedProject/editForm')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/dropped',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/DroppedProject')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/dropped/ReviewForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/DroppedProject/ReviewForm')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/dropped/editForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/DroppedProject/editForm')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/proposed/new',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProposedProject/form')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/flow',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectFlow')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/inspection',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectInspection')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/plan',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectPlan')),
		exact: true,
		isNavbar : true
	},

	{
		path: '/business-development/plan/view',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectPlan/ReviewForm')),
		exact: true,
		isNavbar : true
	},


	{
		path: '/business-development/task-plan',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectTaskPlan')),
		exact: true,
		isNavbar : true
	},

	{
		path: '/business-development/task-plan/view',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectTaskPlan/ReviewForm')),
		exact: true,
		isNavbar : true
	},


	{
		path: '/business-development/approval-pending',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectTaskApprovalPending')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/project-file',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectSurveyNo')),
		exact: true,
		isNavbar : true
	},

	{
		path: '/business-development/project-file/ProjectSurveyNoForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectSurveyNo/ProjectSurveyNoForm')),
		exact: true,
		isNavbar : true
	},

	{
		path: '/business-development/project-file/editForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectSurveyNo/editForm')),
		exact: true,
		isNavbar : true
	},

	{
		path: '/business-development/project-file/Review',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectSurveyNo/Review')),
		exact: true,
		isNavbar : true
	},
	







	{
		path: '/business-development/liason-process-plan',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiasonProcessPlan')),
		exact: true,
		isNavbar : true
	},

	{
		path: '/business-development/liason-process-plan/view',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiasonProcessPlan/ReviewForm')),
		exact: true,
		isNavbar : true
	},


	{
		path: '/business-development/liason-process-plan-application/applicationSplitModal',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiasonProcessPlanApplication/ApplicationSplitModal')),
		exact: true,
		isNavbar : true
	},

	{
		path: '/business-development/liason-process-plan/StartDateEditModal',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiasonProcessPlan/StartDateEditModal')),
		exact: true,
		isNavbar : true
	},



	{
		path: '/business-development/liason-process-plan-application',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiasonProcessPlanApplication')),
		exact: true,
		isNavbar : true
	},

	{
		path: '/business-development/liason-process-plan-application/view',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiasonProcessPlanApplication/ReviewForm')),
		exact: true,
		isNavbar : true
	},


	{
		path: '/business-development/liason-task-plan',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiasonTaskPlan')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/liason-documentation',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiasonDocumentation')),
		exact: true,
		isNavbar : true
	},

	{
		path: '/business-development/liason-documentation/view',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiasonDocumentation/ReviewForm')),
		exact: true,
		isNavbar : true
	},

	{
		path: '/business-development/liason-documentation/documentCheckList',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiasonDocumentation/DocumentCheckListForm')),
		exact: true,
		isNavbar : true
	},


	{
		path: '/business-development/liason-documentation/ownerAssign',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiasonDocumentation/OwnerAssignForm')),
		exact: true,
		isNavbar : true
	},



	{
		path: '/business-development/liason-documentation-approval',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiasonDocumentationApproval')),
		exact: true,
		isNavbar : true
	},

	{
		path: '/business-development/liason-documentation-approval/approval',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiasonDocumentationApproval/ApprovalModal')),
		exact: true,
		isNavbar : true
	},

	{
		path: '/business-development/project-survey/new',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectSurveyNo/form')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/task-plan/approvals',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectTaskPlan/ReviewForm')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/survey-no-pending',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/SurveyNumberTaskPending')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/survey-no/approvals',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/SurveyNumberTaskPending/ReviewForm')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/liaison-Process-Applicability',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonProcessApplicability/form')),
		exact: true,
		isNavbar : true
	},

	
	
	{
		path: '/business-development/task-plan-document',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/TaskPlanDocument/form')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/bd-profitable',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/Profitable')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/notifications',
		Component: React.lazy(() => import('../../Pages/Notifications')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/milestone',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/MileStone')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/authorization',
		Component: React.lazy(() => import('../../Pages/Authorization')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/authorization/new',
		Component: React.lazy(() => import('../../Pages/Authorization/form')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/land-owner',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandOwner')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/land-owner/new',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandOwner/form')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/land-owner/editForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandOwner/editForm')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/land-owner/details',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandOwner/ReviewForm')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/land-owner/newAccounts',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandOwner/landOwnerAccountModal')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/land-owner/accountEdit',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandOwner/landOwnerAccountEditModal')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/project-file-acquisition-info',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectFIleAcquisitionInfo')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/project-file-acquisition-info/new',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectFIleAcquisitionInfo/form')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/project-file-acquisition-info/approvals',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectFIleAcquisitionInfo/ReviewForm')),
		exact: true,
		isNavbar : true
	},

	{
		path: '/business-development/project-file-acquisition-info/editForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectFIleAcquisitionInfo/editForm')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/land-broker',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandBroker')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/land-broker/new',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandBroker/form')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/land-broker/details',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandBroker/ReviewForm')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/land-broker/accountEdit',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandBroker/landBrokerAccountEditModal')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/land-broker/newAccounts',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandBroker/landBrokerAccountModal')),
		exact: true,
		isNavbar : true
	},

	{
		path: '/business-development/land-broker/editForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandBroker/editForm')),
		exact: true,
		isNavbar : true
	},
	
	
	{
		path: '/business-development/feasibility-report',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/FeasibilityReport')),
		exact: true,
		isNavbar : true
	},

	{
		path: '/business-development/feasibility-report/new',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/FeasibilityReport/form')),
		exact: true,
		isNavbar : true
	},

	{
		path: '/business-development/feasibility-report/view',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/FeasibilityReport/ReviewForm')),
		exact: true,
		isNavbar : true
	},

	{
		path: '/business-development/feasibility-report/approval',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/FeasibilityReport/Approval')),
		exact: true,
		isNavbar : true
	},

	{
		path: '/business-development/feasibility-report/printPdf',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/FeasibilityReport/printPdf')),
		exact: true,
		isNavbar : true
	},

	{
		path: '/business-development/feasibility-report-general',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/FeasibilityReportGeneral')),
		exact: true,
		isNavbar : true
	},

	{
		path: '/business-development/feasibility-report-general/new',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/FeasibilityReportGeneral/form')),
		exact: true,
		isNavbar : true
	},

	{
		path: '/business-development/feasibility-report-general/view',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/FeasibilityReportGeneral/ReviewForm')),
		exact: true,
		isNavbar : true
	},


	{
		path: '/business-development/project-fund',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectFund')),
		exact: true,
		isNavbar : true
	},

	{
		path: '/business-development/project-fund/new',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectFund/form')),
		exact: true,
		isNavbar : true
	},

	{
		path: '/business-development/project-fund/Review',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectFund/ReviewForm')),
		exact: true,
		isNavbar : true
	},


	{
		path: '/business-development/project-broker',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectBroker')),
		exact: true,
		isNavbar : true
	},

	{
		path: '/business-development/project-broker/ReviewForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectBroker/ReviewForm')),
		exact: true,
		isNavbar : true
	},



	{
		path: '/business-development/land-payment',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandPayment')),
		exact: true,
		isNavbar : true
	},

	{
		path: '/business-development/land-payment/new',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandPayment/form')),
		exact: true,
		isNavbar : true
	},

	{
		path: '/business-development/land-payment/brokerPayment',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandPayment/brokerPaymentForm')),
		exact: true,
		isNavbar : true
	},

	{
		path: '/business-development/land-payment/ReviewForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandPayment/ReviewForm')),
		exact: true,
		isNavbar : true
	},

	{
		path: '/business-development/land-payment/governmentFeeForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandPayment/governmentFeeForm')),
		exact: true,
		isNavbar : true
	},


	{
		path: '/business-development/land-payment/advancePaymentForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandPayment/advancePaymentForm')),
		exact: true,
		isNavbar : true
	},

	{
		path: '/business-development/basic-document',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/BasicDocument')),
		exact: true,
		isNavbar : true
	},

	{
		path: '/business-development/basic-document/form',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/BasicDocument/form')),
		exact: true,
		isNavbar : true
	},

	{
		path: '/business-development/basic-document/basicDocumentForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/BasicDocument/basicDocumentForm')),
		exact: true,
		isNavbar : true
	},




	{
		path: '/accounts/account-payable',
		Component: React.lazy(() => import('../../Pages/Accounts/AccountPayable/index')),
		exact: true,
		isNavbar : true
	},

	{
		path: '/accounts/account-payable/new',
		Component: React.lazy(() => import('../../Pages/Accounts/AccountPayable/form')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/accounts/account-payable/ReviewForm',
		Component: React.lazy(() => import('../../Pages/Accounts/AccountPayable/ReviewForm')),
		exact: true,
		isNavbar : true
	},
	
	{
		path: '/accounts/paid-account',
		Component: React.lazy(() => import('../../Pages/Accounts/PaidAccount/index')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/accounts/paid-account/ReviewForm',
		Component: React.lazy(() => import('../../Pages/Accounts/PaidAccount/ReviewForm')),
		exact: true,
		isNavbar : true
	},


	{
		path: '/dashboard/civil/civil-project',
		Component: React.lazy(() => import('../../Pages/Dashboard/CivilProject/ProjectProgress')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/dashboard/civil/civil-project/projectProgressDetail',
		Component: React.lazy(() => import('../../Pages/Dashboard/CivilProject/projectProgressDetail')),
		exact: true,
		isNavbar : true
	},


	{
		path: '/dashboard/civil/civil-project/projectProgressTaskDetail',
		Component: React.lazy(() => import('../../Pages/Dashboard/CivilProject/taskProgressDetail')),
		exact: true,
		isNavbar : true
	},

	{
		path: '/business-development/proposed/ReviewForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProposedProject/ReviewForm')),
		exact: true,
		isNavbar : true
	},
	
	{
		path: '/business-development/proposed/editForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProposedProject/editForm')),
		exact: true,
		isNavbar : true
	},
	

	{
		path: '/business-development/project-company-map',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectCompanyMap')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/project-company-map/new',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectCompanyMap/form')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/project-company-map/approvals',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectCompanyMap/ReviewForm')),
		exact: true,
		isNavbar : true
	},


	{
		path: '/business-development/liaison-report',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonReport')),
		exact: true,
		isNavbar : true
	},

	{
		path: '/test/test',
		Component: React.lazy(() => import('../../Pages/Test/Test')),
		exact: true,
		isNavbar : true
	},

	{
		path: '/profile',
		Component: React.lazy(() => import('../../Pages/Profile/UserProfile')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/profile/editForm',
		Component: React.lazy(() => import('../../Pages/Profile/UserProfile/editForm')),
		exact: true,
		isNavbar : true
	},
	
	{
		path: '/birthdayWish',
		Component: React.lazy(() => import('../../Pages/Greetings/Birthday')),
		exact: true,
		isNavbar : true
		
	},

	{
		path: '/workAnniversary',
		Component: React.lazy(() => import('../../Pages/Greetings/WorkAnniversary')),
		exact: true,
		isNavbar : true
		
	},
	{
		path: '/business-development/consolidated-report',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ConsolidatedReport')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/consolidated-report/view',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ConsolidatedReport/ReviewForm')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/documentation-report',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/DocumentationReport')),
		exact: true,
		isNavbar : true
	},

	{
		path: '/business-development/apf-task',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ApfTask')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/apf-task/new',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ApfTask/form')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/apf-task/Review',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ApfTask/ReviewForm')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/apf-query',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ApfQuery')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/apf-query/new',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ApfQuery/form')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/apf-query/Review',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ApfQuery/ReviewForm')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/land-dispute',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandDispute')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/land-dispute/new',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandDispute/form')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/land-dispute/Review',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandDispute/ReviewForm')),
		exact: true,
		isNavbar : true
	},

	{
		path: '/business-development/court-case/',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/CourtCase')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/court-case/new',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/CourtCase/form')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/court-case/ReviewForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/CourtCase/ReviewForm')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/court-case/editForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/CourtCase/editForm')),
		exact: true,
		isNavbar : true
	},


	{
		path: '/business-development/project-investment',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectInvestment')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/project-query',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectQuery')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/project-query/new',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectQuery/form')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/dashboard/project-summary',
		Component: React.lazy(() => import('../../Pages/Dashboard/ProjectSummary')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/dashboard/document-rework-report',
		Component: React.lazy(() => import('../../Pages/Dashboard/DocumentReworkReport')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/dashboard/project-task-report',
		Component: React.lazy(() => import('../../Pages/Dashboard/ProjectTaskReport')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/training',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/Training')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/training/new',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/Training/form')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/land-dispute/landDisputeForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandDispute/landDisputeForm')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/court-case/courtCaseForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/CourtCase/courtCaseForm')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/apf-task/apfTaskForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ApfTask/apfTaskForm')),
		exact: true,
		isNavbar : true
	},
		{
		path: '/business-development/projectNamingForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProposedProject/projectNamingForm')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/trainingContent',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/TraningContent')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/liaison-payment',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonPayment')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/liaison-payment/new',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonPayment/form')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/liaison-payment/view',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonPayment/ReviewForm')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/liaison-payment/addPaymentRequest',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonPayment/addPaymentRequest')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/liaison-payment/approval',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonPayment/Approval')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/liaison-payment/issue',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonPayment/Issue')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/liaison-payment/settlementRequest',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonPayment/settlementRequest')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/liaison-payment/settlementApproval',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonPayment/settlementApproval')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/liaison-payment/settlementSettle',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonPayment/settlementSettle')),
		exact: true,
		isNavbar : true
	},
	{
		path: '/business-development/liaison-payment/detail',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonPayment/Detail')),
		exact: true,
		isNavbar : true
	},

]
