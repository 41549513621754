import { GET_ACCOUNT_PAYMENT,GET_COMPANY_LIST,GET_PAYMENT_CATEGORY_LIST,
    GET_ACCOUNT_PAYMENT_DETAIL,GET_COMPANY_BANK_ACCOUNT_LIST,
    GET_PAYMENT_MODE_CATEGORY_LIST,GET_PAYMENT_MODE_LIST,GET_ACCOUNT_PAYMENT_PAID_LIST,GET_ACCOUNT_PAYMENT_PAID_DETAIL} from './types';

const initialStateChart = {
    labels : [],
    datasets: [],
  };
  
const initialState = {
    
    accountPaymentList : {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    companyList : {
        "error": false,
        "message": "",
        "company": [],
        "totalCount": 0
    },
    paymentCategoryList : {
        "error": false,
        "message": "",
        "company": [],
        "totalCount": 0
    }, 
    accountPaymentDetail : {
        "error": false,
        "message": "",
        "data": [],
    },
    companyAccountList : {
        "error": false,
        "message": "",
        "company": [],
        "totalCount": 0
    },
    paymentModeCategoryList : {
        "error": false,
        "message": "",
        "company": [],
        "totalCount": 0
    },
    paymentModeList : {
        "error": false,
        "message": "",
        "company": [],
        "totalCount": 0
    },
    accountPaymentPaidList : {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },

    accountPaymentPaidDetail : {
        "error": false,
        "message": "",
        "data": [],
    },
   
   
}

const Report_reducer = (state = initialState, { type, payload }) => {
    switch(type)
    {
        case GET_ACCOUNT_PAYMENT:
            return Object.assign({}, state, { accountPaymentList: payload}); 
        case GET_COMPANY_LIST:
            return Object.assign({}, state, { companyList: payload});
        case GET_PAYMENT_CATEGORY_LIST:
            return Object.assign({}, state, { paymentCategoryList: payload});
        case GET_ACCOUNT_PAYMENT_DETAIL:
            return Object.assign({}, state, { accountPaymentDetail: payload});
        case GET_COMPANY_BANK_ACCOUNT_LIST:
            return Object.assign({}, state, { companyAccountList: payload});
        case GET_PAYMENT_MODE_CATEGORY_LIST:
            return Object.assign({}, state, { paymentModeCategoryList: payload});
        case GET_PAYMENT_MODE_LIST:
            return Object.assign({}, state, { paymentModeList: payload});
        case GET_ACCOUNT_PAYMENT_PAID_LIST:
            return Object.assign({}, state, { accountPaymentPaidList: payload});
        case GET_ACCOUNT_PAYMENT_PAID_DETAIL:
            return Object.assign({}, state, { accountPaymentPaidDetail: payload});  
        default:  
            return state;
    }
}

export default Report_reducer;


