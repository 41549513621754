import {ErpApi, ErpApiService, FileUploadService} from '../../utils/ApiService';
import {GET_PROJECT_AUTHORITY, GET_BD_USER, GET_PROJECT_PHASE, GET_AUTHORIZATION,GET_USER_PERMISSION,GET_MENU_PERMISSION,
    GET_DISTRICT, GET_TALUK,GET_HOBLI, GET_PROJECT_BROKERS, GET_UPCOMING_PROJECT_LIST, GET_FUNCTIONALITY, 
    GET_VILLAGE_LIST, GET_PROJECT_OWNERS_NAMES, GET_PROJECT_NAMES, GET_MY_WEB_NOTIFICATION,
    GET_PROJECT_LIST, GET_PROJECT_FLOW_LIST, GET_PROJECT_TASK_STATUS, GET_PROJECT_SURVEY_NO_TASK_APPROVAL_DETAILS,
    GET_PROJECT_TASK_PLAN_DETAILS, GET_PROJECT_PLAN, GET_PROJECT_FILE_LIST, GET_PROJECT_SURVEY_NO_TASK_APPROVAL,
    GET_LAND_OWNER_NAMES,GET_LIASON_PROCESS_PLAN, GET_LIASON_TASK_PLAN, GET_LIASON_DOCUMENT_TASK_PLAN,  
    GET_PROJECT_DELAY_REASON, GET_DOCUMENT_TYPES, GET_PROJECT_BD_OWNER, GET_PROJECT_LEGAL_OWNER, GET_PROJECT_PLAN_FILE_LIST, GET_LIAISON_CATEGORY_LIST, GET_LIAISON_PROCESS_LIST,GET_PROJECT_TASK_PLAN_MANDOTORY_DOCUMENT, 
    GET_LAND_OWNER_LIST,GET_SURVEY_NO_LIST,GET_SURVEY_DETAIL_LIST,
    GET_FILE_ACQUISITION_INFO_LIST,GET_ACQUISITION_MODE_LIST,GET_ACQUISITION_INFO_DETAIL,
GET_LIAISON_DOCUMENT_CATEGORY_LIST,
    GET_PROJECT_LIST_WITH_EXTENT,GET_FEASIBILITY_ITEM_LIST,GET_FEASIBILITY_REPORT_LIST,GET_FEASIBILITY_REPORT_DETAIL,GET_LAND_OWNER_DETAIL,GET_LAND_BROKER_LIST,GET_LAND_BROKER_DETAIL,GET_LAND_OWNNER_VILLAGE_LIST, 
    GET_PROJECT_FUND_SOURCE_LIST,GET_PROJECT_FUND_REQUIREMENT_DETAIL,GET_PROJECT_FUND_LIST,GET_PROJECT_FUND_DETAIL,
    GET_PROJECT_FILE_PAYEMENT_DETAIL,
    GET_BD_PAYMENT_LIST,GET_BD_PAYMENT_DETAIL,GET_BD_PAYMENT_MILESTONE,
    GET_PROJECT_BROKER_LIST,GET_PROJECT_BROKER_DETAIL,GET_PROJECT_BROKER_PAYMENT_INFO,
    GET_PROJECT_LAND_OWNER,GET_PROJECT_OWNER_SURVEY_NUMBER_LIST,GET_PROJECT_FILE_ADVANCE_PAYMENT_DETAIL,
    GET_LIAISON_PROCESS_PLAN_FILE, GET_LIASON_PROCESS_PLAN_APPLICATION,GET_LIAISON_PROCESS_PLAN_APPLICATION_FILE_DETAIL,
    GET_PROJECTC_MILESTONE,GET_BD_REGISTRATION_ITEM, GET_SUBREGISTER_OFFICE, GET_PROJECT_FILE_DOCUMENT, GET_DEPENDENT_RELATIONSHIP, GET_BD_PAYMENT_ADVANCE_DETAIL,
    GET_PROJECT_DETAIL, GET_PROJECT_DROP_REASON_LIST ,GET_COMPANY_PROJECT_MAP,GET_PROJECT,GET_COMPANY,GET_DROPPED_PROJECT_LIST,GET_INSERTED_LAND_OWNER_DATA,
    GET_INSERTED_LAND_BROKER_DATA,GET_LAND_OWNER_ACCOUNT_DETAIL,GET_LAND_BROKER_ACCOUNT_DETAIL,
    GET_LIAISON_PROCESS_PLAN_FILTER_OWNER_LIST,GET_LIAISON_PROCESS_PLAN_APPLICATION_FILTER_OWNER_LIST,GET_LIAISON_DOC_TASK_PLAN_FILTER_OWNER_LIST,
    GET_USER_PROFILE,GET_BIRTHDAY,GET_EMPLOYEE_WORK_ANNIVERSARY,GET_PROJECT_LIST_FILTER,GET_PROJECT_UPCOMING_FILTER,
    GET_CIVIL_PROJECT_FILTER,GET_LIASON_DOCUMENT_TASK_PLAN_APPROVAL,GET_PROJECT_FILE_FILTER,GET_LIAISON_PROCESS_FILTER,GET_APF_PANEL,GET_APF_PENDING_PROJECT_FILE,GET_APF_TASK_PLAN,GET_APF_TASK_PLAN_DETAIL,
    GET_APF_TASK_PLAN_PROJECT_FILTER,GET_APF_TASK_PLAN_PANEL_FILTER,GET_APF_TASK_PLAN_PROJECT_FILE_FILTER,GET_APF_TASK_FILTER,
    GET_APF_TASK_PLAN_QUERY,GET_APF_TASK_PLAN_QUERY_DETAIL,
    GET_LIAISON_DOCUMENTATION_REPORT,GET_BD_LAND_DISPUTE_TYPE,GET_BD_LAND_DISPUTE_LIST,
    GET_BD_LAND_DISPUTE_DETAIL,GET_LIAISON_DOCUMENTATION_REPORT_PROJECT_FILTER,GET_LIAISON_DOCUMENTATION_REPORT_PROJECT_FILE_FILTER,
    GET_LIAISON_DOCUMENTATION_REPORT_OWNER_FILTER,GET_LIAISON_DOCUMENTATION_REPORT_CATEGORY_FILTER,GET_COURT_CASE_TYPE,GET_COURT_CASE_STATUS,
    GET_COURT_CASE_JURISDICTION,GET_DIRECTOR,GET_COURT_CASE,GET_COURT_CASE_DETAIL,GET_TRAINING_CONTENT,
    GET_BD_PROJECT_TASK_REJECTION_REASON,GET_BD_AGREEMENT_TYPE,GET_BD_PROJECT_REPORT,
    GET_BD_PROJECT_TASK_REPORT,GET_PROJECT_PROPOSED_NAME,GET_TRAINING,
    GET_APF_TASK_PLAN_TASK_FILTER,GET_LIAISON_PROCESS_PLAN_PROJECT,
    GET_LIAISON_PROCESS_PLAN_PROCESS,GET_LIAISON_PAYMENT_LIST,
    GET_LIAISON_PAYMENT_DETAIL,GET_LIAISON_PAYMENT_SURVEY_NUMBER,
    GET_LIAISON_PAYMENT_TASK_PLAN,GET_FUNCTIONALITY_PERMISSION_DETAIL,
    GET_LIAISON_PAYMENT_FILTER_PROJECT,
    GET_LIAISON_PAYMENT_FILTER_REQUESTED_BY,GET_PROJECT_NAMES1,
    GET_PROJECT_TASK_PLAN_FILTER_TASK_OWNER,
    GET_PROJECT_TASK_PLAN_FILTER_TASK,GET_LIAISON_DOCUMENT_TASK_PLAN_PENDING,
    GET_LIAISON_APPLICABLE_PROCESS_PLAN,GET_LIASON_DOCUMENT_TASK_PLAN_EXPORT,
    GET_DEPARTMENT,GET_LIAISON_DOCUMENT_TASK_PLAN_APPROVAL_DETAIL,GET_LIAISON_DOCUMENT_TASK_PLAN_DETAIL} from './types';
import {mainLoadingTrue, mainLoadingFalse, displayMessage} from '../User/action';
import {getItem, setItem} from '../../utils/crypto';
import { $CombinedState } from 'redux';

const userLocal = getItem('enuseloc');
const LoginuserId = userLocal && Object.keys(userLocal).length > 0 ? userLocal['user_id'] : null;

export const getProjectAuthority_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "authorityID": "",
        "authorityStatus": "",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.PROJECT_AUTHORITY, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getProjectAuthoritySuccess(data.projectAuthority))
            }
            else{
                dispatch(getProjectAuthoritySuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getProjectAuthoritySuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getProjectAuthoritySuccess = (data) => {
    return {
        type : GET_PROJECT_AUTHORITY,
        payload : data
    }
}

export const getBdUser_action = (data) => {
    const initialPayload ={
        "userID": LoginuserId,
        "departmentID":"",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.BD_USER, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getBdSuccess(data.user))
            }
            else{
                dispatch(getBdSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getBdSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getBdSuccess = (data) => {
    return {
        type : GET_BD_USER,
        payload : data
    }
}


export const getProjectPhase_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.PROJECT_PHASE, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getProjectPhaseSuccess(data.data))
            }
            else{
                dispatch(getProjectPhaseSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getProjectPhaseSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getProjectPhaseSuccess = (data) => {
    return {
        type : GET_PROJECT_PHASE,
        payload : data
    }
}

export const getDistrict_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":0,
        "iDisplayLength":-1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.DISTRICTS, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getDistrictSuccess(data.data))
            }
            else{
                dispatch(getDistrictSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getDistrictSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getDistrictSuccess = (data) => {
    return {
        type : GET_DISTRICT,
        payload : data
    }
}

export const getTaluk_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "generalSearch":"",
        "districtID": "",
        "sortOrder":"",
        "iDisplayStart":0,
        "iDisplayLength":-1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.TALUKS, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getTalukSuccess(data.data))
            }
            else{
                dispatch(getTalukSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getTalukSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getTalukSuccess = (data) => {
    return {
        type : GET_TALUK,
        payload : data
    }
}

export const getHobli_action = (data) => {
    const initialPayload = {
        "userID":LoginuserId,
        "generalSearch":"",
        "talukID": "",
        "sortOrder":"",
        "iDisplayStart":0,
        "iDisplayLength":-1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.HOBLI, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getHobliSuccess(data.data))
            }
            else{
                dispatch(getHobliSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getHobliSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getHobliSuccess = (data) => {
    return {
        type : GET_HOBLI,
        payload : data
    }
}


export const getProjectBrokers_action = (data) => {
    const initialPayload = {
        "userID":  LoginuserId,
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":0,
        "iDisplayLength":-1
        }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.PROJECT_BROKERS, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getProjectBrokersSuccess(data.data))
            }
            else{
                dispatch(getProjectBrokersSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getProjectBrokersSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getProjectBrokersSuccess = (data) => {
    return {
        type : GET_PROJECT_BROKERS,
        payload : data
    }
}


export const postProject_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.POST_PROJECT, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                // toast.error(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const getProjectList_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID":"",
        "projectPhaseID":"",
        "planningAuthorityID":"",
        "bdOwnerID":"",
        "legalOwnerID":"",
        "civilOwnerID":"",
        "projectStatus":"Active",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.PROJECT_LIST, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getProjectListSuccess(data))
            }
            else{
                dispatch(getProjectListSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getProjectListSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getUpgoingProjectList_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID":"",
        "projectPhaseID":"",
        "planningAuthorityID":"",
        "bdOwnerID":"",
        "legalOwnerID":"",
        "civilOwnerID":"",
        "projectStatus":"",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPCOMING_PROJECT_LIST, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch({
                    type : GET_UPCOMING_PROJECT_LIST,
                    payload : data
                })
            }
            else{
                dispatch({
                    type : GET_UPCOMING_PROJECT_LIST,
                    payload : data
                })
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch({
                type : GET_UPCOMING_PROJECT_LIST,
                payload : data
            })
            dispatch(mainLoadingFalse());
        })
    }
}

export const getProjectListSuccess = (data) => {
    return {
        type : GET_PROJECT_LIST,
        payload : data
    }
}

export const getVillageList_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "hobliID":"",
        "taskID":"",
        "projectPhaseID":"",
        "planningAuthorityID":"",
        "bdOwnerID":"",
        "legalOwnerID":"",
        "civilOwnerID":"",
        "projectStatus":"",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":0,
        "iDisplayLength":-1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.VILLAGE_LIST, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getVillageListSuccess(data.data))
            }
            else{
                dispatch(getVillageListSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getVillageListSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getVillageListSuccess = (data) => {
    return {
        type : GET_VILLAGE_LIST,
        payload : data
    }
}

export const getProjectFlowList_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        projectID: "",
        taskID: "",
        projectPhaseID: "",
        planningAuthorityID: "",
        bdOwnerID: "",
        legalOwnerID: "",
        civilOwnerID: "",
        projectStatus: "",
        parentID:"0",
        generalSearch: "",
        sortOrder: "", 
        ...data,
        // taskOwnerID : LoginuserId
    };
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.PROJECT_TASK_PLAN, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getProjectFlowListSuccess(data))
            }
            else{
                dispatch(getProjectFlowListSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getProjectFlowListSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getProjectFlowListSuccess = (data) => {
    return {
        type : GET_PROJECT_FLOW_LIST,
        payload : data
    }
}


export const getProjectTaskPlanFilterOwner_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "adminView":"",
        "reportView":"",
        "projectPhaseID": "",
        "planningAuthorityID": "",
        "projectStatus": "",
        "generalSearch": "",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.PROJECT_OWNERS_NAMES, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch({
                    type : GET_PROJECT_OWNERS_NAMES,
                    payload : data.data
                })
            }
            else{
                dispatch({
                    type : GET_PROJECT_OWNERS_NAMES,
                    payload : []
                })
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch({
                type : GET_PROJECT_OWNERS_NAMES,
                payload : []
            })
            dispatch(mainLoadingFalse());
        })
    }
}


export const getProjectTaskPlanFilterProject_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectPhaseID": "",
        "projectID":"",
        "planningAuthorityID": "",
        "projectStatus": "",
        "generalSearch": "",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.PROJECT_NAMES, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch({
                    type : GET_PROJECT_NAMES,
                    payload : data.data
                })
            }
            else{
                dispatch({
                    type : GET_PROJECT_NAMES,
                    payload : []
                })
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch({
                type : GET_PROJECT_NAMES,
                payload : []
            })
            dispatch(mainLoadingFalse());
        })
    }
}

export const getProjectTaskPlanFilterProject1_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID":"",
        "taskID":"",
        "taskOwnerID":"",
        "taskPlanStatusID":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.PROJECT_NAMES1, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch({
                    type : GET_PROJECT_NAMES1,
                    payload : data.data
                })
            }
            else{
                dispatch({
                    type : GET_PROJECT_NAMES1,
                    payload : []
                })
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch({
                type : GET_PROJECT_NAMES1,
                payload : []
            })
            dispatch(mainLoadingFalse());
        })
    }
}




export const getProjectTaskStatus_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectPhaseID":"",
        "planningAuthorityID":"",
        "projectStatus":"",
        "generalSearch":"",
        "iDisplayStart":0,
        "iDisplayLength":-1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.PROJECT_TASK_STATUS, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch({
                    type : GET_PROJECT_TASK_STATUS,
                    payload : data.data
                })
            }
            else{
                dispatch({
                    type : GET_PROJECT_TASK_STATUS,
                    payload : []
                })
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch({
                type : GET_PROJECT_TASK_STATUS,
                payload : []
            })
            dispatch(mainLoadingFalse());
        })
    }
}

export const updateProjectTaskRemark_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        //"taskType":"ProjectTask",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_PROJECT_TASK_REMARK, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const getProjectDelayReason_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "reasonTypeID":"",
        "taskID":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.PROJECT_DELAY_REASON, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch({
                    type : GET_PROJECT_DELAY_REASON,
                    payload : data.data
                })
            }
            else{
                dispatch({
                    type : GET_PROJECT_DELAY_REASON,
                    payload : []
                })
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch({
                type : GET_PROJECT_DELAY_REASON,
                payload : []
            })
            dispatch(mainLoadingFalse());
        })
    }
}


export const getDocumentType_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.PROJECT_DOCUMENT_TYPES, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch({
                    type : GET_DOCUMENT_TYPES,
                    payload : data.data
                })
            }
            else{
                dispatch({
                    type : GET_DOCUMENT_TYPES,
                    payload : []
                })
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch({
                type : GET_DOCUMENT_TYPES,
                payload : []
            })
            dispatch(mainLoadingFalse());
        })
    }
}

export const uploadProjectTaskDocument_action = (data) => {
    let initialPayload = new FormData();
    initialPayload.append('userID', LoginuserId);
    initialPayload.append('documentType',  data.documentType);
    initialPayload.append('documentRemark', data.documentRemark);
    initialPayload.append('taskPlanID', data.taskPlanID);
    initialPayload.append('documentFile', data.documentFile);
    initialPayload.append('taskType', data?.taskTypeID ? data?.taskTypeID : "ProjectTask");

    console.log("data", initialPayload);
    const config = {
        headers: { 'content-type': 'multipart/form-data' }
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return FileUploadService.post(ErpApi.UPDATE_PROJECT_UPLOAD_DOCUMENT, initialPayload, config).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const updateProjectTaskComplete_action = (data) => {
    let initialPayload = new FormData();
    // initialPayload.append('documentType',  data.documentType);
    initialPayload.append('taskCompletedDate', data.taskCompletedDate);
    initialPayload.append('taskPlanID', data.taskPlanID);
    initialPayload.append('documentFile', data.documentFile);
    initialPayload.append('userID', LoginuserId);
    initialPayload.append('taskType', data?.taskType ? data?.taskType : "ProjectTask");
    initialPayload.append('documentRemark', "");

    console.log("initialPayload", initialPayload);
    const config = {
        headers: { 'content-type': 'multipart/form-data' }
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return FileUploadService.post(ErpApi.UPDATE_PROJECT_TASK_COMPLETE, initialPayload, config).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

// export const updateProjectSubTaskComplete_action = (data) => {
//     let initialPayload = new FormData();
//     // initialPayload.append('documentType',  37);
//     initialPayload.append('userID', LoginuserId);

//     for(var i = 0 ; i < data.taskDocuments.length; i++) {
        
//         initialPayload.append('documentFile'+data.taskDocuments[i].documentType, data.taskDocuments[i].documentFile);
//     }

//     initialPayload.append('taskDocuments', JSON.stringify(data.taskDocuments));

//     initialPayload.append('taskCompletedDate', data.taskCompletedDate);
//     initialPayload.append('taskPlanID', data.taskPlanID);
//     initialPayload.append('subTaskPlanID', JSON.stringify(data.subTaskPlanID));
//     initialPayload.append('documentRemark', "");
//     // initialPayload.append('documentFile', data.documentFile);
//     initialPayload.append('taskType', data.taskType);

//     console.log("data", initialPayload);
//     const config = {
//         headers: { 'content-type': 'multipart/form-data' }
//     }
//     return dispatch => {
//         dispatch(mainLoadingTrue());
//         return FileUploadService.post(ErpApi.UPDATE_PROJECT_SUB_TASK_COMPLETE, initialPayload, config).then(response => {
//             const {data}  = response;
//             dispatch(mainLoadingFalse());
//             if(!data.error)
//             {
//                 dispatch(displayMessage({
//                     text : data.message,
//                     type : "success"
//                 }));
//             }
//             else{
//                 dispatch(displayMessage({
//                     text : data.message,
//                     type : "success"
//                 }));
//             }
//         })
//         .catch(err => {
//             dispatch(mainLoadingFalse());
//         })
//     }
// }

export const getProjectTaskPlanDetail_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.PROJECT_TASK_PLAN_DETAILS, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch({
                    type : GET_PROJECT_TASK_PLAN_DETAILS,
                    payload : data.data
                })
            }
            else{
                dispatch({
                    type : GET_PROJECT_TASK_PLAN_DETAILS,
                    payload : []
                })
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch({
                type : GET_PROJECT_TASK_PLAN_DETAILS,
                payload : []
            })
            dispatch(mainLoadingFalse());
        })
    }
}

export const getProjectPlan_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID":"",
        "taskID":"",
        "projectPhaseID":"",
        "planningAuthorityID":"",
        "bdOwnerID":"",
        "legalOwnerID":"",
        "civilOwnerID":"",
        "projectStatus":"",
        "parentID": "0",
        "generalSearch":"",
        "sortOrder":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.PROJECT_PLAN, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch({
                    type : GET_PROJECT_PLAN,
                    payload : data
                })
            }
            else{
                dispatch({
                    type : GET_PROJECT_PLAN,
                    payload : []
                })
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch({
                type : GET_PROJECT_PLAN,
                payload : []
            })
            dispatch(mainLoadingFalse());
        })
    }
}

export const uploadProjectTaskStatus_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_PROJECT_TASK_STATUS, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                })); 
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const uploadProjectTaskApprovalStatus_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_PROJECT_TASK_APROVAL_STATUS, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const createProjectFile_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.POST_PROJECT_FILE, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                // toast.error(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "error"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const getProjectFileList_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID":"",
        "villageID":"",
        "projectPhaseID":"",
        "planningAuthorityID":"",
        "bdOwnerID":"",
        "legalOwnerID":"",
        "fileStatus":"Active",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":0,
        "iDisplayLength": -1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.PROJECT_PROJECT_FILE, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch({
                    type : GET_PROJECT_FILE_LIST,
                    payload : data
                })
            }
            else{
                dispatch({
                    type : GET_PROJECT_FILE_LIST,
                    payload : []
                })
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch({
                type : GET_PROJECT_FILE_LIST,
                payload : []
            })
            dispatch(mainLoadingFalse());
        })
    }
}

export const getLandOwner_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "villageID":"",
        "parentID":"",
        "ownerStatus":"",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":0,
        "iDisplayLength": -1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.PROJECT_LAND_OWNER, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch({
                    type : GET_LAND_OWNER_NAMES,
                    payload : data.data
                })
            }
            else{
                dispatch({
                    type : GET_LAND_OWNER_NAMES,
                    payload : []
                })
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch({
                type : GET_LAND_OWNER_NAMES,
                payload : []
            })
            dispatch(mainLoadingFalse());
        })
    }
}

export const getLiaisonProcessPlan_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.LIASON_PROCESS_PLAN, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch({
                    type : GET_LIASON_PROCESS_PLAN,
                    payload : data
                })
            }
            else{
                dispatch({
                    type : GET_LIASON_PROCESS_PLAN,
                    payload : []
                })
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch({
                type : GET_LIASON_PROCESS_PLAN,
                payload : []
            })
            dispatch(mainLoadingFalse());
        })
    }
}


export const getLiaisonTaskPlan_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":"0",
        "iDisplayLength":"10",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.LIASON_TASK_PLAN, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch({
                    type : GET_LIASON_TASK_PLAN,
                    payload : data
                })
            }
            else{
                dispatch({
                    type : GET_LIASON_TASK_PLAN,
                    payload : []
                })
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch({
                type : GET_LIASON_TASK_PLAN,
                payload : []
            })
            dispatch(mainLoadingFalse());
        })
    }
}

export const updateFirebaseKeyWeb_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.POST_PROJECT_FILE, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                // toast.error(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const getProjectBdOwner_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "authorityID":"",
        "projecPhaseID":"",
        "projecStatus":"",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":0,
        "iDisplayLength": -1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.PROJECT_BD_OWNER, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch({
                    type : GET_PROJECT_BD_OWNER,
                    payload : data.data
                })
            }
            else{
                dispatch({
                    type : GET_PROJECT_BD_OWNER,
                    payload : []
                })
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch({
                type : GET_PROJECT_BD_OWNER,
                payload : []
            })
            dispatch(mainLoadingFalse());
        })
    }
}


export const getProjectLegalOwner_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "authorityID":"",
        "projecPhaseID":"",
        "projecStatus":"",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":0,
        "iDisplayLength": -1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.PROJECT_LEGAL_OWNER, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch({
                    type : GET_PROJECT_LEGAL_OWNER,
                    payload : data.data
                })
            }
            else{
                dispatch({
                    type : GET_PROJECT_LEGAL_OWNER,
                    payload : []
                })
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch({
                type : GET_PROJECT_LEGAL_OWNER,
                payload : []
            })
            dispatch(mainLoadingFalse());
        })
    }
}


export const getLiaisonDocumentationTaskPlan_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID":"",
        "projectFileID":"",
        "processPlanID":"",
        "taskPlanStatus":"",
        "taskOwnerID":"",
        "categoryID":"",
        "documentType":"",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":"0",
        "iDisplayLength":"10",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.LIASON_DOCUMENT_TASK_PLAN, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch({
                    type : GET_LIASON_DOCUMENT_TASK_PLAN,
                    payload : {...data, initialPayload: initialPayload}

                    
                })
            }
            else{
                dispatch({
                    type : GET_LIASON_DOCUMENT_TASK_PLAN,
                    payload : []
                })
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch({
                type : GET_LIASON_DOCUMENT_TASK_PLAN,
                payload : []
            })
            dispatch(mainLoadingFalse());
        })
    }
}


export const getLiaisonDocumentationTaskPlanApproval_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID":"",
        "projectFileID":"",
        "processPlanID":"",
        "taskPlanStatus":"",
        "taskOwnerID":"",
        "categoryID":"",
        "documentType":"",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":"0",
        "iDisplayLength":"10",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_LIASON_DOCUMENT_TASK_PLAN_APPROVAL, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch({
                    type : GET_LIASON_DOCUMENT_TASK_PLAN_APPROVAL,
                    payload : data
                })
            }
            else{
                dispatch({
                    type : GET_LIASON_DOCUMENT_TASK_PLAN_APPROVAL,
                    payload : []
                })
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch({
                type : GET_LIASON_DOCUMENT_TASK_PLAN_APPROVAL,
                payload : []
            })
            dispatch(mainLoadingFalse());
        })
    }
}

export const getProjectTaskPlanApproval_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        //"projectID":"61",
        "taskID":"",
        "taskOwnerID":"",
        "taskStatus":"",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":0,
        "iDisplayLength":10,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.PROJECT_SURVEY_NO_TASK_APPROVAL, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch({
                    type : GET_PROJECT_SURVEY_NO_TASK_APPROVAL,
                    payload : data
                })
            }
            else{
                dispatch({
                    type : GET_PROJECT_SURVEY_NO_TASK_APPROVAL,
                    payload : []
                })
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch({
                type : GET_PROJECT_SURVEY_NO_TASK_APPROVAL,
                payload : []
            })
            dispatch(mainLoadingFalse());
        })
    }
}

export const getProjectTaskPlanApprovalDetail_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectTaskPlanApprovalID":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.PROJECT_SURVEY_NO_TASK_APPROVAL_DETAILS, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch({
                    type : GET_PROJECT_SURVEY_NO_TASK_APPROVAL_DETAILS,
                    payload : data
                })
            }
            else{
                dispatch({
                    type : GET_PROJECT_SURVEY_NO_TASK_APPROVAL_DETAILS,
                    payload : []
                })
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch({
                type : GET_PROJECT_SURVEY_NO_TASK_APPROVAL_DETAILS,
                payload : []
            })
            dispatch(mainLoadingFalse());
        })
    }
}

export const updateLiaisonProcessApplicabilityStatus_action = (data) => {
    let initialPayload = {
        "userID": LoginuserId,
        "processPlanID":"",
        "applicabilityStatus":"",
        "updateRemark":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_LIASON_PROCESS_APPLICATION_STATUS, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const updateLiaisonTaskPlanComplete_action = (data) => {
    let initialPayload;
        initialPayload = new FormData();
        initialPayload.append('userID', LoginuserId);
        for(var i = 0 ; i < data.taskDocuments.length; i++) {
        
            initialPayload.append('documentFile'+data.taskDocuments[i].documentType+i, data.taskDocuments[i].documentFile);
        }
    
        initialPayload.append('taskDocuments', JSON.stringify(data.taskDocuments));
    
        initialPayload.append('documentRemark', data?.documentRemark);
        initialPayload.append('taskPlanID', data.taskPlanID);

        initialPayload.append('taskCompletedDate', data.taskCompletedDate);
        initialPayload.append('isSequenceExecution', data.isSequenceExecution);

    const config =  data?.documentFile ? {
        headers: { 'content-type': 'multipart/form-data' }
    } : {};

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_LIASON_TASK_PLAN_COMPLETE, initialPayload, config).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const getMyWebNotification_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "notificationFor":"",
        "notificationType":"",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":0,
        "iDisplayLength":50,
        }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.MY_WEB_NOTIFICATION, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch({
                    type : GET_MY_WEB_NOTIFICATION,
                    payload : data
                })
            }
            else{
                dispatch({
                    type : GET_MY_WEB_NOTIFICATION,
                    payload : []
                })
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch({
                type : GET_MY_WEB_NOTIFICATION,
                payload : []
            })
            dispatch(mainLoadingFalse());
        })
    }
}

export const getAuthorization_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "menuID":"",
        "functionalityID":"",
        "grantUser":"",
        "status":"Active",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":0,
        "iDisplayLength":10,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.AUTHORIZATION, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch({
                    type : GET_AUTHORIZATION,
                    payload : data
                })
            }
            else{
                dispatch({
                    type : GET_AUTHORIZATION,
                    payload : []
                })
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch({
                type : GET_AUTHORIZATION,
                payload : []
            })
            dispatch(mainLoadingFalse());
        })
    }
}


export const clearAuthorizationList_action = () => {
    console.log("authorizationData_cleared_in_action");

    return dispatch => {
        dispatch({
            type : GET_AUTHORIZATION,
            payload : []
        })
    }
}


export const getMenuForPermission_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "menuID":"",
        "functionalityID":"",
        "grantUser":"",
        "status":"",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":0,
        "iDisplayLength":-1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.MENU_PERMISSION, initialPayload).then(response => {
            const {data}  = response;
            console.log("menuForPermissionList", data.data)
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch({
                    type : GET_MENU_PERMISSION,
                    payload : data.data
                })
            }
            else{
                dispatch({
                    type : GET_MENU_PERMISSION,
                    payload : []
                })
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch({
                type : GET_MENU_PERMISSION,
                payload : []
            })
            dispatch(mainLoadingFalse());
        })
    }
}

export const getFunctionality_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        //"menuID":"",
        //"functionalityID":"",
        "status":"",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":0,
        "iDisplayLength": -1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.FUNCTIONALITY, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch({
                    type : GET_FUNCTIONALITY,
                    payload : data.data
                })
            }
            else{
                dispatch({
                    type : GET_FUNCTIONALITY,
                    payload : []
                })
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch({
                type : GET_FUNCTIONALITY,
                payload : []
            })
            dispatch(mainLoadingFalse());
        })
    }
}


export const updateUserFunctionalityPermission_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "grantUser":LoginuserId,
        "functionalityID":[],
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_USER_FUNCTIONALITY_PERMISSION, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}


export const getProjectPlanFileList_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId, 
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.PROJECT_PLAN_FILE_PLAN, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getProjectPlanFileListSuccess(data))
            }
            else{
                dispatch(getProjectPlanFileListSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getProjectPlanFileListSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getProjectPlanFileListSuccess = (data) => {
    return {
        type : GET_PROJECT_PLAN_FILE_LIST,
        payload : data
    }
}


export const getLiaisonCategoryList_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId, 
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.LIAISON_PROCESS_CATEGORY, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                //console.log("testing"+data);
                dispatch(getLiaisonCategoryListSuccess(data))
            }
            else{
                dispatch(getLiaisonCategoryListSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getLiaisonCategoryListSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getLiaisonCategoryListSuccess = (data) => {
    return {
        type : GET_LIAISON_CATEGORY_LIST,
        payload : data
    }
}




export const getLiaisonProcessList_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId, 
        ...data
    }
    return dispatch => {

        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.FILE_LIAISON_PROCESS_PLAN, initialPayload).then(response => {
            const {data}  = response;

            console.log("testing111",data);

            dispatch(mainLoadingFalse());
            if(!data.error){
                console.log("testing",data);
                dispatch(getLiaisonProcessListSuccess(data.data))
            }
            else{
                dispatch(getLiaisonProcessListSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getLiaisonProcessListSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getLiaisonProcessListSuccess = (data) => {
    return {
        type : GET_LIAISON_PROCESS_LIST,
        payload : data
    }
}



export const updateLiaisonProcessPlanApplicability_action = (data) => {
    
    console.log("testing333",data);
    const initialPayload = {
        "userID": LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_LIAISON_PROCESS_PLAN_APPLICABILITY, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                // toast.error(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "error"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}




//New
export const getLandOwnerList_action = (data) => {
    const initialPayload = {
        "userID":LoginuserId,
        "projectID":"",
        "parentID":"",
        "ownerStatus":"",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":0,
        "iDisplayLength": "",
        ...data 
    };
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.PROJECT_LAND_OWNER, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getLandOwnerSuccess(data))
            }
            else{
                dispatch(getLandOwnerSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getLandOwnerSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getLandOwnerSuccess = (data) => {
    return {
        type : GET_LAND_OWNER_LIST,
        payload : data
    }
}


export const getSurveyNo_action = (data) => {


    const initialPayload = {
        "userID":LoginuserId,
        ...data 
    };

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_SURVEY_NO, initialPayload).then(response => {
            const {data}  = response;
            console.log("survey data::: ");
            console.log(data);

            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getSurveyNumberSuccess(data.data))
            }
            else{
                dispatch(getSurveyNumberSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getSurveyNumberSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getSurveyNumberSuccess = (data) => {
    return {
        type : GET_SURVEY_NO_LIST,
        payload : data
    }
}

export const getSurveyNoDetails_action = (data) => {


    const initialPayload = {
        "userID":LoginuserId,
        ...data 
    };


    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_SURVEY_NO_DETAILS, initialPayload).then(response => {
            const {data}  = response;


            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getSurveyNumberDetailSuccess(data))
            }
            else{
                dispatch(getSurveyNumberDetailSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getSurveyNumberDetailSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getSurveyNumberDetailSuccess = (data) => {
    return {
        type : GET_SURVEY_DETAIL_LIST,
        payload : data
    }
}

export const createFileAcquisitionInfo_action = (data) => {
    const initialPayload = {
        "userID":LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.CREATE_FILE_ACQUISITION_INFO, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                // toast.error(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "error"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const getFileAcquisitionInfo_action = (data) => {
    const initialPayload = {
        "userID":LoginuserId,
        "acquisitionInfoID":"",
        "projectFileID":"",
        "villageID":"",
        "projectID":"",
        "infoStatus":"",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":0,
        "iDisplayLength": -1,
        ...data 
    };
    return dispatch => {
        dispatch(mainLoadingTrue());
        console.log("action payload is ",initialPayload);
        return ErpApiService.post(ErpApi.GET_FILE_ACQUISITION_INFO, initialPayload).then(response => {
            const {data}  = response;

            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getAcquisitionInfoListSuccess(data))
            }
            else{
                dispatch(getAcquisitionInfoListSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getAcquisitionInfoListSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getAcquisitionInfoListSuccess = (data) => {
    return {
        type : GET_FILE_ACQUISITION_INFO_LIST,
        payload : data
    }
}

export const getAcquisitionMode_action = (data) => {

    const initialPayload = {
        "userID":LoginuserId,
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_FILE_ACQUISITION_MODE, initialPayload).then(response => {
            const {data}  = response;

            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getAcquisitionModeSuccess(data.data))
            }
            else{
                dispatch(getAcquisitionModeSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getAcquisitionModeSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getAcquisitionModeSuccess = (data) => {
    return {
        type : GET_ACQUISITION_MODE_LIST,
        payload : data
    }
}

export const getAcquisitionInfoDetail_action = (data) => {

    
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_FILE_ACQUISITION_INFO_DETAIL, data).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getAcquisitionInfoDetailSuccess(data))
            }
            else{
                dispatch(getAcquisitionInfoDetailSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getAcquisitionInfoDetailSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getAcquisitionInfoDetailSuccess = (data) => {
    return {
        type : GET_ACQUISITION_INFO_DETAIL,
        payload : data
    }
}

export const updateFileAcquisitionInfoStatus_action = (data) => {
    const initialPayload = {
        "userID":LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_FILE_ACQUISITION_INFO_STATUS, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const getProjectWithExtent_action = (data) => {
    const initialPayload = {
        "userID":LoginuserId,
        "acquisitionInfoID":2,
        "projectFileID":"",
        "villageID":"",
        "projectID":"",
        "infoStatus":"",
        "generalSearch":"",
        "iDisplayStart":0,
        "iDisplayLength":10,
        ...data 
    };
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_PROJECT_WITH_EXTENT, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getProjectWithExtentSuccess(data))
            }
            else{
                dispatch(getProjectWithExtentSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getProjectWithExtentSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getProjectWithExtentSuccess = (data) => {
    return {
        type : GET_PROJECT_LIST_WITH_EXTENT,
        payload : data
    }
}

export const getProjectFeasibilityItem_action = (data) => {
    const initialPayload = {
        "userID":LoginuserId,
        "statusID":"Active",
        ...data 
    };
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_FEASIBILITY_ITEM, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getProjectFeasibilityItemSuccess(data))
            }
            else{
                dispatch(getProjectFeasibilityItemSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getProjectFeasibilityItemSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getProjectFeasibilityItemSuccess = (data) => {
    return {
        type : GET_FEASIBILITY_ITEM_LIST,
        payload : data
    }
}

export const updateFeasibilityReport_action = (data) => {
    const initialPayload = {
        "userID":LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_FEASIBILITY_REPORT, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const getFeasibilityReport_action = (data) => {
    const initialPayload = {
        "userID":LoginuserId,
        "projectID":"",
        "statusID":"",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":0,
        "iDisplayLength": -1,
        ...data 
    };
    return dispatch => {
        dispatch(mainLoadingTrue());
        console.log("action payload is ",initialPayload);
        return ErpApiService.post(ErpApi.GET_FEASIBILITY_REPORT, initialPayload).then(response => {
            const {data}  = response;

            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getFeasibilityReportList_success(data))
            }
            else{
                dispatch(getFeasibilityReportList_success({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getFeasibilityReportList_success([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getFeasibilityReportList_success = (data) => {
    return {
        type : GET_FEASIBILITY_REPORT_LIST,
        payload : data
    }
}

export const getFeasibilityReportDetail_action = (data) => {
    const initialPayload = {
        "userID":LoginuserId,
        "projectID":"",
        "feasibilityID":"",
        ...data 
    };
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_FEASIBILITY_REPORT_DETAIL, initialPayload).then(response => {
            const {data}  = response;

            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getFeasibilityReportDetail_success(data))
            }
            else{
                dispatch(getFeasibilityReportDetail_success({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getFeasibilityReportDetail_success([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getFeasibilityReportDetail_success = (data) => {
    return {
        type : GET_FEASIBILITY_REPORT_DETAIL,
        payload : data
    }
}


export const createLandOwner_action = (data) => {
    let initialPayload;
    // if(data?.documentFile){
        initialPayload = new FormData();
        initialPayload.append('userID', LoginuserId);
        initialPayload.append('parentID', data.parentID);
        initialPayload.append('ownerType',data.ownerType);
        initialPayload.append('parentRelationship', data.parentRelationship);

        initialPayload.append('ownerName', data.ownerName);
        initialPayload.append('mobileNumber', data.mobileNumber);
        initialPayload.append('projectID', data.projectID);
        initialPayload.append('isPanPresent', data.isPanPresent);
        initialPayload.append('isAadharPresent', data.isAadharPresent);
        initialPayload.append('aadharNumber', data.aadharNumber);
        initialPayload.append('panNumber', data.panNumber);
        initialPayload.append('ownerRemarks', data.ownerRemarks);
        initialPayload.append('panDocumentFile', data?.panDocumentFile );
        initialPayload.append('aadharDocumentFile', data?.aadharDocumentFile);
        initialPayload.append('projectFileID', JSON.stringify(data?.projectFileID));



    const config =  data?.panDocumentFile ? {
        headers: { 'content-type': 'multipart/form-data' }
    } : {};

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.CREATE_LAND_OWNER, initialPayload, config).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(getInsertedLandOwnerId(data.data));
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));

            }
            else{
                
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}


export const getInsertedLandOwnerId = (data) => {
    console.log("inredux",data)
    return {
        type : GET_INSERTED_LAND_OWNER_DATA,
        payload : data
    }
}





export const createLandOwnerAccount_action = (data) => {
    let initialPayload;
    // if(data?.documentFile){
        initialPayload = new FormData();
        initialPayload.append('userID', LoginuserId);
        initialPayload.append('landOwnerID', data.landOwnerID);
        initialPayload.append('accountNumber',data.accountNumber);
        initialPayload.append('accountName', data.accountName);

        initialPayload.append('accountBank', data.accountBank);
        initialPayload.append('accountBranch', data.accountBranch);
        initialPayload.append('accountIfscCode', data.accountIfscCode);
  
        initialPayload.append('passBookDocumentFile', data?.passBookDocumentFile );


    const config =  data?.passBookDocumentFile ? {
        headers: { 'content-type': 'multipart/form-data' }
    } : {};

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.CREATE_LAND_OWNER_ACCOUNT, initialPayload, config).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const getLandOwnerDetail_action = (data) => {
    const initialPayload = {
        "userID":LoginuserId,
        ...data 
    };
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_LAND_OWNER_DETAIL, initialPayload).then(response => {
            const {data}  = response;

            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getLandOwnerDetail_success(data))
            }
            else{
                dispatch(getLandOwnerDetail_success({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getLandOwnerDetail_success([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getLandOwnerDetail_success = (data) => {
    return {
        type : GET_LAND_OWNER_DETAIL,
        payload : data
    }
}

export const getProjectBdLandBroker_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "brokerStatus": "",
        "generalSearch": "",
        "iDisplayStart": 0,
        "iDisplayLength": 10,
        ...data 
    };
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_LAND_BROKER, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getProjectBdLandBroker_success(data))
            }
            else{
                dispatch(getProjectBdLandBroker_success({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getProjectBdLandBroker_success([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getProjectBdLandBroker_success = (data) => {
    return {
        type : GET_LAND_BROKER_LIST,
        payload : data
    }
}

export const createLandBroker_action = (data) => {
    let initialPayload;

        initialPayload = new FormData();
        initialPayload.append('userID', LoginuserId);
        initialPayload.append('brokerName', data.brokerName);
        initialPayload.append('mobileNumber', data.mobileNumber);
        initialPayload.append('isPanPresent', data.isPanPresent);
        initialPayload.append('isAadharPresent', data.isAadharPresent);
        initialPayload.append('aadharNumber', data.aadharNumber);
        initialPayload.append('panNumber', data.panNumber);
        initialPayload.append('brokerRemarks', data.brokerRemarks);
        initialPayload.append('panDocumentFile', data?.panDocumentFile );
        initialPayload.append('aadharDocumentFile', data?.aadharDocumentFile);


    const config =  data?.panDocumentFile ? {
        headers: { 'content-type': 'multipart/form-data' }
    } : {};

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.CREATE_LAND_BROKER, initialPayload, config).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {

                dispatch(getInsertedLandBrokerId(data.data));
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const getInsertedLandBrokerId = (data) => {
    console.log("inredux",data)
    return {
        type : GET_INSERTED_LAND_BROKER_DATA,
        payload : data
    }
}



export const getProjectBdLandBrokerDetail_action = (data) => {
    const initialPayload = {
        "userID":LoginuserId,
        "landBrokerID":"",
        ...data 
    };
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_LAND_BROKER_DETAIL, initialPayload).then(response => {
            const {data}  = response;

            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getProjectBdLandBrokerDetail_success(data))
            }
            else{
                dispatch(getProjectBdLandBrokerDetail_success({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getProjectBdLandBrokerDetail_success([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getProjectBdLandBrokerDetail_success = (data) => {
    return {
        type : GET_LAND_BROKER_DETAIL,
        payload : data
    }
}

export const createLandBrokerAccount_action = (data) => {
    let initialPayload;
    // if(data?.documentFile){
        initialPayload = new FormData();
        initialPayload.append('userID', LoginuserId);
        initialPayload.append('landBrokerID', data.landBrokerID);
        initialPayload.append('accountNumber',data.accountNumber);
        initialPayload.append('accountName', data.accountName);

        initialPayload.append('accountBank', data.accountBank);
        initialPayload.append('accountBranch', data.accountBranch);
        initialPayload.append('accountIfscCode', data.accountIfscCode);
  
        initialPayload.append('passBookDocumentFile', data?.passBookDocumentFile );


    const config =  data?.passBookDocumentFile ? {
        headers: { 'content-type': 'multipart/form-data' }
    } : {};

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.CREATE_LAND_BROKER_ACCOUNT, initialPayload, config).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}


export const getProjectTaskPlanMandotoryDocumentList_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId, 
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_PROJECT_TASK_PLAN_MANDOTORY_DOCUMENT, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());

            if(!data.error){
                dispatch(getProjectTaskPlanMandotoryDocumentSuccess(data))
            }
            else{
                dispatch(getProjectTaskPlanMandotoryDocumentSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getProjectTaskPlanMandotoryDocumentSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getProjectTaskPlanMandotoryDocumentSuccess = (data) => {
    return {
        type : GET_PROJECT_TASK_PLAN_MANDOTORY_DOCUMENT,
        payload : data
    }
}



export const uploadProjectTaskPlanMandotoryDocument_action = (data) => {

    let initialPayload = new FormData();
    for(var i = 0 ; i < data.taskDocuments.length; i++) {
        
        initialPayload.append('documentFile'+data.taskDocuments[i].documentType, data.taskDocuments[i].documentFile);
    }
    initialPayload.append('taskDocuments', JSON.stringify(data.taskDocuments));
    initialPayload.append('taskPlanID', data.taskPlanID);
    initialPayload.append('userID', LoginuserId); 

    console.log("data", initialPayload);
    const config = {
        headers: { 'content-type': 'multipart/form-data' }
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return FileUploadService.post(ErpApi.UPLOAD_PROJECT_TASK_PLAN_MANDOTORY_DOCUMENT, initialPayload, config).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}



export const feasibilityReportSendForApproval_action = (data) => {
    const initialPayload = {
        "userID":LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.FEASIBILITY_REPORT_SEND_FOR_APPROVAL, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const approveFeasibilityReportTaskPlan_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "taskPlanApprovalID": "",
        "feasibilityReportID": "",
        "statusID": "",
        "remark": "",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.APPROVE_FEASIBILITY_REPORT_TASK_PLAN, initialPayload).then(response => {
            const {data}  = response;
            console.log("actionPayload##",data);
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}




export const getLandOwnerVillageList_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "villageID":"",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":0,
        "iDisplayLength":-1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_LAND_OWNER_VILLAGE, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getLandOwnerVillageListSuccess(data.data))
            }
            else{
                dispatch(getLandOwnerVillageListSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getLandOwnerVillageListSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getLandOwnerVillageListSuccess = (data) => {
    return {
        type : GET_LAND_OWNNER_VILLAGE_LIST,
        payload : data
    }
}



export const getLiaisonDocumentCategory_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "statusID":"",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": "0",
        "iDisplayLength": "-1",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_LIAISON_DOCUMENT_CATEGORY, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());

            if(!data.error){
                dispatch(getLiaisonDocumentCategorySuccess(data))
            }
            else{
                dispatch(getLiaisonDocumentCategorySuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getLiaisonDocumentCategorySuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getLiaisonDocumentCategorySuccess = (data) => {
    return {
        type : GET_LIAISON_DOCUMENT_CATEGORY_LIST,
        payload : data
    }
}
export const updateLiaisonDocumentCategory_action = (data) => {
    let initialPayload = {
        "userID": LoginuserId,
        "liaisonTaskID": "",
        "categoryID": "",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_LIAISON_DOCUMENT_CATEGORY, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}
export const updateLiaisonTaskPlanOwner_action = (data) => {
    let initialPayload = {
        "userID": LoginuserId,
        "liaisonTaskPlanID": "",
        "ownerID": "",
        "changeRemark": "",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_LIAISON_TASK_PLAN_OWNER, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const updateLiaisonTaskPlanStatus_action = (data) => {
    let initialPayload = {
        "userID": LoginuserId,
        "taskPlanID": "",
        "taskStatusID": "",
        "updateRemark": "",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_LIAISON_TASK_PLAN_STATUS, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const updateBdProjectTaskPlanOwner_action = (data) => {
    let initialPayload = {
        "userID": LoginuserId,
        "taskPlanID":"",
        "ownerID":"",
        "changeRemark":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_BD_PROJECT_TASK_PLAN_OWNER, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}


export const updateLiaisonProcessPlanOwner_action = (data) => {
    let initialPayload = {
        "userID": LoginuserId,
        "processPlanID":"",
        "ownerID":"",
        "changeRemark":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_LIAISON_PROCESS_PLAN_OWNER, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const createLiaisonDocumentCheckListPlan_action = (data) => {
    let initialPayload = {
        "userID": LoginuserId,
        "projectID" : "",
        "projectFileID" : "",
        "processID" : "",
        "documentName" : "",
        "documentCategory" : "",
        "documentType" : "",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.CREATE_LIAISON_DOC_CHECKLIST_PLAN, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const importLiaisonDocumentCheckListPlan_action = (data) => {
    let initialPayload = new FormData();
    initialPayload.append('userID', LoginuserId);
    initialPayload.append('documentFile',  data.documentFile);

    console.log("data", initialPayload);
    const config = {
        headers: { 'content-type': 'multipart/form-data' }
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return FileUploadService.post(ErpApi.IMPORT_LIAISON_DOC_CHECKLIST_PLAN, initialPayload, config).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}


export const getProjectFundSource_action = (data) => {
    const initialPayload ={
        "userID": LoginuserId,
        "sourceStatus": "Active",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_PROJECT_FUND_SOURCE, initialPayload).then(response => {
            const {data}  = response;

            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getProjectFundSourceSuccess(data))
            }
            else{
                dispatch(getProjectFundSourceSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getProjectFundSourceSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getProjectFundSourceSuccess = (data) => {
    return {
        type : GET_PROJECT_FUND_SOURCE_LIST,
        payload : data
    }
}

export const getProjectFundRequirment_action = (data) => {
    const initialPayload ={
        "userID": LoginuserId,
        "projectID": "",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_PROJECT_FUND_REQUIREMENT, initialPayload).then(response => {
            const {data}  = response;

            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getProjectFundRequirmentSuccess(data))
            }
            else{
                dispatch(getProjectFundRequirmentSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getProjectFundRequirmentSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getProjectFundRequirmentSuccess = (data) => {
    return {
        type : GET_PROJECT_FUND_REQUIREMENT_DETAIL,
        payload : data
    }
}

export const updateProjectFundRequirement_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_PROJECT_FUND_REQUIREMENT, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const getProjectFund_action = (data) => {
    const initialPayload ={
        "userID": LoginuserId,
        "projectFundID":"",
        "projectID": "",
        "status": "",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_PROJECT_FUND, initialPayload).then(response => {
            const {data}  = response;
            // console.log("actionPayload",initialPayload);
            // console.log("actionData",data);
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getProjectFundSuccess(data))
            }
            else{
                dispatch(getProjectFundSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getProjectFundSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getProjectFundSuccess = (data) => {
    return {
        type : GET_PROJECT_FUND_LIST,
        payload : data
    }
}

export const getProjectFundDetail_action = (data) => {
    const initialPayload ={
        "userID":LoginuserId,
        "projectFundID":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_PROJECT_FUND_DETAIL, initialPayload).then(response => {
            const {data}  = response;
            // console.log("actionPayload",initialPayload);
            // console.log("actionData",data);
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getProjectFundDetailSuccess(data))
            }
            else{
                dispatch(getProjectFundDetailSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getProjectFundDetailSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getProjectFundDetailSuccess = (data) => {
    return {
        type : GET_PROJECT_FUND_DETAIL,
        payload : data
    }
}



export const getProjectFilePaymentDetailSuccess = (data) => {
    return {
        type : GET_PROJECT_FILE_PAYEMENT_DETAIL,
        payload : data
    }
}

export const addLandOwnerPayment_action = (data) => {
    const initialPayload = {
        "userID":LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.ADD_LAND_OWNER_PAYMENT, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                // toast.error(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "error"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}


export const getBdPayment_action = (data) => {
    const initialPayload ={
        "userID": LoginuserId,
        "companyID":"",
        "projectID":"",
        "projectFileID":"",
        "paymentCategory":"",
        "paymentAgainst":"",
        "paymentType":"",
        "requetedBy":"",
        "recipientID":"",
        "paymentStatus":"",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":0,
        "iDisplayLength":10,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_BD_PAYMENT, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getBdPaymentSuccess(data))
            }
            else{
                dispatch(getBdPaymentSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getBdPaymentSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getBdPaymentSuccess = (data) => {
    return {
        type : GET_BD_PAYMENT_LIST,
        payload : data
    }
}

export const getBdPaymentDetail_action = (data) => {
    const initialPayload ={
        "userID":LoginuserId,
        "bdPaymentID":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi._GET_BD_PAYMENT_DETAIL, initialPayload).then(response => {
            const {data}  = response;

            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getBdPaymentDetailSuccess(data))
            }
            else{
                dispatch(getBdPaymentDetailSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getBdPaymentDetailSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getBdPaymentDetailSuccess = (data) => {
    return {
        type : GET_BD_PAYMENT_DETAIL,
        payload : data
    }
}

export const updateBdPaymentStatus_action = (data) => {
    const initialPayload = {
        "userID":LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_BD_PAYMENT_STATUS, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const getBdPaymentMilestone_action = (data) => {
    const initialPayload = {
        "userID":LoginuserId,
        "milestoneType":"",
        "milestoneStatus":"",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":0,
        "iDisplayLength": -1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_BD_PAYMENT_MILESTONE, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch({
                    type : GET_BD_PAYMENT_MILESTONE,
                    payload : data.data
                })
            }
            else{
                dispatch({
                    type : GET_BD_PAYMENT_MILESTONE,
                    payload : []
                })
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch({
                type : GET_BD_PAYMENT_MILESTONE,
                payload : []
            })
            dispatch(mainLoadingFalse());
        })
    }
}

export const getProjectBroker_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "brokerID": "",
        "projectID": "",
        "projectFileID": "",
        "brokerStatus": "",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_PROJECT_BROKER, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch({
                    type : GET_PROJECT_BROKER_LIST,
                    payload : data
                })
            }
            else{
                dispatch({
                    type : GET_PROJECT_BROKER_LIST,
                    payload : []
                })
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch({
                type : GET_PROJECT_BROKER_LIST,
                payload : []
            })
            dispatch(mainLoadingFalse());
        })
    }
}

export const getProjectBrokerDetail_action = (data) => {
    const initialPayload ={
        "userID": LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        console.log("brokerPayload",initialPayload)
        return ErpApiService.post(ErpApi.GET_PROJECT_BROKER_DETAIL, initialPayload).then(response => {
            const {data}  = response;
            console.log("fromActionBroker",data)

            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getProjectBrokerDetailSuccess(data))
            }
            else{
                dispatch(getProjectBrokerDetailSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getProjectBrokerDetailSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getProjectBrokerDetailSuccess = (data) => {
    return {
        type : GET_PROJECT_BROKER_DETAIL,
        payload : data
    }
}

export const updateProjectBrokerStatus_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,       
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_PROJECT_BROKER_STATUS, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const addProjectBrokerCharge_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.ADD_PROJECT_BROKER_CHARGE, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const updateProjectBrokerChargeStatus_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,       
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_PROJECT_BROKER_CHARGE_STATUS, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const addProjectBrokerPayment_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.ADD_PROJECT_BROKER_PAYMENT, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const getProjectBrokerPaymentInfo_action = (data) => {
    const initialPayload ={
        "userID": LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.ADD_PROJECT_BROKER_PAYMENT_INFO, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getProjectBrokerPaymentInfoSuccess(data))
            }
            else{
                dispatch(getProjectBrokerPaymentInfoSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getProjectBrokerPaymentInfoSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getProjectBrokerPaymentInfoSuccess = (data) => {
    return {
        type : GET_PROJECT_BROKER_PAYMENT_INFO,
        payload : data
    }
}

export const getProjectLandOwner_action = (data) => {
    const initialPayload ={
        "userID": LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_PROJECT_LAND_OWNER, initialPayload).then(response => {
            const {data}  = response;

            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getProjectLandOwnerSuccess(data))
            }
            else{
                dispatch(getProjectLandOwnerSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getProjectLandOwnerSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getProjectLandOwnerSuccess = (data) => {
    return {
        type : GET_PROJECT_LAND_OWNER,
        payload : data
    }
}

export const getProjectOwnerSurveyNo_action = (data) => {
    const initialPayload ={
        "userID": LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_PROJECT_OWNER_SURVEY_NUMBER, initialPayload).then(response => {
            const {data}  = response;

            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getProjectOwnerSurveyNoSuccess(data))
            }
            else{
                dispatch(getProjectOwnerSurveyNoSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getProjectOwnerSurveyNoSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getProjectOwnerSurveyNoSuccess = (data) => {
    return {
        type : GET_PROJECT_OWNER_SURVEY_NUMBER_LIST,
        payload : data
    }
}

export const getProjectFileAdvancePaymentDetail_action = (data) => {
    const initialPayload ={
        "userID": LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_PROJECT_FILE_ADVANCE_PAYMENT_DETAIL, initialPayload).then(response => {
            const {data}  = response;

            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getProjectFileAdvancePaymentDetailSuccess(data))
            }
            else{
                dispatch(getProjectFileAdvancePaymentDetailSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getProjectFileAdvancePaymentDetailSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getProjectFileAdvancePaymentDetailSuccess = (data) => {
    return {
        type : GET_PROJECT_FILE_ADVANCE_PAYMENT_DETAIL,
        payload : data
    }
}

export const getProjectFilePaymentDetail_action = (data) => {
    const initialPayload ={
        "userID":LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());     
        return ErpApiService.post(ErpApi.GET_PROJECT_FILE_PAYMENT_DETAIL, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getProjectFilePaymentDetailSuccess(data))
            }
            else{
                dispatch(getProjectFilePaymentDetailSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getProjectFilePaymentDetailSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getLiaisonProcessPlanFile_action = (data) => {
    const initialPayload ={
        "userID": LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_LIAISON_PROCESS_PLAN_FILE, initialPayload).then(response => {
            const {data}  = response;

            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getLiaisonProcessPlanFileSuccess(data))
            }
            else{
                dispatch(getLiaisonProcessPlanFileSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getLiaisonProcessPlanFileSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getLiaisonProcessPlanFileSuccess = (data) => {
    return {
        type : GET_LIAISON_PROCESS_PLAN_FILE,
        payload : data
    }
}

export const liaisonProcessPlanStart_action = (data) => {
    const initialPayload = {
        "userID":LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.ADD_LIAISON_PROCESS_PLAN_START, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                // toast.error(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "error"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}


export const getLiaisonProcessPlanApplication_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        /*"projectID":"",
        "projectFileID":"",
        "processID":"",
        "completionStatus":"",
        "processOwnerID":"",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":0,
        "iDisplayLength" : 10,*/
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_LIAISON_PROCESS_PLAN_APPLICATION, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch({
                    type : GET_LIASON_PROCESS_PLAN_APPLICATION,
                    payload : data
                })
            }
            else{
                dispatch({
                    type : GET_LIASON_PROCESS_PLAN_APPLICATION,
                    payload : []
                })
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch({
                type : GET_LIASON_PROCESS_PLAN_APPLICATION,
                payload : []
            })
            dispatch(mainLoadingFalse());
        })
    }
}
export const updateLiaisonProcessPlanApplicationOwner = (data) => {
    let initialPayload = {
        "userID": LoginuserId,
        "processPlanApplicationID":"",
        "ownerID":"",
        "changeRemark":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_LIAISON_PROCESS_PLAN_APPLICATION_OWNER, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const getLiaisonProcessPlanApplicationFileDetail_action = (data) => {
    const initialPayload ={
        "userID": LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_LIAISON_PROCESS_PLAN_APPLICATION_FILE_DETAIL, initialPayload).then(response => {
            const {data}  = response;

            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getLiaisonProcessPlanApplicationFileDetailSuccess(data))
            }
            else{
                dispatch(getLiaisonProcessPlanApplicationFileDetailSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getLiaisonProcessPlanApplicationFileDetailSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getLiaisonProcessPlanApplicationFileDetailSuccess = (data) => {
    return {
        type : GET_LIAISON_PROCESS_PLAN_APPLICATION_FILE_DETAIL,
        payload : data
    }
}
export const liaisonProcessPlanApplicationSplit_action = (data) => {
    const initialPayload = {
        "userID":LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.ADD_LIAISON_PROCESS_PLAN_APPLICATION_SPLIT, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                // toast.error(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "error"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}
export const updateLiaisonProcessPlanApplicationStatus_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        //"taskType":"ProjectTask",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_LIAISON_PROCESS_PLAN_APPLICATION_STATUS, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}



export const getProjectMilestone_action = (data) => {
    const initialPayload ={
        "userID": LoginuserId,
        "projectID":"",
        "planningAuthorityID":"",
        "sortOrder":"",
        "iDisplayStart":0,
        "iDisplayLength":-1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_PROJECTC_MILESTONE, initialPayload).then(response => {
            const {data}  = response;

            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getProjectMilestoneSuccess(data))
            }
            else{
                dispatch(getProjectMilestoneSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getProjectMilestoneSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getProjectMilestoneSuccess = (data) => {
    return {
        type : GET_PROJECTC_MILESTONE,
        payload : data
    }
}


export const getBdRegistrationItem_action = (data) => {
    const initialPayload ={
        "userID": LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_BD_REGISTRATION_ITEM, initialPayload).then(response => {
            const {data}  = response;

            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getBdRegistrationItemSuccess(data))
            }
            else{
                dispatch(getBdRegistrationItemSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getBdRegistrationItemSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getBdRegistrationItemSuccess = (data) => {
    return {
        type : GET_BD_REGISTRATION_ITEM,
        payload : data
    }
}

export const getSubRegisterOffice_action = (data) => {
    const initialPayload ={
        "userID": LoginuserId,
        "projectID":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_SUBREGISTER_OFFICE, initialPayload).then(response => {
            const {data}  = response;

            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getSubRegisterOfficeSuccess(data))
            }
            else{
                dispatch(getSubRegisterOfficeSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getSubRegisterOfficeSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getSubRegisterOfficeSuccess = (data) => {
    return {
        type : GET_SUBREGISTER_OFFICE,
        payload : data
    }
}

export const addLandGovtPayment_action = (data) => {
    const initialPayload = {
        "userID":LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.ADD_LAND_GOVT_PAYMENT, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                // toast.error(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "error"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const updateSurveyNumberCreation_action = (data) => {
    let initialPayload = {
        "userID": LoginuserId,
        "taskRemark":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_SURVEY_NUMBER_CREATION, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}


export const uploadProjectFileDocument_action = (data) => {
    let initialPayload = new FormData();

    initialPayload.append('userID', LoginuserId);
    initialPayload.append('projectFileID', data.projectFileID);

    // for (let index = 0; index < data.documentFile.length; index++) {
    //     initialPayload.append('documentFile[]', data.documentFile[index]);
    // }

    for(var i = 0 ; i < data.taskDocuments.length; i++) {
        initialPayload.append('documentFile'+data.taskDocuments[i].documentType+i, data.taskDocuments[i].documentFile);
    }

    initialPayload.append('taskDocuments', JSON.stringify(data.taskDocuments));
    initialPayload.append('documentRemark', data.documentRemark);

    // initialPayload.append('documentTitle', data.documentTitle);
    // initialPayload.append('documentType',  data.documentType);

    console.log("initialPayload", Array.from(initialPayload));
    const config = {
        headers: { 'content-type': 'multipart/form-data' }
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return FileUploadService.post(ErpApi.UPLOAD_PROJECT_FILE_DOCUMENT, initialPayload, config).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}
export const getProjectFileDocument_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectFileID": "",
        "projectID": "",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_PROJECT_FILE_DOCUMENT, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getProjectFileDocumentSuccess(data))
            }
            else{
                dispatch(getProjectFileDocumentSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getProjectFileDocumentSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}
export const getProjectFileDocumentSuccess = (data) => {
    return {
        type : GET_PROJECT_FILE_DOCUMENT,
        payload : data
    }
}


export const updateProjectFile_action = (data) => {
    const initialPayload = {
        "userID":LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_PROJECT_FILE, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}
export const updateProjectFileStatus_action = (data) => {
    const initialPayload = {
        "userID":LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_PROJECT_FILE_STATUS, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}



export const getDependentRelationship_action = (data) => {
    const initialPayload ={
        "userID": LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_DEPENDENT_RELATIONSHIP, initialPayload).then(response => {
            const {data}  = response;

            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getDependentRelationshipSuccess(data))
            }
            else{
                dispatch(getDependentRelationshipSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getDependentRelationshipSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getDependentRelationshipSuccess = (data) => {
    return {
        type : GET_DEPENDENT_RELATIONSHIP,
        payload : data
    }
}
export const updateLandOwner_action = (data) => {
    let initialPayload;
        initialPayload = new FormData();
        initialPayload.append('userID', LoginuserId);
        initialPayload.append('landOwnerID', data.landOwnerID);
        initialPayload.append('parentRelationship', data.parentRelationship);
        initialPayload.append('ownerName', data.ownerName);
        initialPayload.append('mobileNumber', data.mobileNumber);
        initialPayload.append('isPanPresent', data.isPanPresent);
        initialPayload.append('isAadharPresent', data.isAadharPresent);
        initialPayload.append('aadharNumber', data.aadharNumber);
        initialPayload.append('panNumber', data.panNumber);
        initialPayload.append('ownerRemarks', data.ownerRemarks);
        initialPayload.append('panDocumentFile', data?.panDocumentFile );
        initialPayload.append('aadharDocumentFile', data?.aadharDocumentFile);


    const config =  data?.panDocumentFile ? {
        headers: { 'content-type': 'multipart/form-data' }
    } : {};
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_LAND_OWNER, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}
export const updateLandOwnerStatus_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        //"taskType":"ProjectTask",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_LAND_OWNER_STATUS, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const updateLandBroker_action = (data) => {
    
    let initialPayload;
        initialPayload = new FormData();
        initialPayload.append('userID', LoginuserId);
        initialPayload.append('brokerID', data.brokerID);
        initialPayload.append('brokerName', data.brokerName);
        initialPayload.append('mobileNumber', data.mobileNumber);
        initialPayload.append('isPanPresent', data.isPanPresent);
        initialPayload.append('isAadharPresent', data.isAadharPresent);
        initialPayload.append('aadharNumber', data.aadharNumber);
        initialPayload.append('panNumber', data.panNumber);
        initialPayload.append('brokerRemarks', data.brokerRemarks);
        initialPayload.append('panDocumentFile', data?.panDocumentFile );
        initialPayload.append('aadharDocumentFile', data?.aadharDocumentFile);


    const config =  data?.panDocumentFile ? {
        headers: { 'content-type': 'multipart/form-data' }
    } : {};
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_LAND_BROKER, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const updateLandBrokerStatus_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_LAND_BROKER_STATUS, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}


export const getBdPaymentAdvanceDetail_action = (data) => {
    const initialPayload ={
        "userID":LoginuserId,
        "bdPaymentID":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi._GET_BD_PAYMENT_ADVANCE_DETAIL, initialPayload).then(response => {
            const {data}  = response;

            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getBdPaymentAdvanceDetailSuccess(data))
            }
            else{
                dispatch(getBdPaymentAdvanceDetailSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getBdPaymentAdvanceDetailSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getBdPaymentAdvanceDetailSuccess = (data) => {
    return {
        type : GET_BD_PAYMENT_ADVANCE_DETAIL,
        payload : data
    }
}

export const bdLandPaymentSettle_action = (data) => {
    const initialPayload = {
        "userID":LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.BD_LAND_PAYMENT_SETTLE, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                // toast.error(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "error"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}




export const getProjectDetail_action = (data) => {
    const initialPayload ={
        "userID":LoginuserId,
        "projectID":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_PROJECT_DETAIL, initialPayload).then(response => {
            const {data}  = response;

            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getProjectDetailSuccess(data))
            }
            else{
                dispatch(getProjectDetailSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getProjectDetailSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getProjectDetailSuccess = (data) => {
    return {
        type : GET_PROJECT_DETAIL,
        payload : data
    }
}

export const getProjectDropReason_action = (data) => {
    const initialPayload ={
        "userID": LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_PROJECT_DROP_REASON, initialPayload).then(response => {
            const {data}  = response;

            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getProjectDropReasonSuccess(data))
            }
            else{
                dispatch(getProjectDropReasonSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getProjectDropReasonSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getProjectDropReasonSuccess = (data) => {
    return {
        type : GET_PROJECT_DROP_REASON_LIST,
        payload : data
    }
}

export const updateProjectStatus_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID":"",
        "statusID":"",
        "reasonID":"",
        "statusRemark":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_PROJECT_STATUS, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const updateProjectBdOwner_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID":"",
        "bdOwnerID":"",
        "projectRemark":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_PROJECT_BD_OWNER, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const updateProjectLegalOwner_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID":"",
        "legalOwnerID":"",
        "projectRemark":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_PROJECT_LEGAL_OWNER, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const updateProjectDetail_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID":"",
        "projectName":"",
        "projectPhaseID":"",
        "planningAuthorityID":"",
        "projectBrokerID":"",
        "districtID":"",
        "talukID":"",
        "hobliID":"",
        "villageID":"",
        "projectRemark":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_PROJECT_DETAIL, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}


export const getCompanyProjectMap_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "companyID": "",
        "projectID": "",
        "mapStatus": "",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": 0,
        "iDisplayLength": "-1",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.COMPANY_PROJECT_MAP, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch({
                    type: GET_COMPANY_PROJECT_MAP,
                    payload: data
                })
            }
            else {
                dispatch({
                    type: GET_COMPANY_PROJECT_MAP,
                    payload: []
                })
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch({
                    type: GET_COMPANY_PROJECT_MAP,
                    payload: []
                })
                dispatch(mainLoadingFalse());
            })
    }
}

export const updateProjectCompanyMap_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "companyProjectID": "",
        "mapStatus": "",
        "remark": "",

        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_PROJECT_COMPANY_MAP, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
            else {
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
        })
            .catch(err => {
                dispatch(mainLoadingFalse());
            })
    }
}

export const addProjectCompanyMap_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "companyID": "",
        "projectID": "",
        "remark": "",
        ...data,
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.ADD_PROJECT_COMPANY_MAP, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
            else {
                // toast.error(data.message);
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
        })
            .catch(err => {
                dispatch(mainLoadingFalse());
            })
    }
}

export const getProject_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.PROJECT_INFO, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch({
                    type: GET_PROJECT,
                    payload: data
                })
            }
            else {
                dispatch({
                    type: GET_PROJECT,
                    payload: []
                })
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch({
                    type: GET_PROJECT,
                    payload: []
                })
                dispatch(mainLoadingFalse());
            })
    }
}

export const getCompany_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "companyID": "",
        "companyStatus": "Active",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": 0,
        "iDisplayLength": "-1",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_COMPANY, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch({
                    type: GET_COMPANY,
                    payload: data
                })
            }
            else {
                dispatch({
                    type: GET_COMPANY,
                    payload: []
                })
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch({
                    type: GET_COMPANY,
                    payload: []
                })
                dispatch(mainLoadingFalse());
            })
    }
}



export const getDroppedProject_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectPhaseID":"",
        "planningAuthorityID":"",
        "bdOwnerID":"",
        "legalOwnerID":"",
        "civilOwnerID":"",
        "projectStatus":"",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_DROPPED_PROJECT_LIST, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getDroppedProjectSuccess(data))
            }
            else{
                dispatch(getDroppedProjectSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getDroppedProjectSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getDroppedProjectSuccess = (data) => {
    return {
        type : GET_DROPPED_PROJECT_LIST,
        payload : data
    }
}

export const getLandOwnerAccountDetail_action = (data) => {
    const initialPayload ={
        "userID": LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_LAND_OWNER_ACCOUNT_DETAIL, initialPayload).then(response => {
            const {data}  = response;

            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getLandOwnerAccountDetailSuccess(data))
            }
            else{
                dispatch(getLandOwnerAccountDetailSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getLandOwnerAccountDetailSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getLandOwnerAccountDetailSuccess = (data) => {
    return {
        type : GET_LAND_OWNER_ACCOUNT_DETAIL,
        payload : data
    }
}


export const updateLandOwnerAccount_action = (data) => {
    let initialPayload;
    // if(data?.documentFile){
        initialPayload = new FormData();
        initialPayload.append('userID', LoginuserId);
        initialPayload.append('accountID', data.accountID);
        initialPayload.append('accountNumber',data.accountNumber);
        initialPayload.append('accountName', data.accountName);
        initialPayload.append('accountBank', data.accountBank);
        initialPayload.append('accountBranch', data.accountBranch);
        initialPayload.append('accountIfscCode', data.accountIfscCode);
        initialPayload.append('accountStatus', data.accountStatus);
        initialPayload.append('isDocumentChanged', data.isDocumentChanged);
        initialPayload.append('passBookDocumentFile', data?.passBookDocumentFile);


    const config =  data?.passBookDocumentFile ? {
        headers: { 'content-type': 'multipart/form-data' }
    } : {};

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_LAND_OWNER_ACCOUNT_DETAIL, initialPayload, config).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}


export const getLandBrokerAccountDetail_action = (data) => {
    const initialPayload ={
        "userID": LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_LAND_BROKER_ACCOUNT_DETAIL, initialPayload).then(response => {
            const {data}  = response;

            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getLandBrokerAccountDetailSuccess(data))
            }
            else{
                dispatch(getLandBrokerAccountDetailSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getLandBrokerAccountDetailSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getLandBrokerAccountDetailSuccess = (data) => {
    return {
        type : GET_LAND_BROKER_ACCOUNT_DETAIL,
        payload : data
    }
}

export const updateLandBrokerAccount_action = (data) => {
    let initialPayload;
    // if(data?.documentFile){
        initialPayload = new FormData();
        initialPayload.append('userID', LoginuserId);
        initialPayload.append('accountID', data.accountID);
        initialPayload.append('accountNumber',data.accountNumber);
        initialPayload.append('accountName', data.accountName);
        initialPayload.append('accountBank', data.accountBank);
        initialPayload.append('accountBranch', data.accountBranch);
        initialPayload.append('accountIfscCode', data.accountIfscCode);
        initialPayload.append('accountStatus', data.accountStatus);
        initialPayload.append('isDocumentChanged', data.isDocumentChanged);
        initialPayload.append('passBookDocumentFile', data?.passBookDocumentFile);


    const config =  data?.passBookDocumentFile ? {
        headers: { 'content-type': 'multipart/form-data' }
    } : {};

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_LAND_BROKER_ACCOUNT_DETAIL, initialPayload, config).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}


export const getLiaisonProcessPlanFilterOwner_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "adminView":"",
        "reportView":"",
        "projectID": "",
        "projectFileID":"",
        "processStatus": "",
        "generalSearch": "",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_LIAISON_PROCESS_PLAN_FILTER_OWNER, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch({
                    type : GET_LIAISON_PROCESS_PLAN_FILTER_OWNER_LIST,
                    payload : data.data
                })
            }
            else{
                dispatch({
                    type : GET_LIAISON_PROCESS_PLAN_FILTER_OWNER_LIST,
                    payload : []
                })
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch({
                type : GET_LIAISON_PROCESS_PLAN_FILTER_OWNER_LIST,
                payload : []
            })
            dispatch(mainLoadingFalse());
        })
    }
}

export const getLiaisonProcessPlanApplicationFilterOwner_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "adminView":"",
        "reportView":"",
        "projectID": "",
        "processStatus": "",
        "generalSearch": "",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_LIAISON_PROCESS_PLAN_APPLICATION_FILTER_OWNER, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch({
                    type : GET_LIAISON_PROCESS_PLAN_APPLICATION_FILTER_OWNER_LIST,
                    payload : data.data
                })
            }
            else{
                dispatch({
                    type : GET_LIAISON_PROCESS_PLAN_APPLICATION_FILTER_OWNER_LIST,
                    payload : []
                })
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch({
                type : GET_LIAISON_PROCESS_PLAN_APPLICATION_FILTER_OWNER_LIST,
                payload : []
            })
            dispatch(mainLoadingFalse());
        })
    }
}

export const getLiaisonDocumentationTaskPlanFilterOwner_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "adminView":"",
        "reportView":"",
        "projectID": "",
        "projectFileID": "",
        "taskStatus": "",
        "generalSearch": "",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_LIAISON_DOC_TASK_PLAN_FILTER_OWNER, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch({
                    type : GET_LIAISON_DOC_TASK_PLAN_FILTER_OWNER_LIST,
                    payload : data.data
                })
            }
            else{
                dispatch({
                    type : GET_LIAISON_DOC_TASK_PLAN_FILTER_OWNER_LIST,
                    payload : []
                })
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch({
                type : GET_LIAISON_DOC_TASK_PLAN_FILTER_OWNER_LIST,
                payload : []
            })
            dispatch(mainLoadingFalse());
        })
    }
}


// -----
export const getUserProfile_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.USER_PROFILE, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch({
                    type: GET_USER_PROFILE,
                    payload: data
                })
            }
            else {
                dispatch({
                    type: GET_USER_PROFILE,
                    payload: []
                })
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch({
                    type: GET_USER_PROFILE,
                    payload: []
                })
                dispatch(mainLoadingFalse());
            })
    }
}

export const updateEmployeeProfile_action = (data) => {
    const initialPayload = {

        "userID": LoginuserId,
        "profileID": "",
        "employeeName": "",
        "fatherName": "",
        "motherName": "",
        "employeeDateOfBirth": "",
        "employeeGender": "",
        "employeeBloodGroup":  "",
        "employeeMaritialStatus":  "",
        "personalPhoneNumber":  "",
        "personalEmailId": "",
        "employeePanNumber":  "",
        "employeeAadharNumber":  "",
        "currentAddress": "",
        "permanentAddress":  "",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": 0,
        "iDisplayLength": "10",

        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_EMPLOYEE_PROFILE, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
            else {
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
        })
            .catch(err => {
                dispatch(mainLoadingFalse());
            })
    }
}

// ---

export const updateUserProfileImage_action = (data) => {
    let initialPayload;
    // if(data?.documentFile){
        initialPayload = new FormData();
        initialPayload.append('userID', LoginuserId);
        initialPayload.append('profileID', data.profileID);
        initialPayload.append('userProfileImage', data?.userProfileImage);


    const config =  data?.userProfileImage ? {
        headers: { 'content-type': 'multipart/form-data' }
    } : {};

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_USER_PROFILE_IMAGE, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}
export const getBithday_Action=(data)=>{
    const initialPayload ={
      "userID":'145114052227179500',

  ...data
    }
    console.log(initialPayload,"initialPayload")
  return (dispatch) => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(ErpApi.GET_BIRTHDAY, initialPayload)
        .then((response) => {
          const { data } = response;
         
          dispatch(mainLoadingFalse());
          if (!data.error) {
            dispatch(getBirthDay(data));
           
          } else {
            dispatch(getBirthDay({}));
          }
        })
        .catch((error) => {
          console.log("err", error);
          dispatch( getBirthDay());
          dispatch( getBirthDay());
        });
  }
  
  };
  const getBirthDay=(data)=>{
    return{
      type:GET_BIRTHDAY,
      payload:data
    }
  }

  export const updateUserGreetingImage_action = (data) => {
    let initialPayload = new FormData();
    initialPayload.append('userID', '145114052227179500');
    initialPayload.append('profileID',  data.profileID);
    initialPayload.append('userGreetingImage', data.userGreetingImage);

    console.log("data", initialPayload);
    const config = {
        headers: { 'content-type': 'multipart/form-data' }
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return FileUploadService.post(ErpApi.UPDATE_USER_GREETING_IMAGE, initialPayload, config).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const getAnniversary_Action=(data)=>{
    const initialPayload ={
      "userID":145114052227179500,
  ...data
    }
    console.log(initialPayload,"initialPayload")
  return (dispatch) => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(ErpApi.GET_WORK_ANNIVERSARY, initialPayload)
        .then((response) => {
          const { data } = response;
         
          dispatch(mainLoadingFalse());
          if (!data.error) {
            dispatch(getAnniversary(data));
           
          } else {
            dispatch(getAnniversary({}));
          }
        })
        .catch((error) => {
          console.log("err", error);
          dispatch( getAnniversary());
          dispatch( getAnniversary());
        });
  }
  
  };
  const getAnniversary=(data)=>{
    return{
      type:GET_EMPLOYEE_WORK_ANNIVERSARY,
      payload:data
    }
  }

  export const updateUserAnniversaryGreetingImage_action = (data) => {
    let initialPayload = new FormData();
    initialPayload.append('userID', '145114052227179500');
    initialPayload.append('profileID',  data.profileID);
    initialPayload.append('userGreetingImage', data.userGreetingImage);

    console.log("data", initialPayload);
    const config = {
        headers: { 'content-type': 'multipart/form-data' }
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return FileUploadService.post(ErpApi.UPDATE_USER_ANNIVERSARY_GREETING_IMAGE, initialPayload, config).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const getProjectFilter_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectPhaseID":"",
        "planningAuthorityID":"",
        "projectStatus":"",
        "sortOrder":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_PROJECT_FILTER, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getProjectListFilterSuccess(data))
            }
            else{
                dispatch(getProjectListFilterSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getProjectListFilterSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}
export const getProjectListFilterSuccess = (data) => {
    return {
        type : GET_PROJECT_LIST_FILTER,
        payload : data
    }
}

export const getProjectUpcomingFilter_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectPhaseID":"",
        "planningAuthorityID":"",
        "projectStatus":"",
        "sortOrder":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_PROJECT_UPCOMING_FILTER, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getProjectUpcomingFilterSuccess(data))
            }
            else{
                dispatch(getProjectUpcomingFilterSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getProjectUpcomingFilterSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}
export const getProjectUpcomingFilterSuccess = (data) => {
    return {
        type : GET_PROJECT_UPCOMING_FILTER,
        payload : data
    }
}

export const getCivilProjectFilter_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectPhaseID":"",
        "planningAuthorityID":"",
        "projectStatus":"",
        "sortOrder":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_FILTER, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getCivilProjectFilterSuccess(data))
            }
            else{
                dispatch(getCivilProjectFilterSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCivilProjectFilterSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}
export const getCivilProjectFilterSuccess = (data) => {
    return {
        type : GET_CIVIL_PROJECT_FILTER,
        payload : data
    }
}
export const getProjectFileFilter_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID":"",
        "villageID":"",
        "fileStatus":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_PROJECT_FILE_FILTER, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch({
                    type : GET_PROJECT_FILE_FILTER,
                    payload : data
                })
            }
            else{
                dispatch({
                    type : GET_PROJECT_FILE_FILTER,
                    payload : []
                })
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch({
                type : GET_PROJECT_FILE_FILTER,
                payload : []
            })
            dispatch(mainLoadingFalse());
        })
    }
}
export const getLiaisonProcessFilter_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID":"",
        "statusID":"Active",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_LIAISON_PROCESS_FILTER, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getLiaisonProcessFilterSuccess(data))
            }
            else{
                dispatch(getLiaisonProcessFilterSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getLiaisonProcessFilterSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}
export const getLiaisonProcessFilterSuccess = (data) => {
    return {
        type : GET_LIAISON_PROCESS_FILTER,
        payload : data
    }
}

export const getApfPanel_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "statusID":"",
        "sortOrder":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_APF_PANEL, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getApfPanelSuccess(data))
            }
            else{
                dispatch(getApfPanelSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getApfPanelSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}
export const getApfPanelSuccess = (data) => {
    return {
        type : GET_APF_PANEL,
        payload : data
    }
}

export const getApfPendingProjectFile_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_APF_PENDING_PROJECT_FILE, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getApfPendingProjectFileSuccess(data))
            }
            else{
                dispatch(getApfPendingProjectFileSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getApfPendingProjectFileSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}
export const getApfPendingProjectFileSuccess = (data) => {
    return {
        type : GET_APF_PENDING_PROJECT_FILE,
        payload : data
    }
}

export const createApfTaskPlan_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID":"",
        "taskID":"",
        "panelID":"",
        "projectFileID":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.CREATE_APF_TASK_PLAN, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                // toast.error(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "error"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const getApfTaskPlan_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":0,
        "iDisplayLength": -1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_APF_TASK_PLAN, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getApfTaskPlanSuccess(data))
            }
            else{
                dispatch(getApfTaskPlanSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getApfTaskPlanSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}
export const getApfTaskPlanSuccess = (data) => {
    return {
        type : GET_APF_TASK_PLAN,
        payload : data
    }
}
export const getApfTaskPlanDetail_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "taskPlanID":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_APF_TASK_PLAN_DETAIL, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getApfTaskPlanDetailSuccess(data))
            }
            else{
                dispatch(getApfTaskPlanDetailSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getApfTaskPlanDetailSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}
export const getApfTaskPlanDetailSuccess = (data) => {
    return {
        type : GET_APF_TASK_PLAN_DETAIL,
        payload : data
    }
}
export const updateApfTaskRemark_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        //"taskType":"ProjectTask",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_APF_TASK_REMARK, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const updateApfTaskPlanOwner_action = (data) => {
    let initialPayload = {
        "userID": LoginuserId,
        "taskPlanID":"",
        "ownerID":"",
        "changeRemark":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_APF_TASK_PLAN_OWNER, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}
export const uploadApfTaskDocument_action = (data) => {
    let initialPayload = new FormData();
    initialPayload.append('userID', LoginuserId);
    initialPayload.append('documentType',  data.documentType);
    initialPayload.append('documentRemark', data.documentRemark);
    initialPayload.append('taskPlanID', data.taskPlanID);
    initialPayload.append('documentFile', data.documentFile);
    // initialPayload.append('taskType', data?.taskTypeID ? data?.taskTypeID : "ProjectTask");

    console.log("data", initialPayload);
    const config = {
        headers: { 'content-type': 'multipart/form-data' }
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return FileUploadService.post(ErpApi.UPLOAD_APF_TASK_DOCUMENT, initialPayload, config).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}
export const updateApfTaskPlanStart_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "taskPlanID":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_APF_TASK_PLAN_START, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}
export const updateApfTaskPlanComplete_action = (data) => {
    let initialPayload = new FormData();
    initialPayload.append('userID', LoginuserId);
    initialPayload.append('taskPlanID', data.taskPlanID);
    initialPayload.append('taskCompletedDate', data.taskCompletedDate);
    initialPayload.append('documentType',  data.documentType);
    initialPayload.append('documentRemark', "");
    initialPayload.append('documentFile', data.documentFile);
    // initialPayload.append('subTaskPlanID', JSON.stringify(data.subTaskPlanID));

    console.log("data", initialPayload);
    const config = {
        headers: { 'content-type': 'multipart/form-data' }
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return FileUploadService.post(ErpApi.UPDATE_APF_TASK_PLAN_COMPLETE, initialPayload, config).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const getApfTaskPlanProjectFilter_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID":"",
        "taskID":"",
        "panelID":"",
        "projectFileID":"",
        "taskOwnerID":"",
        "taskStatus":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_APF_TASK_PLAN_PROJECT_FILTER, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getApfTaskPlanProjectFilterSuccess(data))
            }
            else{
                dispatch(getApfTaskPlanProjectFilterSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getApfTaskPlanProjectFilterSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}
export const getApfTaskPlanProjectFilterSuccess = (data) => {
    return {
        type : GET_APF_TASK_PLAN_PROJECT_FILTER,
        payload : data
    }
}
export const getApfTaskPlanPanelFilter_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID":"",
        "taskID":"",
        "panelID":"",
        "projectFileID":"",
        "taskOwnerID":"",
        "taskStatus":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_APF_TASK_PLAN_PANEL_FILTER, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getApfTaskPlanPanelFilterSuccess(data))
            }
            else{
                dispatch(getApfTaskPlanPanelFilterSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getApfTaskPlanPanelFilterSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}
export const getApfTaskPlanPanelFilterSuccess = (data) => {
    return {
        type : GET_APF_TASK_PLAN_PANEL_FILTER,
        payload : data
    }
}
export const getApfTaskPlanProjectFileFilter_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID":"",
        "taskID":"",
        "panelID":"",
        "projectFileID":"",
        "taskOwnerID":"",
        "taskStatus":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_APF_TASK_PLAN_PROJECT_FILE_FILTER, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getApfTaskPlanProjectFileFilterSuccess(data))
            }
            else{
                dispatch(getApfTaskPlanProjectFileFilterSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getApfTaskPlanProjectFileFilterSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}
export const getApfTaskPlanProjectFileFilterSuccess = (data) => {
    return {
        type : GET_APF_TASK_PLAN_PROJECT_FILE_FILTER,
        payload : data
    }
}



export const getApfTaskPlanTaskFilter_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID":"",
        "taskID":"",
        "panelID":"",
        "projectFileID":"",
        "taskOwnerID":"",
        "taskStatus":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_APF_TASK_PLAN_TASK_FILTER, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getApfTaskPlanTaskFilterSuccess(data))
            }
            else{
                dispatch(getApfTaskPlanTaskFilterSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getApfTaskPlanTaskFilterSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}
export const getApfTaskPlanTaskFilterSuccess = (data) => {
    return {
        type : GET_APF_TASK_PLAN_TASK_FILTER,
        payload : data
    }
}













export const getApfTask_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID":"",
        "projectFileID":"",
        "panelID":"",
        "taskID":"",
        "taskOwnerID":"",
        "taskStatus":"",
        "statusID":"Active",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_APF_TASK_FILTER, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getApfTaskSuccess(data))
            }
            else{
                dispatch(getApfTaskSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getApfTaskSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}
export const getApfTaskSuccess = (data) => {
    return {
        type : GET_APF_TASK_FILTER,
        payload : data
    }
}
export const getApfTaskPlanQuery_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "taskPlanID":"",
        "projectID":"",
        "taskID":"",
        "panelID":"",
        "projectFileID":"",
        "queryOwnerID":"",
        "queryStatus":"",
        "statusID":"Active",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":"",
        "iDisplayLength":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_APF_TASK_PLAN_QUERY, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getApfTaskPlanQuerySuccess(data))
            }
            else{
                dispatch(getApfTaskPlanQuerySuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getApfTaskPlanQuerySuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}
export const getApfTaskPlanQuerySuccess = (data) => {
    return {
        type : GET_APF_TASK_PLAN_QUERY,
        payload : data
    }
}
export const createApfTaskPlanQuery_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "apfTaskPlanID":"",
        "taskOwnerID":"",
        "queryDescription":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.CREATE_APF_TASK_PLAN_QUERY, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                // toast.error(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "error"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}
export const uploadApfTaskQueryDocument_action = (data) => {
    let initialPayload = new FormData();
    initialPayload.append('userID', LoginuserId);
    initialPayload.append('taskPlanQueryID', data.taskPlanQueryID);
    initialPayload.append('documentType',  data.documentType);
    initialPayload.append('documentRemark', data.documentRemark);
    initialPayload.append('documentFile', data.documentFile);

    console.log("data", initialPayload);
    const config = {
        headers: { 'content-type': 'multipart/form-data' }
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return FileUploadService.post(ErpApi.UPLOAD_APF_TASK_QUERY_DOCUMENT, initialPayload, config).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}
export const getApfTaskPlanQueryDetail_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "apfQueryID":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_APF_TASK_PLAN_QUERY_DETAIL, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch({
                    type : GET_APF_TASK_PLAN_QUERY_DETAIL,
                    payload : data
                })
            }
            else{
                dispatch({
                    type : GET_APF_TASK_PLAN_QUERY_DETAIL,
                    payload : []
                })
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch({
                type : GET_APF_TASK_PLAN_QUERY_DETAIL,
                payload : []
            })
            dispatch(mainLoadingFalse());
        })
    }
}
export const updateApfTaskPlanQueryOwner_action = (data) => {
    let initialPayload = {
        "userID": LoginuserId,
        "taskPlanQueryID":"",
        "ownerID":"",
        "changeRemark":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_APF_TASK_PLAN_QUERY_OWNER, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}
export const updateApfTaskPlanQueryStatus_action = (data) => {
    let initialPayload = new FormData();
    initialPayload.append('userID', LoginuserId);
    initialPayload.append('taskPlanQueryID', data.taskPlanQueryID);
    initialPayload.append('queryStatusID', data.queryStatusID);
    initialPayload.append('documentType',  data.documentType);
    initialPayload.append('documentRemark', data.documentRemark);
    initialPayload.append('documentFile', data.documentFile);
    // initialPayload.append('subTaskPlanID', JSON.stringify(data.subTaskPlanID));

    console.log("data", initialPayload);
    const config = {
        headers: { 'content-type': 'multipart/form-data' }
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return FileUploadService.post(ErpApi.UPDATE_APF_TASK_PLAN_QUERY_STATUS, initialPayload, config).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}
export const getLiaisonDocumentationReport_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID":"",
        "projectFileID":"",
        "taskPlanStatus":"",
        "documentType":"",
        "taskOwnerID":"",
        "categoryID":"",
        "sortOrder":"",
        "iDisplayStart":"1",
        "iDisplayLength":"20",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_LIAISON_DOCUMENTATION_REPORT, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getLiaisonDocumentationReportSuccess(data))
            }
            else{
                dispatch(getLiaisonDocumentationReportSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getLiaisonDocumentationReportSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}
export const getLiaisonDocumentationReportSuccess = (data) => {
    return {
        type : GET_LIAISON_DOCUMENTATION_REPORT,
        payload : data
    }
}
export const getBdLandDisputeType_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "statusID":"Active",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_BD_LAND_DISPUTE_TYPE, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getBdLandDisputeTypeSuccess(data))
            }
            else{
                dispatch(getBdLandDisputeTypeSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getBdLandDisputeTypeSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getBdLandDisputeTypeSuccess = (data) => {
    return {
        type : GET_BD_LAND_DISPUTE_TYPE,
        payload : data
    }
}

export const createBdLandDispute_action = (data) => {
    let initialPayload = new FormData();
    initialPayload.append('userID', LoginuserId);
    initialPayload.append('projectFileID',  data.projectFileID);
    initialPayload.append('issueTypeID', data.issueTypeID);
    initialPayload.append('handledByID', data.handledByID);
    initialPayload.append('issueDescription', data.issueDescription);
    initialPayload.append('documentFile', data.documentFile);

    console.log("data", initialPayload);
    const config = {
        headers: { 'content-type': 'multipart/form-data' }
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return FileUploadService.post(ErpApi.CREATE_BD_LAND_DISPUTE, initialPayload, config).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}
export const getBdLandDispute_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID":"",
        "projectFileID":"",
        "issueTypeID":"",
        "issueHandledBy":"",
        "issueStatus":"",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_BD_LAND_DISPUTE, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getBdLandDisputeSuccess(data))
            }
            else{
                dispatch(getBdLandDisputeSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getBdLandDisputeSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getBdLandDisputeSuccess = (data) => {
    return {
        type : GET_BD_LAND_DISPUTE_LIST,
        payload : data
    }
}
export const uploadBdLandDisputDocument_action = (data) => {
    let initialPayload = new FormData();
    initialPayload.append('userID', LoginuserId);
    initialPayload.append('disputeID', data.disputeID);
    initialPayload.append('documentType',  data.documentType);
    initialPayload.append('documentRemark', data.documentRemark);
    initialPayload.append('documentFile', data.documentFile);

    console.log("data", initialPayload);
    const config = {
        headers: { 'content-type': 'multipart/form-data' }
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return FileUploadService.post(ErpApi.UPLOAD_BD_LAND_DISPUTE_DOCUMENT, initialPayload, config).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}
export const getBdLandDisputeDetail_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        landDisputeID:"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_BD_LAND_DISPUTE_DETAIL, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch({
                    type : GET_BD_LAND_DISPUTE_DETAIL,
                    payload : data
                })
            }
            else{
                dispatch({
                    type : GET_BD_LAND_DISPUTE_DETAIL,
                    payload : []
                })
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch({
                type : GET_BD_LAND_DISPUTE_DETAIL,
                payload : []
            })
            dispatch(mainLoadingFalse());
        })
    }
}
export const updateBdLandDisputOwner_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "landDisputeID":"",
        "ownerID":"",
        "changeRemark":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_LAND_DISPUTE_OWNER, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}
export const updateBdLandDisputRemark_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "landDisputeID":"",
        "nextFollowUpDate":"",
        "followUpRemark":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_LAND_DISPUTE_REMARK, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}
export const updateBdLandDisputStatus_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "landDisputeID":"",
        "disputeStatusID":"",
        "updateRemark":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_BD_LAND_DISPUTE_STATUS, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const getLiaisonDocumentationReportProjectFilter_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID":"",
        "projectFileID":"",
        "taskPlanStatus":"",
        "taskOwnerID":"",
        "categoryID":"",
        "documentType":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_LIAISON_DOCUMENTATION_REPORT_PROJECT_FILTER, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch({
                    type : GET_LIAISON_DOCUMENTATION_REPORT_PROJECT_FILTER,
                    payload : data.data
                })
            }
            else{
                dispatch({
                    type : GET_LIAISON_DOCUMENTATION_REPORT_PROJECT_FILTER,
                    payload : []
                })
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch({
                type : GET_LIAISON_DOCUMENTATION_REPORT_PROJECT_FILTER,
                payload : []
            })
            dispatch(mainLoadingFalse());
        })
    }
}

export const getLiaisonDocumentationReportProjectFileFilter_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID":"",
        "villageID":"",
        "fileStatus":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_LIAISON_DOCUMENTATION_REPORT_PROJECT_FILE_FILTER, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch({
                    type : GET_LIAISON_DOCUMENTATION_REPORT_PROJECT_FILE_FILTER,
                    payload : data
                })
            }
            else{
                dispatch({
                    type : GET_LIAISON_DOCUMENTATION_REPORT_PROJECT_FILE_FILTER,
                    payload : []
                })
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch({
                type : GET_LIAISON_DOCUMENTATION_REPORT_PROJECT_FILE_FILTER,
                payload : []
            })
            dispatch(mainLoadingFalse());
        })
    }
}
export const getLiaisonDocumentationReportCategoryFilter_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "statusID":"",
        "generalSearch": "",
        "sortOrder": "",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_LIAISON_DOCUMENTATION_REPORT_CATEGORY_FILTER, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch({
                    type : GET_LIAISON_DOCUMENTATION_REPORT_CATEGORY_FILTER,
                    payload : data
                })
            }
            else{
                dispatch({
                    type : GET_LIAISON_DOCUMENTATION_REPORT_CATEGORY_FILTER,
                    payload : []
                })
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch({
                type : GET_LIAISON_DOCUMENTATION_REPORT_CATEGORY_FILTER,
                payload : []
            })
            dispatch(mainLoadingFalse());
        })
    }
}
export const getLiaisonDocumentationReportOwnerFilter_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_LIAISON_DOCUMENTATION_REPORT_OWNER_FILTER, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch({
                    type : GET_LIAISON_DOCUMENTATION_REPORT_OWNER_FILTER,
                    payload : data
                })
            }
            else{
                dispatch({
                    type : GET_LIAISON_DOCUMENTATION_REPORT_OWNER_FILTER,
                    payload : []
                })
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch({
                type : GET_LIAISON_DOCUMENTATION_REPORT_OWNER_FILTER,
                payload : []
            })
            dispatch(mainLoadingFalse());
        })
    }
}

export const getCourtCaseType_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "statusID": "",
        "sortOrder": "",
        "generalSearch":"",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_COURT_CASE_TYPE, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getCourtCaseTypeSuccess(data))
            }
            else{
                dispatch(getCourtCaseTypeSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCourtCaseTypeSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getCourtCaseTypeSuccess = (data) => {
    return {
        type : GET_COURT_CASE_TYPE,
        payload : data
    }
}

export const getCourtCaseStatus_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "statusID": "",
        "sortOrder": "",
        "generalSearch":"",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_COURT_CASE_STATUS, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getCourtCaseStatusSuccess(data))
            }
            else{
                dispatch(getCourtCaseStatusSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCourtCaseStatusSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getCourtCaseStatusSuccess = (data) => {
    return {
        type : GET_COURT_CASE_STATUS,
        payload : data
    }
}
export const getCourtCaseJurisdiction_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "statusID": "",
        "sortOrder": "",
        "generalSearch":"",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_COURT_CASE_JURISDICTION, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getCourtCaseJurisdictionSuccess(data))
            }
            else{
                dispatch(getCourtCaseJurisdictionSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCourtCaseJurisdictionSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getCourtCaseJurisdictionSuccess = (data) => {
    return {
        type : GET_COURT_CASE_JURISDICTION,
        payload : data
    }
}
export const getDirector_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "statusID": "",
        "sortOrder": "",
        "generalSearch":"",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_DIRECTOR, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getDirectorSuccess(data))
            }
            else{
                dispatch(getDirectorSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getDirectorSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getDirectorSuccess = (data) => {
    return {
        type : GET_DIRECTOR,
        payload : data
    }
}

// export const createCourtCase_action = (data) => {
//     const initialPayload = {
//         "userID": LoginuserId,
//         ...data
//     }
//     return dispatch => {
//         dispatch(mainLoadingTrue());
//         return ErpApiService.post(ErpApi.CREATE_COURT_CASE, initialPayload).then(response => {
//             const {data}  = response;
//             dispatch(mainLoadingFalse());
//             if(!data.error)
//             {
//                 // toast.success(data.message);
//                 dispatch(displayMessage({
//                     text : data.message,
//                     type : "success"
//                 }));
//             }
//             else{
//                 // toast.error(data.message);
//                 dispatch(displayMessage({
//                     text : data.message,
//                     type : "error"
//                 }));
//             }
//         })
//         .catch(err => {
//             dispatch(mainLoadingFalse());
//         })
//     }
// }


export const createCourtCase_action = (data) => {

    let initialPayload = new FormData();
    initialPayload.append('userID', LoginuserId);

    for(var i = 0 ; i < data.taskDocuments.length; i++) {
        initialPayload.append('documentFile'+data.taskDocuments[i].documentType+i, data.taskDocuments[i].documentFile);
    }
    
    initialPayload.append('taskDocuments', JSON.stringify(data.taskDocuments));



    initialPayload.append('caseOn', data.caseOn);
    initialPayload.append('caseOnID', JSON.stringify(data.caseOnID));
    initialPayload.append('caseTypeID', data.caseTypeID);
    initialPayload.append('caseNumber', data.caseNumber);
    initialPayload.append('caseJurisdiction', data.caseJurisdiction);
    initialPayload.append('caseStatus', data.caseStatus);
    initialPayload.append('fillingDate', data.fillingDate);
    initialPayload.append('casePlaintiff', data.casePlaintiff);
    initialPayload.append('caseDefendant', data.caseDefendant);
    initialPayload.append('caseNature', data.caseNature);
    initialPayload.append('caseDescription', data.caseDescription);
    initialPayload.append('handledByID', data.handledByID);



    console.log("data", initialPayload);
    const config = {
        headers: { 'content-type': 'multipart/form-data' }
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return FileUploadService.post(ErpApi.CREATE_COURT_CASE, initialPayload, config).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}




export const getCourtCase_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "caseOn":"",
        "projectID":"",
        "caseTypeID":"",
        "caseHandledBy":"",
        "caseStatus":"",
        "caseJurisdiction":"",
        "sortOrder": "",
        "generalSearch":"",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_COURT_CASE, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getCourtCaseSuccess(data))
            }
            else{
                dispatch(getCourtCaseSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCourtCaseSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getCourtCaseSuccess = (data) => {
    return {
        type : GET_COURT_CASE,
        payload : data
    }
}

export const uploadCourtCaseDocument_action = (data) => {
    let initialPayload = new FormData();
    initialPayload.append('userID', LoginuserId);


    for(var i = 0 ; i < data.taskDocuments.length; i++) {
        initialPayload.append('documentFile'+data.taskDocuments[i].documentType+i, data.taskDocuments[i].documentFile);
    }
    
    initialPayload.append('taskDocuments', JSON.stringify(data.taskDocuments));



    initialPayload.append('courtCaseID', data.courtCaseID);
    initialPayload.append('documentType',  data.documentType);
    initialPayload.append('documentRemark', data.documentRemark);
    // initialPayload.append('documentFile', data.documentFile);

    console.log("data", initialPayload);
    const config = {
        headers: { 'content-type': 'multipart/form-data' }
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return FileUploadService.post(ErpApi.UPLOAD_COURT_CASE_DOCUMENT, initialPayload, config).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}
export const updateCourtCaseOwner_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "courtCaseID":"",
        "ownerID":"",
        "changeRemark":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_COURT_CASE_OWNER, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}
export const updateCourtCaseStatus_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "courtCaseID":"",
        "courtCaseStatusID":"",
        "updateRemark":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_COURT_CASE_STATUS, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}
export const updateCourtCaseRemark_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "courtCaseID":"",
        "nextFollowUpDate":"",
        "followUpRemark":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_COURT_CASE_REMARK, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}
export const getCourtCaseDetail_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "courtCaseID":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_COURT_CASE_DETAIL, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch({
                    type : GET_COURT_CASE_DETAIL,
                    payload : data
                })
            }
            else{
                dispatch({
                    type : GET_COURT_CASE_DETAIL,
                    payload : []
                })
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch({
                type : GET_COURT_CASE_DETAIL,
                payload : []
            })
            dispatch(mainLoadingFalse());
        })
    }
}
export const updateCourtCase_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "courtCaseID":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_COURT_CASE, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}
export const updateBdProjectTaskPlanStart_action = (data) => {
    console.log("Data11",data)
    const initialPayload = {
        "userID": LoginuserId,
        "taskPlanID":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_BD_PROJECT_TASK_PLAN_START, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}


export const createTrainingContent_action = (data) => {
    let initialPayload = new FormData();
    initialPayload.append('userID', LoginuserId);
    initialPayload.append('functionalityID', data.functionalityID);
    initialPayload.append('videoDescription', data.videoDescription);
    initialPayload.append('videoFile', data.videoFile);

    console.log("initialPayload", initialPayload);
    const config = {
        headers: { 'content-type': 'multipart/form-data' }
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return FileUploadService.post(ErpApi.CREATE_TRAINING_CONTENT, initialPayload, config).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const getTrainingContent_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "menuID":"",
        "functionalityID":"",
        "statusID":"",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_TRAINING_CONTENT, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getTrainingContentSuccess(data))
            }
            else{
                dispatch(getTrainingContentSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getTrainingContentSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getTrainingContentSuccess = (data) => {
    return {
        type : GET_TRAINING_CONTENT,
        payload : data
    }
}

export const updateTrainingContent_action = (data) => {
    let initialPayload = {
        "userID": LoginuserId,
        "trainingContentID":"",
        "videoDescription":"",
        "statusID":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_TRAINING_CONTENT, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const deleteProjectFileDocument_action = (data) => {
    let initialPayload = {
        "userID": LoginuserId,
        "documentID" : "",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.DELETE_PROJECT_FILE_DOCUMENT, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const getBdProjectTaskRejectionReason_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "reasonID":"",
        "status":"",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_BD_PROJECT_TASK_REJECTION_REASON, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch({
                    type : GET_BD_PROJECT_TASK_REJECTION_REASON,
                    payload : data
                })
            }
            else{
                dispatch({
                    type : GET_BD_PROJECT_TASK_REJECTION_REASON,
                    payload : []
                })
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch({
                type : GET_BD_PROJECT_TASK_REJECTION_REASON,
                payload : []
            })
            dispatch(mainLoadingFalse());
        })
    }
}
export const getBdAgreementType_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "agreementTypeID":"",
        "status":"",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_BD_AGREEMENT_TYPE, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch({
                    type : GET_BD_AGREEMENT_TYPE,
                    payload : data.data
                })
            }
            else{
                dispatch({
                    type : GET_BD_AGREEMENT_TYPE,
                    payload : []
                })
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch({
                type : GET_BD_AGREEMENT_TYPE,
                payload : []
            })
            dispatch(mainLoadingFalse());
        })
    }
}

export const getBdProjectReport_action = (data) => {
    const initialPayload ={
        "userID": LoginuserId,
        "projectID":"",
        "statusID":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_BD_PROJECT_REPORT, initialPayload).then(response => {
            const {data}  = response;

            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getBdProjectReportSuccess(data))
            }
            else{
                dispatch(getBdProjectReportSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getBdProjectReportSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getBdProjectReportSuccess = (data) => {
    return {
        type : GET_BD_PROJECT_REPORT,
        payload : data
    }
}

export const getBdProjectTaskReport_action = (data) => {
    const initialPayload ={
        "userID": LoginuserId,
        "projectID":"",
        "statusID":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_BD_PROJECT_TASK_REPORT, initialPayload).then(response => {
            const {data}  = response;

            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getBdProjectTaskReportSuccess(data))
            }
            else{
                dispatch(getBdProjectTaskReportSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getBdProjectTaskReportSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getBdProjectTaskReportSuccess = (data) => {
    return {
        type : GET_BD_PROJECT_TASK_REPORT,
        payload : data
    }
}

export const createProjectProposedName_action = (data) => {
    const initialPayload = {
        "userID":LoginuserId,
        "taskPlanID":"",
        "projectName":"",
        "projectNameDescription":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.CREATE_PROJECT_PROPOSED_NAME, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                // toast.error(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "error"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}


export const getProjectProposedName_action = (data) => {
    const initialPayload ={
        "userID": LoginuserId,
        "taskPlanID":"",
        "projectID":"",
        "statusID":"",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":"1",
        "iDisplayLength":"-1",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_PROJECT_PROPOSED_NAME, initialPayload).then(response => {
            const {data}  = response;

            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getProjectProposedNameSuccess(data))
            }
            else{
                dispatch(getProjectProposedNameSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getProjectProposedNameSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getProjectProposedNameSuccess = (data) => {
    return {
        type : GET_PROJECT_PROPOSED_NAME,
        payload : data
    }
}

export const deleteProjectProposedName_action = (data) => {
    let initialPayload = {
        "userID": LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.DELETE_PROJECT_PROPOSED_NAME, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const updateProjectProposedNameStatus_action = (data) => {
    let initialPayload = {
        "userID": LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_PROJECT_PROPOSED_NAME_STATUS, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}
// -----------------

///delete content
export const deleleTrainingContent_action = (data) => {
    let initialPayload = {
        "userID": LoginuserId,
        
        "trainingContentID":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.DELETE_TRANING_CONTENT, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}
// getMyTraning

export const getMyTraining_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "menuID":"",
        "functionalityID":"",
        "statusID":"",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_TRAINING, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getMyTraning(data))
            }
            else{
                dispatch(getMyTraning({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getMyTraning([]))
            dispatch(mainLoadingFalse());
        })
    }
}



const getMyTraning=(data)=>{
    return {
       type:GET_TRAINING,
       payload:data 
    }
}

// multiple document upload 
export const uploadProjectTaskDocuments_action = (data) => {
    let initialPayload = new FormData();

    for(var i = 0 ; i < data.taskDocuments.length; i++) {
        initialPayload.append('documentFile'+data.taskDocuments[i].documentType+i, data.taskDocuments[i].documentFile);
    }

    initialPayload.append('taskDocuments', JSON.stringify(data.taskDocuments));

    initialPayload.append('userID', LoginuserId);
    // initialPayload.append('documentType',  data.documentType);
    initialPayload.append('documentRemark', data.documentRemark);
    initialPayload.append('taskPlanID', data.taskPlanID);
    // initialPayload.append('documentFile', data.documentFile);
    initialPayload.append('taskType', data?.taskTypeID ? data?.taskTypeID : "ProjectTask");

    console.log("data", initialPayload);
    const config = {
        headers: { 'content-type': 'multipart/form-data' }
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return FileUploadService.post(ErpApi.UPDATE_PROJECT_UPLOAD_DOCUMENTS, initialPayload, config).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const updateProjectSubTaskCompleteStatus_action = (data) => {
    let initialPayload = new FormData();
    // initialPayload.append('documentType',  37);
    initialPayload.append('userID', LoginuserId);

    for(var i = 0 ; i < data.taskDocuments.length; i++) {
        initialPayload.append('documentFile'+data.taskDocuments[i].documentType+i, data.taskDocuments[i].documentFile);
    }

    initialPayload.append('taskDocuments', JSON.stringify(data.taskDocuments));

    initialPayload.append('taskCompletedDate', data.taskCompletedDate);
    initialPayload.append('taskPlanID', data.taskPlanID);
    initialPayload.append('subTaskPlanID', JSON.stringify(data.subTaskPlanID));
    initialPayload.append('documentRemark', "");
    // initialPayload.append('documentFile', data.documentFile);
    initialPayload.append('taskType', data.taskType);

    console.log("data", initialPayload);
    const config = {
        headers: { 'content-type': 'multipart/form-data' }
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return FileUploadService.post(ErpApi.UPDATE_PROJECT_SUB_TASK_COMPLETE_STATUS, initialPayload, config).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

// ---------------------- 18 july

export const getLiaisonProcessPlanProject_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID":"",
        "projectFileID":"",
        "issueTypeID":"",
        "issueHandledBy":"",
        "issueStatus":"",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_LIAISON_PROCESS_PLAN_PROJECT, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getLiaisonProcessPlanProjectSuccess(data))
            }
            else{
                dispatch(getLiaisonProcessPlanProjectSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getLiaisonProcessPlanProjectSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getLiaisonProcessPlanProjectSuccess = (data) => {
    return {
        type : GET_LIAISON_PROCESS_PLAN_PROJECT,
        payload : data
    }
}



export const getLiaisonProcessPlanProcess_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID":"",
        "processID":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_LIAISON_PROCESS_PLAN_PROCESS, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getLiaisonProcessPlanProcessSuccess(data))
            }
            else{
                dispatch(getLiaisonProcessPlanProcessSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getLiaisonProcessPlanProcessSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getLiaisonProcessPlanProcessSuccess = (data) => {
    return {
        type : GET_LIAISON_PROCESS_PLAN_PROCESS,
        payload : data
    }
}


export const createLiaisonPayment_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "paymentTypeID":"",
        "projectID":"",
        "processID":"",
        "companyID":"",
        "paymentRemark":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.CREATE_LIAISON_PAYMENT, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                // toast.error(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "error"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const getLiaisonPayment_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "paymentTypeID":"",
        "projectID":"",
        "processID":"",
        "companyID":"",
        "requestedByID":"",
        "paymentStatusID":"",
        "settlementStatusID":"",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":"",
        "iDisplayLength":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_LIAISON_PAYMENT_LIST, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getLiaisonPaymentSuccess(data))
            }
            else{
                dispatch(getLiaisonPaymentSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getLiaisonPaymentSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}
export const getLiaisonPaymentSuccess = (data) => {
    return {
        type : GET_LIAISON_PAYMENT_LIST,
        payload : data
    }
}
export const getLiaisonPaymentDetail_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "liaisonPaymentID":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_LIAISON_PAYMENT_DETAIL, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getLiaisonPaymentDetailSuccess(data))
            }
            else{
                dispatch(getLiaisonPaymentDetailSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getLiaisonPaymentDetailSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}
export const getLiaisonPaymentDetailSuccess = (data) => {
    return {
        type : GET_LIAISON_PAYMENT_DETAIL,
        payload : data
    }
}

export const getLiaisonPaymentSurveyNumber_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID":"",
        "processID":"",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":0,
        "iDisplayLength":10,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_LIAISON_PAYMENT_SURVEY_NUMBER, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getLiaisonPaymentSurveyNumberSuccess(data))
            }
            else{
                dispatch(getLiaisonPaymentSurveyNumberSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getLiaisonPaymentSurveyNumberSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}
export const getLiaisonPaymentSurveyNumberSuccess = (data) => {
    return {
        type : GET_LIAISON_PAYMENT_SURVEY_NUMBER,
        payload : data
    }
}



export const getLiaisonPaymentTaskPlan_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "liaisonPaymentID":"",
        "processPlanID":"",
          ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_LIAISON_PAYMENT_TASK_PLAN, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getLiaisonPaymentTaskPlanSuccess(data))
            }
            else{
                dispatch(getLiaisonPaymentTaskPlanSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getLiaisonPaymentTaskPlanSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}
export const getLiaisonPaymentTaskPlanSuccess = (data) => {
    return {
        type : GET_LIAISON_PAYMENT_TASK_PLAN,
        payload : data
    }
}

export const addLiaisonPaymentItem_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "liaisonPaymentID":"",
        "taskPlanIDs":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.ADD_LIAISON_PAYMENT_ITEM, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                // toast.error(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "error"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}
export const deleteLiaisonPayment_action = (data) => {
    let initialPayload = {
        "userID": LoginuserId,
        "liaisonPaymentID" : "",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.DELETE_LIAISON_PAYMENT, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}
export const deleteLiaisonPaymentItem_action = (data) => {
    let initialPayload = {
        "userID": LoginuserId,
        "liaisonPaymentItemID" : "",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.DELETE_LIAISON_PAYMENT_ITEM, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}


export const updateLiaisonPaymentStatus_action = (data) => {
    let initialPayload = {
        "userID": LoginuserId,
        "liaisonPaymentID" : "",
        "paymentStatusID" : "",
        "statusRemark" : "",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_LIAISON_PAYMENT_STATUS, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}



export const updateLiaisonPaymentIssue_action = (data) => {
    let initialPayload = {
        "userID": LoginuserId,
        "liaisonPaymentID" : "",
        "paymentStatusID":"",
        "companyID":"",
        "issueRemark":"",
        "paymentMode":"",
        "instrumentDate":"",
        "instrumentDetail":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_LIAISON_PAYMENT_ISSUE, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const updateLiaisonPaymentItemActualAmount_action = (data) => {
    let initialPayload = {
        "userID": LoginuserId,
        "liaisonPaymentID" : "",
        "updateRemark":"",
        "taskPlanIDs":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_LIAISON_PAYMENT_ITEM_ACTUAL_AMOUNT, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const updateLiaisonPaymentSettlementApproval_action = (data) => {
    let initialPayload = {
        "userID": LoginuserId,
        "liaisonPaymentID" : "",
        "paymentStatusID" : "",
        "statusRemark" : "",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_LIAISON_PAYMENT_SETTLEMENT_APPROVAL, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const updateLiaisonPaymentSettlementSettle_action = (data) => {
    let initialPayload = {
        "userID": LoginuserId,
        "liaisonPaymentID" : "",
        "paymentStatusID" : "",
        "statusRemark" : "",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_LIAISON_PAYMENT_SETTLEMENT_SETTLE, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const getFunctionalityPermissionDetail_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "functionalityID":"",
        "functionalityItemIDs":[
            {   "functionalityID":61,
                "functionalityName":"Request"
            }, 
            {
                "functionalityID":62,
                "functionalityName":"Approved"
            }, 
            {   "functionalityID":63,
                "functionalityName":"Issue"
            }
        ],
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_FUNCTIONALITY_PERMISSION_DETAIL, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getFunctionalityPermissionDetailSuccess(data))
            }
            else{
                dispatch(getFunctionalityPermissionDetailSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getFunctionalityPermissionDetailSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getFunctionalityPermissionDetailSuccess = (data) => {
    return {
        type : GET_FUNCTIONALITY_PERMISSION_DETAIL,
        payload : data
    }
}

export const resetFunctionalityPermissionDetail_action = () => {
    return {
        type : GET_FUNCTIONALITY_PERMISSION_DETAIL,
        payload : []
    }
}

export const getLiaisonPaymentFilterProject_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "paymentTypeID":"",
        "companyID":"",
        "projectID":"",
        "processID":"",
        "requestedByID":"",
        "paymentStatusID":"",
        "settlementStatusID":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_LIAISON_PAYMENT_FILTER_PROJECT, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getLiaisonPaymentFilterProjectSuccess(data))
            }
            else{
                dispatch(getLiaisonPaymentFilterProjectSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getLiaisonPaymentFilterProjectSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getLiaisonPaymentFilterProjectSuccess = (data) => {
    return {
        type : GET_LIAISON_PAYMENT_FILTER_PROJECT,
        payload : data
    }
}

export const getLiaisonPaymentFilterRequestedBy_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "paymentTypeID":"",
        "companyID":"",
        "projectID":"",
        "processID":"",
        "requestedByID":"",
        "paymentStatusID":"",
        "settlementStatusID":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_LIAISON_PAYMENT_FILTER_REQUESTED_BY, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getLiaisonPaymentFilterRequestedBySuccess(data))
            }
            else{
                dispatch(getLiaisonPaymentFilterRequestedBySuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getLiaisonPaymentFilterRequestedBySuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getLiaisonPaymentFilterRequestedBySuccess = (data) => {
    return {
        type : GET_LIAISON_PAYMENT_FILTER_REQUESTED_BY,
        payload : data
    }
}

export const getProjectTaskPlanFilterTaskOwner_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID":"",
        "taskID":"",
        "taskOwnerID":"",
        "taskPlanStatusID":"",
        "adminView":"",
        "reportView":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_PROJECT_TASK_PLAN_FILTER_TASK_OWNER, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch({
                    type : GET_PROJECT_TASK_PLAN_FILTER_TASK_OWNER,
                    payload : data
                })
            }
            else{
                dispatch({
                    type : GET_PROJECT_TASK_PLAN_FILTER_TASK_OWNER,
                    payload : []
                })
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch({
                type : GET_PROJECT_TASK_PLAN_FILTER_TASK_OWNER,
                payload : []
            })
            dispatch(mainLoadingFalse());
        })
    }
}
export const getProjectTaskPlanFilterTask_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID":"",
        "taskID":"",
        "taskOwnerID":"",
        "taskPlanStatusID":"",
        "adminView":"",
        "reportView":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_PROJECT_TASK_PLAN_FILTER_TASK, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch({
                    type : GET_PROJECT_TASK_PLAN_FILTER_TASK,
                    payload : data
                })
            }
            else{
                dispatch({
                    type : GET_PROJECT_TASK_PLAN_FILTER_TASK,
                    payload : []
                })
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch({
                type : GET_PROJECT_TASK_PLAN_FILTER_TASK,
                payload : []
            })
            dispatch(mainLoadingFalse());
        })
    }
}

export const updateLiaisonTaskPlanStart_action = (data) => {
    let initialPayload = new FormData();
    initialPayload.append('userID', LoginuserId);

    for(var i = 0 ; i < data.taskDocuments.length; i++) {
        
        initialPayload.append('documentFile'+data.taskDocuments[i].documentType+i, data.taskDocuments[i].documentFile);
    }

    initialPayload.append('taskDocuments', JSON.stringify(data.taskDocuments));

    initialPayload.append('documentRemark', data?.documentRemark);
    initialPayload.append('taskPlanID', data.taskPlanID);

    console.log("data", initialPayload);
    const config = {
        headers: { 'content-type': 'multipart/form-data' }
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return FileUploadService.post(ErpApi.UPDATE_LIAISON_TASK_PLAN_START, initialPayload, config).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const updateLiaisonTaskPlanOwners_action = (data) => {
    let initialPayload = {
        "userID": LoginuserId,
        "taskPlanID":"",
        "taskPlanOwnerID":"",
        "changeRemark":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_LIAISON_TASK_PLAN_OWNERS, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const getLiaisonDocumentationTaskPlanPending_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID":"",
        "projectFileID":[],
        "categoryID":"",
        "documentType":"",
        "sortOrder":"",
        "iDisplayStart":"",
        "iDisplayLength":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_LIAISON_DOCUMENT_TASK_PLAN_PENDING, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch({
                    type : GET_LIAISON_DOCUMENT_TASK_PLAN_PENDING,
                    payload : data
                })
            }
            else{
                dispatch({
                    type : GET_LIAISON_DOCUMENT_TASK_PLAN_PENDING,
                    payload : []
                })
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch({
                type : GET_LIAISON_DOCUMENT_TASK_PLAN_PENDING,
                payload : []
            })
            dispatch(mainLoadingFalse());
        })
    }
}


export const getLiaisonApplicableProcessPlan_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "processPlanID":"",
        "projectID":"",
        "projectFileID":"",
        "processID":"",
        "applicabilityStatus":"",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":"",
        "iDisplayLength":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_LIAISON_APPLICABLE_PROCESS_PLAN, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch({
                    type : GET_LIAISON_APPLICABLE_PROCESS_PLAN,
                    payload : data
                })
            }
            else{
                dispatch({
                    type : GET_LIAISON_APPLICABLE_PROCESS_PLAN,
                    payload : []
                })
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch({
                type : GET_LIAISON_APPLICABLE_PROCESS_PLAN,
                payload : []
            })
            dispatch(mainLoadingFalse());
        })
    }
}

export const getLiaisonDocumentationTaskPlanExport_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID":"",
        "projectFileID":"",
        "processPlanID":"",
        "taskPlanStatus":"",
        "taskOwnerID":"",
        "categoryID":"",
        "documentType":"",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":"0",
        "iDisplayLength":-1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.LIASON_DOCUMENT_TASK_PLAN, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch({
                    type : GET_LIASON_DOCUMENT_TASK_PLAN_EXPORT,
                    payload : data
                })
            }
            else{
                dispatch({
                    type : GET_LIASON_DOCUMENT_TASK_PLAN_EXPORT,
                    payload : []
                })
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch({
                type : GET_LIASON_DOCUMENT_TASK_PLAN_EXPORT,
                payload : []
            })
            dispatch(mainLoadingFalse());
        })
    }
}

export const getDepartment_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "departmentID":"",
        "departmentStatus":"",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":"1",
        "iDisplayLength":"-1",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_DEPARTMENT, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getDepartmentSuccess(data))
            }
            else{
                dispatch(getDepartmentSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getDepartmentSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}
export const getDepartmentSuccess = (data) => {
    return {
        type : GET_DEPARTMENT,
        payload : data
    }
}

export const deleteProjectDocument_action = (data) => {
    let initialPayload = {
        "userID": LoginuserId,
        "documentID" : "",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.DELETE_PROJECT_DOCUMENT, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}
export const updateLiaisonDocumentTaskPlan_action = (data) => {
    let initialPayload = {
        "userID": LoginuserId,
        "liaisonTaskID" : "",
        "taskName":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_LIAISON_DOCUMENT_TASK_PLAN, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}


export const getLiaisonDocumentationTaskPlanApprovalDetail_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "documentTaskPlanID":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_LIAISON_DOCUMENT_TASK_PLAN_APPROVAL_DETAIL, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getLiaisonDocumentationTaskPlanApprovalDetailSuccess(data))
            }
            else{
                dispatch(getLiaisonDocumentationTaskPlanApprovalDetailSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getLiaisonDocumentationTaskPlanApprovalDetailSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}
export const getLiaisonDocumentationTaskPlanApprovalDetailSuccess = (data) => {
    return {
        type : GET_LIAISON_DOCUMENT_TASK_PLAN_APPROVAL_DETAIL,
        payload : data
    }
}
export const getLiaisonDocumentationTaskPlanDetail_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "documentTaskPlanID":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_LIAISON_DOCUMENT_TASK_PLAN_DETAIL, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getLiaisonDocumentationTaskPlanDetailSuccess(data))
            }
            else{
                dispatch(getLiaisonDocumentationTaskPlanDetailSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getLiaisonDocumentationTaskPlanDetailSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}
export const getLiaisonDocumentationTaskPlanDetailSuccess = (data) => {
    return {
        type : GET_LIAISON_DOCUMENT_TASK_PLAN_DETAIL,
        payload : data
    }
}
export const updateNotificationViewStatus_action = (data) => {
    let initialPayload = {
        "userID": LoginuserId,
        "notificationID" : "",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_NOTIFICATION_VIEW_STATUS, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(getMyWebNotification_action())
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}