import { GET_PROFITABLE_REPORTS, GET_LIASONPROCESSOWNER, GET_PROJECT_NAMES, GET_PENDING_TASK, 
    GET_TASK_DELAYS, GET_PROFITABLE_REPORT_COUNT,
    GET_LIAISON_PROJECT_NAMES,
    GET_DASHBOARD_DATA,
    GET_USER_DASHBOARD_DATA, GET_USER_DASHBOARD_TASK,GET_CIVIL_PROCESS_DATA,GET_ENQUIRY_SOURCE
 } from './types';

const initialState = {
    profitableReports : [],
    profitableReportCount : 0,
    LiasonProcessOwners : [],
    getProjectNames : [],
    getTaskDelays : [],
    getPendingTasks : [],
    getLisionProjectNames : [],
    DashboardData: [],
    myDashboardData : {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    myDashboardTask : {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    civilProcessProgressData : {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    enquirySourceList:{
        error: false,
        message: "",
        data: [],
    },
}

const Report_reducer = (state = initialState, { type, payload }) => {
    console.log(type, "payload", payload)
    switch(type)
    {
        case GET_PROFITABLE_REPORTS:
            return Object.assign({}, state, { profitableReports: payload });        
        case GET_PROFITABLE_REPORT_COUNT:
            return Object.assign({}, state, { profitableReportCount: payload });
        case GET_LIASONPROCESSOWNER:
            return Object.assign({}, state, { LiasonProcessOwners: payload });
        case GET_PROJECT_NAMES:
            return Object.assign({}, state, { getProjectNames: payload });
        case GET_LIAISON_PROJECT_NAMES:
            return Object.assign({}, state, { getLisionProjectNames: payload });
        case GET_TASK_DELAYS:
            return Object.assign({}, state, { getTaskDelays: payload });
        case GET_PENDING_TASK:
            return Object.assign({}, state, { getPendingTasks: payload });

        case GET_DASHBOARD_DATA:
            return Object.assign({}, state, { DashboardData: payload });


        case GET_USER_DASHBOARD_DATA:
            return Object.assign({}, state, { myDashboardData: payload });
        case GET_USER_DASHBOARD_TASK:
                return Object.assign({}, state, { myDashboardTask: payload });

        case GET_CIVIL_PROCESS_DATA:
            return Object.assign({}, state, { civilProcessProgressData: payload });

        case GET_ENQUIRY_SOURCE:
            return Object.assign({}, state, { enquirySourceList: payload });
           

        // case DISPLAY_MESSAGE:
        //     return Object.assign({}, state, { messageInfo: payload });
            
        // case DISPLAY_ERROR:
        //     return Object.assign({}, state, { error: payload.error });

        // case SUCCESS_STATUS:
        //     return Object.assign({}, state, { status: payload.status });
            
        // case OTP_SENT_SUCCESS:
        //     return Object.assign({}, state, { otp_sent: true });
        
        // case OTP_VALID_FAILS:
        //     return Object.assign({}, state, { valid_otp: false });

        // case OTP_VALID_SUCCESS:
        //         return Object.assign({}, state, { valid_otp: true });
            
                
        default:  
            return state;
    }
}

export default Report_reducer;