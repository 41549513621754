import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';
import store from './Redux/store';
import { HashRouter as Router, Routes, Navigate, Route } from 'react-router-dom';
import {createRoot} from 'react-dom/client';

const showInvalidLicenseError = () =>{}
const showNotFoundLicenseError = () =>{}
const showExpiredLicenseError = () =>{}
const showError = () =>{}

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <React.Fragment>
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>
</React.Fragment>
);

// ReactDOM.render(
//   <React.Fragment>
//     <Provider store={store}>
//       <Router>
//         <App />
//       </Router>
//     </Provider>
//   </React.Fragment>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
