import React, { useState } from 'react';
import ResponsiveAppBar from './AppBar'
import { styled, useTheme } from '@mui/material/styles';
import {Drawer as MuiDrawer, List, ListItem, ListItemText, Divider, ListItemIcon, Typography, ListItemButton, Collapse} from '@mui/material';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import './styles.scss'
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import IconButton from '@mui/material/IconButton';
import MainRouter from './MainRouter'
import {Link, NavLink } from 'react-router-dom'
import {dateFormat} from '../../utils/date';


const DashboardIcon = React.lazy(()=> import("@mui/icons-material/Home"))
const CivilIcon = React.lazy(()=> import("@mui/icons-material/Details"))
const LiaisonIcon = React.lazy(()=> import("@mui/icons-material/Details"))
const BDIcon = React.lazy(()=> import("@mui/icons-material/LocationCity"))

const IconObject = {
  Dashboard : DashboardIcon,
  Civil : CivilIcon,
  Liaison : LiaisonIcon,
  BD : BDIcon,
  Admin : BDIcon,
  Common : CivilIcon,
}

const returnIcon = (key) =>{
  if(IconObject.hasOwnProperty(key)){
    return IconObject[key]
  }else{
    return IconObject["Common"]
  }
}



const _reportDate = dateFormat(new Date(), "yyyy-MM-DD")
const SidebarMenu = [
  {
    path : '/',
    label : 'Dashboard',
    Icon : React.lazy(()=> import("@mui/icons-material/Home")),
    newTab : false,
    children : [
      {
        path : `/dashboard/civil`,
        label : 'Civil',
        newTab : true,
        children : []
      },
      {
        path : `/dashboard/sales`,
        //path : `/`,
        label : 'Sales',
        newTab : true,
        children : []
      },
      {
        path : `/dashboard/crm`,
        label : 'CRM',
        newTab : true,
        children : []
      },
      {
        path : `/dashboard/marketing`,
        label : 'Marketing',
        newTab : true,
        children : []
      },
      {
        path : `/dashboard/procurment`,
        label : 'Procurment',
        newTab : true,
        children : []
      },
    ]
  },
  {
    path : '/civil',
    label : 'Civil',
    Icon : React.lazy(()=> import("@mui/icons-material/Details")),
    children : [
      {
        path : `/civil/resource-machine-planning`,
        label : 'Machine Planning',
        newTab : true,
        children : []
      },

      {
        path : `/civil/resource-nmr-planning`,
        label : 'Manpower Planning',
        newTab : true,
        children : []
      },

      {
        path : `/civil/resource-machine-planning-details`,
        label : 'Machine Planning Details',
        newTab : true,
        children : []
      },
  
      {
        path : `/civil/resource-nmr-planning-details`,
        label : 'Manpower Planning Details',
        newTab : true,
        children : []
      },

      {
        path : `/civil/resource-planning`,
        label : 'Resource Planning',
        newTab : true,
        children : []
      },

      {
        path : `/civil/budget`,
        label : 'Project Budget',
        newTab : true,
        children : []
      },
      {
        path : `/civil/progress`,
        label : 'Project Progress',
        newTab : true,
        children : []
      },
    ]
  },
  {
    path : '/Reports',
    label : 'Liaison',
    Icon : React.lazy(()=> import("@mui/icons-material/Assessment")),
    children : [

      {
        path :  `/liaison-reports/reportDate=${_reportDate}`,
        label : 'Liaison Meeting',
        newTab : true,
        children : []
      },

      {
        path : `/nextHearing/reportDate=${_reportDate}`,
        label : 'Next Hearing',
        newTab : true,
        children : []
      },

      {
        path :  `/courtCase/reportDate=${_reportDate}`,
        label : 'Not updated Court Case',
        newTab : true,
        children : []
      },

      {
        path :  `/liaison_kns_report/reportDate=${_reportDate}`,
        label : 'Liaison Kns Report',
        newTab : true,
        children : []
      },
      {
        path :  `/liaison_shc_report/reportDate=${_reportDate}`,
        label : 'Liaison SHC Report',
        newTab : true,
        children : []
      },
      
      // {
      //   path :  `/liaison-fallowups-reports/reportDate=${_reportDate}`,
      //   label : 'Pending Liaison',
      //   newTab : true,
      //   children : []
      // },
      
      {
        path :  `/liaison_all_report/reportDate=${_reportDate}`,
        label : 'Liaison All Report',
        newTab : true,
        children : []
      },
      // {
      //   path :  `/liaison_own_report/reportDate=${_reportDate}`,
      //   label : 'Liaison Own Report',
      //   newTab : true,
      //   children : []
      // },
    ]
  },
  {
    path : '/',
    label : 'BD',
    Icon : React.lazy(()=> import("@mui/icons-material/LocationCity")),
    newTab : false,
    children : [
      {
        path : `/milestone`,
        label : 'Project Milestone',
        newTab : false,
        children : []
      },

      {
        path : `/business-development/plan`,
        label : 'Project Plan',
        newTab : false,
        children : []
      },
      {
        path : `/business-development/task-plan`,
        label : 'Project Task Plan',
        newTab : false,
        children : []
      },
      {
        path : `/business-development/approval-pending`,
        label : 'Project Task Approval',
        newTab : false,
        children : []
      },
      {
        path : `/business-development/survey-no-pending`,
        label : 'Survey No. Task Approval',
        newTab : false,
        children : []
      },
      {
        path : `/business-development/liason-process-plan`,
        label : 'Liaison Process Plan',
        newTab : false,
        children : []
      },
      // {
      //   path : `/business-development/liason-task-plan`,
      //   label : 'Liaison Task Plan',
      //   newTab : false,
      //   children : []
      // },
      {
        path : `/business-development/liason-documentation`,
        label : 'Liaison Documentation',
        newTab : false,
        children : []
      },
      {
        path : `/business-development/proposed`,
        label : 'Proposed Project',
        newTab : false,
        children : []
      },

      {
        path : `/business-development/project-file`,
        label : 'Project Sy No',
        newTab : false,
        children : []
      },
      {
        path : `/business-development/up-coming`,
        label : 'UpComing Project',
        newTab : false,
        children : []
      },
      {
        path : `/business-development/on-going`,
        label : 'OnGoing Project',
        newTab : false,
        children : []
      },
      {
        path : `/business-development/completed`,
        label : 'Completed Project',
        newTab : false,
        children : []
      },
      {
        path : `/business-development/dropped`,
        label : 'Dropped Project',
        newTab : false,
        children : []
      },
    ]
  },
  {
    path : '/authorization',
    label : 'Authorization',
    Icon : React.lazy(()=> import("@mui/icons-material/LocationCity")),
    newTab : false,
    children : []
  }
]

const menu = JSON.parse(localStorage.getItem('menu'))


const CollpseComponent = ({ item }) => {
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(!open);
  };
  const ReactIcon = returnIcon(item.menu_name);
  return <>
    <ListItemButton key={item.menu_id} onClick={handleClick} className='d-flex justify-content-between align-items-center'>
      <div className='d-flex justify-content-between'>
        <div className='d-flex'>
          <React.Suspense fallback={<div>Loading</div>}>
              <ReactIcon />
            </React.Suspense>
          <div className="navbar-text">{item.menu_name} </div>
        </div>
      </div>
      <div>{open ? <ExpandLess /> : <ExpandMore />}</div>
    </ListItemButton>
    <Collapse in={open} timeout="auto" unmountOnExit className='sub-item-collapse'>
      <List component="div" disablePadding>
        {item.menu_item.map(sub => {
          return <NavLink key={sub.id} to={sub.routing_path} target={sub?.newTab ? "_blank" : ""}  style={({ isActive }) => ({
            color: isActive ? '#fff' : '#545e6f',
            background: isActive ? '#7600dc' : '#f0f0f0',
          })}>
            <ListItemButton sx={{ pl: 0 }}>
              <div className='horizontal-line'></div>
              <div className="subtext">{sub.functionality_name}</div>
            </ListItemButton>
          </NavLink>
        })}
      </List>
    </Collapse>
  </>
}

const drawerWidth = 210;

const openedMixin = (theme) => ({
    width: drawerWidth,
    top:"auto",
    //background : "#fff !important",
    background:"#1d213e !important",
    color:"#fff !important",
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    top:"auto",
    // background : "#fff !important",
    background:"#1d213e !important",
    color:"#fff !important",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(7)} + 1px)`,
    },
  });
const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      width: drawerWidth,
      height : "90vh",
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    }),
  );

function Navbar(props) {
    const [isSidebar, setSidebar] = useState(true);
    const theme = useTheme();

    const ontoggleClick = () =>{
        setSidebar(!isSidebar)
    }

    const handleDrawerClose = () => {
        setSidebar(!isSidebar)
      };
    console.log('vvvmenu', menu);
    menu.map((_menu, index) => (
      console.log("nnnnn", _menu)
    ))
    return (
        <div className='navbar'>
            <div className='header'><ResponsiveAppBar ontoggleClick={ontoggleClick} isSidebar={isSidebar} handleDrawerClose={handleDrawerClose}/></div>
            <div className='main-body'>
                <div className={"sidebar"}> 
                    <Drawer variant="permanent" open={isSidebar}>
                        <List>
                        {menu.map((_menu, index) => (
                          <React.Fragment key={index}>
                          {
                            _menu.menu_item.length > 0 ?
                            <CollpseComponent item={_menu} />
                            :
                            <Link key={_menu.menu_name} to={_menu.routing_path} >
                              <ListItem >
                                {/* <ListItemIcon> */}
                                    <React.Suspense fallback={<div>Loading</div>}>
                                      <_menu.Icon  className="navar-icons" />
                                    </React.Suspense>
                                  {/* </ListItemIcon> */}
                                <div className="navbar-text">{_menu.menu_name}</div>
                              </ListItem>
                            </Link>
                          }
                          </React.Fragment>
                           
                        ))}
                        </List>
                        <Divider />
                        {/* <List>
                        {['All mail', 'Trash', 'Spam'].map((text, index) => (
                            <ListItem button key={text}>
                            <ListItemIcon>
                                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                            </ListItemIcon>
                            <ListItemText primary={text} />
                            </ListItem>
                        ))}
                        </List> */}
                    </Drawer>
                </div>
                <div className='content'>
                    <div>
                    {/* <Employee  type="list" /> */}
                    {/* <MainRouter /> */}
                    {props.children}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Navbar;