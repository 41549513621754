import axios from 'axios';
import { getCivilResourcePlanningSuccess } from '../Redux/Civil/action';


// export const BaseUrl = 'https://knsonline.in/dev/RestAPI_V1/';
// export const ErpBaseUrl = 'http://192.168.0.200/dev/RestAPI_V1/';
// export const DocumentUrl = 'https://knsonline.in/dev/';

export const BaseUrl = 'https://knsonline.in/test/RestAPI_V1/';
export const ErpBaseUrl = 'http://192.168.0.200/test/RestAPI_V1/';
export const DocumentUrl = 'https://knsonline.in/test/';

// export const BaseUrl = 'https://knsonline.in/uat/RestAPI_V1/';
// export const ErpBaseUrl = 'http://192.168.0.200/uat/RestAPI_V1/';
// export const DocumentUrl = 'https://knsonline.in/uat/';

// export const BaseUrl = 'https://knsonline.in/RestAPI_V1/';
// export const ErpBaseUrl = 'http://192.168.0.200/RestAPI_V1/';
// export const DocumentUrl = 'https://knsonline.in/';

export const ApiPaths = {
    LOGIN: 'userLogin',
    GETDASHBOARDDATA: 'getDashboardData',
    PROFITABLE_REPORTS : 'getProcessPlan',
    LIASONPROCESSOWNER : 'getLiasonProcessOwner1',
    GETPROJECTS : 'getProjects',
    GETLIAISONPROJECTS : 'getLiaisonProjects',
    GETTASKDELAY : 'getTaskDelayReason',
    GETPENDINGTASK : 'getPendingTasks',
    GETCOURTCASEPENDINGREM : 'getCourtCasePendingReminder',
    GETCOURTCASEREM : 'getCourtCaseReminder',
    PENDINGLIAISONREPORT : 'getLiaisonReport',
    PENDINGLIAISONREPORT_1 : 'getLiaisonReport1',
    CIVILRESOURCEPLANNING : 'getCivilResourcePlanning',
    CIVILRESOURCETYPEPLANNING: 'getCivilTaskOveralResourcePlanning',
    GETCIVILPROJECTS: "getCivilProjectPlan",
    GETCIVILPROCESS: "getCivilProcessPlan",
    GETCIVILTASKS: "getCivilTaskPlan",
    GETCIVILRESOURCE: "getCivilProjectResource",
    GETCIVILBUDGET: "getCivilTaskBudget",
    GETCIVILPROGRESS: "getCivilProjectProgress",
    GET_MY_DASHBOARD_DATA:'getMyDashBoardData',
    GET_MY_DASHBOARD_TASK:'getMyDashBoardTask',
    GET_CIVIL_PROCESS_DATA: "getCivilProcessData",
    GET_ENQUIRY_SOURCE:"getEnquirySource"

};

export const ErpApi = {
    PROJECT_AUTHORITY : "/getProjectAuthority",
    BD_USER : "/getUser",
    PROJECT_PHASE : "/getProjectPhase",
    DISTRICTS : "/getDistrict",
    TALUKS : "/getTaluk",
    HOBLI : "/getHobli",
    PROJECT_BROKERS : "/getLandBroker",
    POST_PROJECT: "/createProject",
    POST_PROJECT_FILE: "/createProjectFile",
    PROJECT_LIST: "/getProject",
    UPCOMING_PROJECT_LIST: "/getProjectUpcoming",
    VILLAGE_LIST: "/getVillage",
    PROJECT_TASK_PLAN: "/getProjectTaskPlan",
    PROJECT_PLAN: "/getProjectPlan",
    PROJECT_OWNERS_NAMES: "getProjectTaskPlanFilterOwner",
    PROJECT_NAMES: "getProjectTaskPlanFilterProject",
    PROJECT_NAMES1: "getProjectTaskPlanFilterProject1",
    PROJECT_TASK_STATUS: "getProjectTaskStatus",
    UPDATE_PROJECT_TASK_REMARK: "updateProjectTaskRemark",
    UPDATE_PROJECT_TASK_STATUS: "uploadProjectTaskStatus",
    //UPDATE_PROJECT_TASK_APROVAL_STATUS: "uploadProjectTaskApprovalStatus",

    UPDATE_PROJECT_TASK_APROVAL_STATUS: "updateProjectTaskPlanApprovalStatus",

    // UPDATE_PROJECT_UPLOAD_DOCUMENT: "/uploadProjectTaskDocument",
    UPDATE_PROJECT_UPLOAD_DOCUMENTS: "/uploadProjectTaskDocuments", // For Multiple Document Upload

    UPDATE_PROJECT_TASK_COMPLETE: "/updateProjectTaskComplete",
    // UPDATE_PROJECT_SUB_TASK_COMPLETE: "/updateProjectTaskPlanComplete",
    UPDATE_PROJECT_SUB_TASK_COMPLETE_STATUS: "/updateProjectTaskPlanCompleteStatus", // with Multiple Docs


    PROJECT_DELAY_REASON: "getProjectDelayReason",
    PROJECT_DOCUMENT_TYPES: "getDocumentType",
    PROJECT_TASK_PLAN_DETAILS: "getProjectTaskPlanDetail",
    PROJECT_PROJECT_FILE: "getProjectFile",
    PROJECT_LAND_OWNER: "getLandOwner",
    //LIASON_PROCESS_PLAN: "getLiaisonProcessPlan",
    LIASON_PROCESS_PLAN: "getLiaisonProcessPlanPending",
    LIASON_TASK_PLAN: "getLiaisonTaskPlan",
    PROJECT_BD_OWNER: "getProjectBdOwner",
    PROJECT_LEGAL_OWNER: "getProjectLegalOwner",
    LIASON_DOCUMENT_TASK_PLAN: "getLiaisonDocumentationTaskPlan",
    PROJECT_SURVEY_NO_TASK_APPROVAL: "getProjectTaskPlanApproval",
    PROJECT_SURVEY_NO_TASK_APPROVAL_DETAILS: "getProjectTaskPlanApprovalDetail",
    UPDATE_LIASON_PROCESS_APPLICATION_STATUS: "updateLiaisonProcessApplicabilityStatus",
    UPDATE_LIASON_TASK_PLAN_COMPLETE: "updateLiaisonTaskPlanComplete",
    MY_WEB_NOTIFICATION: "getMyWebNotification",
    AUTHORIZATION: "getFunctionalityPermission",
    MENU_PERMISSION: "getMenu",
    FUNCTIONALITY: "getFunctionality",
    UPDATE_USER_FUNCTIONALITY_PERMISSION: "/updateUserFunctionalityPermission",

    UPDATE_FIREBASE_KEY : "updateFirebaseKeyWeb",
    PROJECT_PLAN_FILE_PLAN: "/getProjectPlanFile",
    LIAISON_PROCESS_CATEGORY: "/getLiaisonProcessCategory",
    FILE_LIAISON_PROCESS_PLAN: "/getFileLiaisonProcessPlan",
    UPDATE_LIAISON_PROCESS_PLAN_APPLICABILITY: "/updateLiaisonProcessPlanApplicabilityStatus",
    GET_PROJECT_TASK_PLAN_MANDOTORY_DOCUMENT: "/getProjectTaskPlanMandatoryDocument",
    UPLOAD_PROJECT_TASK_PLAN_MANDOTORY_DOCUMENT: "/uploadProjectTaskPlanMandatoryDocument",


    //New
    GET_SURVEY_NO : 'getProjectPlanFile',
    GET_SURVEY_NO_DETAILS : 'getProjectFileDetail',
    CREATE_FILE_ACQUISITION_INFO: 'createFileAcquisitionInfo',
    GET_FILE_ACQUISITION_INFO: 'getFileAcquisitionInfo',
    GET_FILE_ACQUISITION_MODE: 'getFileAcquisitionMode',
    GET_FILE_ACQUISITION_INFO_DETAIL: 'getFileAcquisitionInfoDetail',
    UPDATE_FILE_ACQUISITION_INFO_STATUS:'updateFileAcquisitionInfoStatus',
    GET_PROJECT_WITH_EXTENT:'getProjectWithExtent',
    GET_FEASIBILITY_ITEM:'getProjectFeasibilityItem',
    UPDATE_FEASIBILITY_REPORT:'updateFeasibilityReport',
    GET_FEASIBILITY_REPORT:'getFeasibilityReport',
    GET_FEASIBILITY_REPORT_DETAIL:'getFeasibilityReportDetail',
    CREATE_LAND_OWNER:'createLandOwner',
    CREATE_LAND_OWNER_ACCOUNT:'createLandOwnerAccount',
    GET_LAND_OWNER_DETAIL:'getLandOwnerDetail',
    GET_LAND_BROKER:'getProjectBdLandBroker',
    CREATE_LAND_BROKER:'createLandBroker',
    GET_LAND_BROKER_DETAIL:'getProjectBdLandBrokerDetail',
    CREATE_LAND_BROKER_ACCOUNT:'createLandBrokerAccount',

    FEASIBILITY_REPORT_SEND_FOR_APPROVAL:'feasibilityReportSendForApproval',
    APPROVE_FEASIBILITY_REPORT_TASK_PLAN:'approveFeasibilityReportTaskPlan',
    GET_LAND_OWNER_VILLAGE :'getLandOwnerVillage',

    GET_LIAISON_DOCUMENT_CATEGORY:'getLiaisonDocumentCategory',
    UPDATE_LIAISON_DOCUMENT_CATEGORY:'updateLiaisonDocumentCategory',
    UPDATE_LIAISON_TASK_PLAN_OWNER:'updateLiaisonTaskPlanOwner',
    UPDATE_LIAISON_TASK_PLAN_STATUS:'updateLiaisonTaskPlanStatus',
    UPDATE_BD_PROJECT_TASK_PLAN_OWNER: 'updateBdProjectTaskPlanOwner',
    UPDATE_LIAISON_PROCESS_PLAN_OWNER: 'updateLiaisonProcessPlanOwner',
    CREATE_LIAISON_DOC_CHECKLIST_PLAN:'createLiaisonDocumentCheckListPlan',
    IMPORT_LIAISON_DOC_CHECKLIST_PLAN:'importLiaisonDocumentCheckListPlan',
    GET_PROJECT_FUND_SOURCE:'getProjectFundSource',
    GET_PROJECT_FUND_REQUIREMENT:'getProjectFundRequirement',
    UPDATE_PROJECT_FUND_REQUIREMENT:'updateProjectFundRequirement',
    GET_PROJECT_FUND:'getProjectFund',
    GET_PROJECT_FUND_DETAIL:'getProjectFundDetail',

    GET_PROJECT_FILE_PAYMENT_DETAIL:'getProjectFilePaymentDetail',
    ADD_LAND_OWNER_PAYMENT:'addLandOwnerPayment',
    GET_BD_PAYMENT:'getBdPayment',
    _GET_BD_PAYMENT_DETAIL:'getBdPaymentDetail',
    UPDATE_BD_PAYMENT_STATUS:'updateBdPaymentStatus',
    GET_BD_PAYMENT_MILESTONE:'getBdPaymentMilestone',
    GET_PROJECT_BROKER:'getProjectBroker',
    GET_PROJECT_BROKER_DETAIL:'getProjectBrokerDetail',
    UPDATE_PROJECT_BROKER_STATUS:'updateProjectBrokerStatus',
    ADD_PROJECT_BROKER_CHARGE:'addProjectBrokerCharge',
    UPDATE_PROJECT_BROKER_CHARGE_STATUS:'updateProjectBrokerChargeStatus',
    ADD_PROJECT_BROKER_PAYMENT:'addProjectBrokerPayment',
    ADD_PROJECT_BROKER_PAYMENT_INFO:'getProjectBrokerPaymentInfo',
    GET_PROJECT_LAND_OWNER:'getProjectLandOwner',
    GET_PROJECT_OWNER_SURVEY_NUMBER:'getProjectOwnerSurveyNo',
    GET_PROJECT_FILE_ADVANCE_PAYMENT_DETAIL:'getProjectFileAdvancePaymentDetail',

    GET_LIAISON_PROCESS_PLAN_FILE:'getLiaisonProcessPlanFile',
    ADD_LIAISON_PROCESS_PLAN_START:'liaisonProcessPlanStart',
    GET_LIAISON_PROCESS_PLAN_APPLICATION:'getLiaisonProcessPlanApplication',
    UPDATE_LIAISON_PROCESS_PLAN_APPLICATION_OWNER: 'updateLiaisonProcessPlanApplicationOwner',
    GET_LIAISON_PROCESS_PLAN_APPLICATION_FILE_DETAIL:'getLiaisonProcessPlanApplicationFileDetail',
    ADD_LIAISON_PROCESS_PLAN_APPLICATION_SPLIT:'liaisonProcessPlanApplicationSplit',
    UPDATE_LIAISON_PROCESS_PLAN_APPLICATION_STATUS:'updateLiaisonProcessPlanApplicationStatus',
    GET_PROJECTC_MILESTONE:'getProjectMilestone',
    GET_BD_REGISTRATION_ITEM:'getBdRegistrationItem',
    GET_SUBREGISTER_OFFICE:'getSubRegisterOffice',
    ADD_LAND_GOVT_PAYMENT:'addLandRegistrationPayment',
    UPDATE_SURVEY_NUMBER_CREATION:'updateSurveyNumberCreation',

    UPLOAD_PROJECT_FILE_DOCUMENT:'uploadProjectFileDocument',
    GET_PROJECT_FILE_DOCUMENT:'getProjectFileDocument',
    UPDATE_PROJECT_FILE:'updateProjectFile',
    UPDATE_PROJECT_FILE_STATUS:'updateProjectFileStatus',
    GET_DEPENDENT_RELATIONSHIP:'getDependentRelationship',
    UPDATE_LAND_OWNER:'updateLandOwner',
    UPDATE_LAND_OWNER_STATUS:'updateLandOwnerStatus',
    UPDATE_LAND_BROKER:'updateLandBroker',
    UPDATE_LAND_BROKER_STATUS:'updateLandBrokerStatus',
    _GET_BD_PAYMENT_ADVANCE_DETAIL:'getBdPaymentAdvanceDetail',
    BD_LAND_PAYMENT_SETTLE:'bdLandPaymentSettle',
    GET_ACCOUNT_PAYMENT:'getAccountPayment',

    GET_COMPANY:'getCompany',
    GET_PAYMENT_CATEGORY:'getPaymentCategory',
    UPDATE_PAYMENT_COMPANY:'updatePaymentCompany',
    GET_ACCOUNT_PAYMENT_DETAIL:'getAccountPaymentDetail',
    GET_COMPANY_BANK_ACCOUNT:'getCompanyBankAccount',
    GET_PAYMENT_MODE_CATEGORY:'getPaymentModeCategory',
    GET_PAYMENT_MODE:'getPaymentMode',
    PAY_ACCOUNT_PAYMENT:'payAccountPayment',
    GET_ACCOUNT_PAYMENT_PAID:'getAccountPaymentPaid',
    GET_ACCOUNT_PAYMENT_PAID_DETAIL:'getAccountPaymentPaidDetail',

    GET_PROJECT_DETAIL:'getProjectDetail',
    GET_PROJECT_DROP_REASON:'getProjectDropReason',

    UPDATE_PROJECT_STATUS:'updateProjectStatus',
    UPDATE_PROJECT_BD_OWNER:'updateProjectBdOwner',
    UPDATE_PROJECT_LEGAL_OWNER:'updateProjectLegalOwner',
    UPDATE_PROJECT_DETAIL:'updateProjectDetail',

    
    COMPANY_PROJECT_MAP:'getCompanyProjectMap',
    ADD_PROJECT_COMPANY_MAP:"addProjectCompanyMap",
    UPDATE_PROJECT_COMPANY_MAP:"updateProjectCompanyMap",
    PROJECT_INFO:"getProject",
    
    GET_DROPPED_PROJECT_LIST: "/getDroppedProject",
    GET_LAND_OWNER_ACCOUNT_DETAIL:"getLandOwnerAccountDetail",
    UPDATE_LAND_OWNER_ACCOUNT_DETAIL:"updateLandOwnerAccount",
    
    GET_LAND_BROKER_ACCOUNT_DETAIL:"getLandBrokerAccountDetail",
    UPDATE_LAND_BROKER_ACCOUNT_DETAIL:"updateLandBrokerAccount",
    GET_LIAISON_DOCUMENT_CATEGORY:"getLiaisonDocumentCategory",


    GET_LIAISON_PROCESS_PLAN_FILTER_OWNER: "getLiaisonProcessPlanFilterOwner",  
    GET_LIAISON_PROCESS_PLAN_APPLICATION_FILTER_OWNER: "getLiaisonProcessPlanApplicationFilterOwner",
    GET_LIAISON_DOC_TASK_PLAN_FILTER_OWNER: "getLiaisonDocumentationTaskPlanFilterOwner",

    USER_PROFILE: "getUserProfile",
    UPDATE_EMPLOYEE_PROFILE: "updateEmployeeProfile",
    UPDATE_USER_PROFILE_IMAGE:'updateUserProfileImage',
    GET_BIRTHDAY:'getEmployeeBirthday',
    UPDATE_USER_GREETING_IMAGE:'updateUserGreetingImage',
    GET_WORK_ANNIVERSARY:'getEmployeeWorkAnniversary',
    UPDATE_USER_ANNIVERSARY_GREETING_IMAGE:'updateUserAnniversaryGreetingImage',
    GET_PROJECT_FILTER:'getProjectFilter',
    GET_PROJECT_UPCOMING_FILTER:'getProjectUpcomingFilter',
    GET_CIVIL_PROJECT_FILTER:'getCivilProjectFilter',
    GET_LIASON_DOCUMENT_TASK_PLAN_APPROVAL: "getLiaisonDocumentationTaskPlanApproval",
    GET_PROJECT_FILE_FILTER:"getProjectFileFilter",
    GET_LIAISON_PROCESS_FILTER:"getLiaisonProcessFilter",
    GET_APF_PANEL:"getApfPanel",
    GET_APF_PENDING_PROJECT_FILE:"getApfPendingProjectFile",
    CREATE_APF_TASK_PLAN:"createApfTaskPlan",
    GET_APF_TASK_PLAN:"getApfTaskPlan",
    GET_APF_TASK_PLAN_DETAIL:"getApfTaskPlanDetail",
    UPDATE_APF_TASK_REMARK:"updateApfTaskRemark",
    UPDATE_APF_TASK_PLAN_OWNER:"updateApfTaskPlanOwner",
    UPLOAD_APF_TASK_DOCUMENT:"uploadApfTaskDocument",
    UPDATE_APF_TASK_PLAN_START:"updateApfTaskPlanStart",
    UPDATE_APF_TASK_PLAN_COMPLETE:"updateApfTaskPlanComplete",

    GET_APF_TASK_PLAN_PROJECT_FILTER:"getApfTaskPlanProjectFilter",
    // GET_APF_TASK_PLAN_OWNER_FILTER:"getApfTaskPlanOwnerFilter",
    GET_APF_TASK_PLAN_PANEL_FILTER:"getApfTaskPlanPanelFilter",
    GET_APF_TASK_PLAN_PROJECT_FILE_FILTER:"getApfTaskPlanProjectFileFilter",
    GET_APF_TASK_PLAN_TASK_FILTER:"getApfTaskPlanTaskFilter",
    GET_APF_TASK_FILTER:"getApfTask",
    GET_APF_TASK_PLAN_QUERY:"getApfTaskPlanQuery",
    CREATE_APF_TASK_PLAN_QUERY:"createApfTaskPlanQuery",
    UPLOAD_APF_TASK_QUERY_DOCUMENT:"uploadApfTaskQueryDocument",
    GET_APF_TASK_PLAN_QUERY_DETAIL:"getApfTaskPlanQueryDetail",
    UPDATE_APF_TASK_PLAN_QUERY_OWNER:"updateApfTaskPlanQueryOwner",
    UPDATE_APF_TASK_PLAN_QUERY_STATUS:"updateApfTaskPlanQueryStatus",
    GET_LIAISON_DOCUMENTATION_REPORT:"getLiaisonDocumentationReport",
    GET_BD_LAND_DISPUTE_TYPE:"getBdLandDisputeType",
    CREATE_BD_LAND_DISPUTE:"createBdLandDispute",
    GET_BD_LAND_DISPUTE:"getBdLandDispute",
    UPLOAD_BD_LAND_DISPUTE_DOCUMENT:"uploadBdLandDisputDocument",
    GET_BD_LAND_DISPUTE_DETAIL:"getBdLandDisputeDetail",
    UPDATE_LAND_DISPUTE_OWNER:"updateBdLandDisputOwner",
    UPDATE_LAND_DISPUTE_REMARK:"updateBdLandDisputRemark",
    UPDATE_BD_LAND_DISPUTE_STATUS:"updateBdLandDisputStatus",
    GET_LIAISON_DOCUMENTATION_REPORT_PROJECT_FILTER:"getLiaisonDocumentationReportProjectFilter",
    GET_LIAISON_DOCUMENTATION_REPORT_PROJECT_FILE_FILTER:"getLiaisonDocumentationReportProjectFileFilter",
    GET_LIAISON_DOCUMENTATION_REPORT_CATEGORY_FILTER:"getLiaisonDocumentationReportCategoryFilter",
    GET_LIAISON_DOCUMENTATION_REPORT_OWNER_FILTER:"getLiaisonDocumentationReportOwnerFilter",
    GET_COURT_CASE_TYPE:"getCourtCaseType",
    GET_COURT_CASE_STATUS :"getCourtCaseStatus",
    GET_COURT_CASE_JURISDICTION:"getCourtCaseJurisdiction",
    GET_DIRECTOR:"getDirector",
    CREATE_COURT_CASE:"createCourtCase",
    GET_COURT_CASE:"getCourtCase",
    UPLOAD_COURT_CASE_DOCUMENT:"uploadCourtCaseDocument",
    UPDATE_COURT_CASE_OWNER:"updateCourtCaseOwner",
    UPDATE_COURT_CASE_REMARK:"updateCourtCaseRemark",
    UPDATE_COURT_CASE_STATUS:"updateCourtCaseStatus",
    GET_COURT_CASE_DETAIL:"getCourtCaseDetail",
    UPDATE_COURT_CASE:"updateCourtCase",
    UPDATE_BD_PROJECT_TASK_PLAN_START:"updateBdProjectTaskPlanStart",
    CREATE_TRAINING_CONTENT:"createTrainingContent",
    GET_TRAINING_CONTENT:"getTrainingContent",
    UPDATE_TRAINING_CONTENT:"updateTrainingContent",
    DELETE_PROJECT_FILE_DOCUMENT:"deleteProjectFileDocument",
    GET_BD_PROJECT_TASK_REJECTION_REASON:"getBdProjectTaskRejectionReason",
    GET_BD_AGREEMENT_TYPE:"getBdAgreementType",
    GET_BD_PROJECT_REPORT:"getBdProjectReport",
    GET_BD_PROJECT_TASK_REPORT:"getBdProjectTaskReport",
    CREATE_PROJECT_PROPOSED_NAME:"createProjectProposedName",
    GET_PROJECT_PROPOSED_NAME:"getProjectProposedName",
    DELETE_PROJECT_PROPOSED_NAME:"deleteProjectProposedName",
    UPDATE_PROJECT_PROPOSED_NAME_STATUS:"updateProjectProposedNameStatus",
    DELETE_TRANING_CONTENT:"deleteTrainingContent",
    GET_TRAINING:"getMyTrainingContent",
    // -------------------------------
    GET_LIAISON_PROCESS_PLAN_PROJECT:"getLiaisonProcessPlanProject",
    GET_LIAISON_PROCESS_PLAN_PROCESS:"getLiaisonProcessPlanProcess",
    CREATE_LIAISON_PAYMENT:"createLiaisonPayment",
    GET_LIAISON_PAYMENT_LIST:"getLiaisonPayment",
    GET_LIAISON_PAYMENT_DETAIL:"getLiaisonPaymentDetail",
    DELETE_LIAISON_PAYMENT:"deleteLiaisonPayment",
    GET_LIAISON_PAYMENT_SURVEY_NUMBER:"getLiaisonPaymentSurveyNumber",
    GET_LIAISON_PAYMENT_TASK_PLAN:"getLiaisonPaymentTaskPlan",
    ADD_LIAISON_PAYMENT_ITEM:"addLiaisonPaymentItem",
    DELETE_LIAISON_PAYMENT_ITEM:"deleteLiaisonPaymentItem",
    UPDATE_LIAISON_PAYMENT_STATUS:"updateLiaisonPaymentStatus",
    UPDATE_LIAISON_PAYMENT_ISSUE:"updateLiaisonPaymentIssue",
    UPDATE_LIAISON_PAYMENT_ITEM_ACTUAL_AMOUNT:"updateLiaisonPaymentItemActualAmount",
    UPDATE_LIAISON_PAYMENT_SETTLEMENT_APPROVAL:"updateLiaisonPaymentSettlementApproval",
    UPDATE_LIAISON_PAYMENT_SETTLEMENT_SETTLE:"updateLiaisonPaymentSettlementSettle",
    GET_FUNCTIONALITY_PERMISSION_DETAIL:"getFunctionalityPermissionDetail",
    GET_LIAISON_PAYMENT_FILTER_PROJECT:"getLiaisonPaymentFilterProject",
    GET_LIAISON_PAYMENT_FILTER_REQUESTED_BY:"getLiaisonPaymentFilterRequestedBy",
    GET_PROJECT_TASK_PLAN_FILTER_TASK_OWNER:"getProjectTaskPlanFilterTaskOwner",
    GET_PROJECT_TASK_PLAN_FILTER_TASK:"getProjectTaskPlanFilterTask",
    UPDATE_LIAISON_TASK_PLAN_START:"updateLiaisonTaskPlanStart",
    UPDATE_LIAISON_TASK_PLAN_OWNERS:"updateLiaisonTaskPlanOwners",
    GET_LIAISON_DOCUMENT_TASK_PLAN_PENDING:"getLiaisonDocumentationTaskPlanPending",
    GET_LIAISON_APPLICABLE_PROCESS_PLAN:"getLiaisonApplicableProcessPlan",
    GET_DEPARTMENT:"getDepartment",
    DELETE_PROJECT_DOCUMENT:"deleteProjectDocument",
    UPDATE_LIAISON_DOCUMENT_TASK_PLAN:"updateLiaisonDocumentTaskPlan",
    GET_LIAISON_DOCUMENT_TASK_PLAN_APPROVAL_DETAIL:"getLiaisonDocumentationTaskPlanApprovalDetail",
    GET_LIAISON_DOCUMENT_TASK_PLAN_DETAIL:"getLiaisonDocumentationTaskPlanDetail",
    UPDATE_NOTIFICATION_VIEW_STATUS:"updateNotificationViewStatus",
    
    

}

export const ApiService = axios.create({
    baseURL : BaseUrl + 'v2/',
    headers : {
        'Accept' : 'application/json',
        'Content-Type' : 'application/x-www-form-urlencoded',
    }
});

export const ErpApiService = axios.create({
    baseURL : BaseUrl + 'v2/',
    headers : {
        'Accept' : 'application/json',
        'Content-Type' : 'application/x-www-form-urlencoded',
    }
});

export const FileUploadService = axios.create({
    baseURL : BaseUrl + 'v2/',
    headers : {
        // 'Accept' : 'application/json',
        // 'Content-Type' : 'application/x-www-form-urlencoded',
    }
});


export const SetAuthToken = (token) => {
    if(token)
    {
        ApiService.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }
    else
    {
        delete ApiService.defaults.headers.common['Authorization'];
    }
}

export const ApiStatus = {
    OTP_SENT: 'OTP sent',
}

