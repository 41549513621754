import { GET_PROJECT_AUTHORITY, GET_BD_USER, GET_PROJECT_PHASE, GET_AUTHORIZATION,GET_MENU_PERMISSION, GET_USER_PERMISSION,
    GET_DISTRICT, GET_TALUK,GET_HOBLI, GET_PROJECT_BROKERS,GET_UPCOMING_PROJECT_LIST,GET_FUNCTIONALITY, 
    GET_VILLAGE_LIST, GET_PROJECT_OWNERS_NAMES,GET_PROJECT_NAMES, GET_MY_WEB_NOTIFICATION,
    GET_PROJECT_TASK_STATUS, GET_PROJECT_DELAY_REASON, GET_PROJECT_SURVEY_NO_TASK_APPROVAL_DETAILS,
    GET_PROJECT_TASK_PLAN_DETAILS, GET_PROJECT_PLAN,GET_PROJECT_FILE_LIST,GET_PROJECT_SURVEY_NO_TASK_APPROVAL,
    GET_LAND_OWNER_NAMES, GET_LIASON_PROCESS_PLAN, GET_LIASON_TASK_PLAN, GET_LIASON_DOCUMENT_TASK_PLAN,
     GET_PROJECT_LIST, GET_PROJECT_FLOW_LIST, GET_DOCUMENT_TYPES, GET_PROJECT_BD_OWNER, GET_PROJECT_LEGAL_OWNER, GET_PROJECT_PLAN_FILE_LIST, GET_LIAISON_CATEGORY_LIST, GET_LIAISON_PROCESS_LIST, GET_PROJECT_TASK_PLAN_MANDOTORY_DOCUMENT,
     GET_LAND_OWNER_LIST,GET_SURVEY_NO_LIST,GET_SURVEY_DETAIL_LIST,
    GET_FILE_ACQUISITION_INFO_LIST,GET_ACQUISITION_MODE_LIST,GET_ACQUISITION_INFO_DETAIL,
GET_LIAISON_DOCUMENT_CATEGORY_LIST,
    GET_PROJECT_LIST_WITH_EXTENT,GET_FEASIBILITY_ITEM_LIST,GET_FEASIBILITY_REPORT_LIST,GET_FEASIBILITY_REPORT_DETAIL,GET_LAND_OWNER_DETAIL,GET_LAND_BROKER_LIST,GET_LAND_BROKER_DETAIL, GET_LAND_OWNNER_VILLAGE_LIST, 
    GET_PROJECT_FUND_SOURCE_LIST,GET_PROJECT_FUND_REQUIREMENT_DETAIL,GET_PROJECT_FUND_LIST,GET_PROJECT_FUND_DETAIL , 
    GET_PROJECT_FILE_PAYEMENT_DETAIL,
    GET_BD_PAYMENT_LIST,GET_BD_PAYMENT_DETAIL,GET_BD_PAYMENT_MILESTONE,
    GET_PROJECT_BROKER_LIST,GET_PROJECT_BROKER_DETAIL,GET_PROJECT_BROKER_PAYMENT_INFO,
    GET_PROJECT_LAND_OWNER,GET_PROJECT_OWNER_SURVEY_NUMBER_LIST,GET_PROJECT_FILE_ADVANCE_PAYMENT_DETAIL,
    GET_LIAISON_PROCESS_PLAN_FILE, GET_LIASON_PROCESS_PLAN_APPLICATION,GET_LIAISON_PROCESS_PLAN_APPLICATION_FILE_DETAIL, GET_PROJECTC_MILESTONE, GET_BD_REGISTRATION_ITEM, GET_SUBREGISTER_OFFICE, GET_PROJECT_FILE_DOCUMENT, GET_DEPENDENT_RELATIONSHIP, GET_BD_PAYMENT_ADVANCE_DETAIL,
    GET_PROJECT_DETAIL, GET_PROJECT_DROP_REASON_LIST ,GET_COMPANY_PROJECT_MAP,GET_PROJECT,GET_COMPANY,GET_DROPPED_PROJECT_LIST
    ,GET_INSERTED_LAND_OWNER_DATA,GET_INSERTED_LAND_BROKER_DATA,GET_LAND_OWNER_ACCOUNT_DETAIL,
    GET_LAND_BROKER_ACCOUNT_DETAIL,GET_LIAISON_PROCESS_PLAN_FILTER_OWNER_LIST,
    GET_LIAISON_PROCESS_PLAN_APPLICATION_FILTER_OWNER_LIST,GET_LIAISON_DOC_TASK_PLAN_FILTER_OWNER_LIST,
    GET_USER_PROFILE,UPDATE_EMPLOYEE_PROFILE,GET_BIRTHDAY,GET_EMPLOYEE_WORK_ANNIVERSARY,
    GET_PROJECT_LIST_FILTER,GET_PROJECT_UPCOMING_FILTER,GET_CIVIL_PROJECT_FILTER,GET_LIASON_DOCUMENT_TASK_PLAN_APPROVAL,GET_PROJECT_FILE_FILTER,GET_LIAISON_PROCESS_FILTER,GET_APF_PANEL,GET_APF_PENDING_PROJECT_FILE,GET_APF_TASK_PLAN,GET_APF_TASK_PLAN_DETAIL,
    GET_APF_TASK_PLAN_PROJECT_FILTER,GET_APF_TASK_PLAN_PANEL_FILTER,GET_APF_TASK_PLAN_PROJECT_FILE_FILTER,GET_APF_TASK_FILTER,
    GET_APF_TASK_PLAN_QUERY,GET_APF_TASK_PLAN_QUERY_DETAIL,GET_LIAISON_DOCUMENTATION_REPORT,GET_BD_LAND_DISPUTE_TYPE,
    GET_BD_LAND_DISPUTE_LIST,GET_BD_LAND_DISPUTE_DETAIL,GET_LIAISON_DOCUMENTATION_REPORT_PROJECT_FILTER,GET_LIAISON_DOCUMENTATION_REPORT_PROJECT_FILE_FILTER,
    GET_LIAISON_DOCUMENTATION_REPORT_OWNER_FILTER,GET_LIAISON_DOCUMENTATION_REPORT_CATEGORY_FILTER,GET_COURT_CASE_TYPE,GET_COURT_CASE_STATUS,
    GET_COURT_CASE_JURISDICTION,GET_DIRECTOR,GET_COURT_CASE,GET_COURT_CASE_DETAIL,GET_TRAINING_CONTENT,GET_BD_PROJECT_TASK_REJECTION_REASON,
    GET_BD_AGREEMENT_TYPE,GET_BD_PROJECT_REPORT,
    GET_BD_PROJECT_TASK_REPORT,GET_PROJECT_PROPOSED_NAME,
    GET_TRAINING,GET_APF_TASK_PLAN_TASK_FILTER,GET_LIAISON_PROCESS_PLAN_PROJECT,
    GET_LIAISON_PROCESS_PLAN_PROCESS,GET_LIAISON_PAYMENT_LIST,
    GET_LIAISON_PAYMENT_DETAIL,GET_LIAISON_PAYMENT_SURVEY_NUMBER,GET_LIAISON_PAYMENT_TASK_PLAN,
    GET_FUNCTIONALITY_PERMISSION_DETAIL,GET_LIAISON_PAYMENT_FILTER_PROJECT,
    GET_LIAISON_PAYMENT_FILTER_REQUESTED_BY,GET_PROJECT_NAMES1,
    GET_PROJECT_TASK_PLAN_FILTER_TASK_OWNER,GET_PROJECT_TASK_PLAN_FILTER_TASK,
    GET_LIAISON_DOCUMENT_TASK_PLAN_PENDING,GET_LIAISON_APPLICABLE_PROCESS_PLAN,
    GET_LIASON_DOCUMENT_TASK_PLAN_EXPORT,GET_DEPARTMENT,GET_LIAISON_DOCUMENT_TASK_PLAN_APPROVAL_DETAIL,GET_LIAISON_DOCUMENT_TASK_PLAN_DETAIL} from './types';

const initialStateChart = {
    labels : [],
    datasets: [],
  };
  
const initialState = {
    projectAuthority : [],
    // civilResourceTypePlanning : initialStateChart,
    // civilBudgetPlanning : initialStateChart,
    user : [],
    projectPhase : [],
    districts : [],
    taluks : [],
    hobli : [],
    villages : [],
    landOwnerVillages : [],
    projectBrokers : [],
    projectList : {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    }, 
    upComingProjectList : {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    }, 
    projectFlowList : {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    projectOwners: [],
    projectNames: [],
    projectNames1:[],
    projectTaskStatus : [],
    projectTaskDelayReason: [],
    projectTaskDocumentType : [],
    ProjectTaskPlanDetail : [],
    projectPlans: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    projectFileList: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    liasonTaskPlan: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    liasonProcessPlan: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    projectSurveyNoTaskApprovals: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    landOwnerNames: [],
    projectBdOwner: [],
    projectLegalOwner: [],
    liasonDocumentTaskPlan: [],
    notificationList: [],
    projectSurveyNoTaskPlanApprovalDetail: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    authorizationList: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },

    menuForPermissionList: [],
    getFunctionalityList: [],
    projectPlanFileList : [],
    liaisonCategoryList : [],
    fileLiaisonProcessList : [],
    taskPlanMandotoryDocumentList:[],


    //New
    landOwnerList: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },

    surveyNumberList:[],

    fileAcquisitionInfoList: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },

    fileAcquisitionModeList: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },

    fileAcquisitionInfoDetail: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    projectListWithExtent: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    feasibilityItemList: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    feasibilityReportList: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    feasibilityReportDetail:[],
    landOwnerDetail:[],
    landBrokerList: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    landBrokerDetail:[],

    liaisonDocumentCategoryList: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },

    liaisonDocumentCategoryList: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },

    projectFundSourceList: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    projectFundRequirementDetail:[],
    projectFundList: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    projectFundDetail:[],

    projectFilePaymentDetail:[],
    bdPaymentList: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },

    bdPaymentDetail: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    bdPaymentMilestone: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },

    projectBrokerList: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },

    projectBrokerDetail: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },

    projectBrokerPaymentInfo: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },

    projectLandOwner: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },

    projectOwnerSurveyNoList: {
        "error": false,
        "message": "",
        "projectFile": []
        },

        projectFileAdvancePaymentDetail: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },

    liaisonProcessPlanFile: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },

    liasonProcessPlanApplication: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    liaisonProcessPlanApplicationFileDetail: {
        "error": false,
        "message": "",
        "data": []
    },

    projectMilestone: {
        "error": false,
        "message": "",
        "data": []
    },

    bdRegistrationItem:{
        "error": false,
        "message": "",
        "data": []
    },

    subRegisterOffice:{
        "error": false,
        "message": "",
        "data": []
    },

    projectFileDocument:{
        "error": false,
        "message": "",
        "data": []
    },

    getDependentRelationship:{
        "error": false,
        "message": "",
        "relationship": []
    },

    bdPaymentAdvanceDetail: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },


    projectDropReason: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },


    companyProjectMap: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    projectDetails: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    companyDetails: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    updateProjectCompany: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },

    droppedProjectList:{
        "error":false,
        "message":"",
        "data":[],
        "totalCount":0
    },

    insertedLandOwnerData:"",
    insertedLandBrokerData:"",

    landOwnerAccountDetail:{
        "error": false,
        "message": "",
        "data": []
    },
    landBrokerAccountDetail:{
        "error": false,
        "message": "",
        "data": []
    },
    processPlanFilterOwners: [],
    processPlanApplicationFilterOwners: [],
    liaisonDocFilterOwners: [],
    userProfileDetails: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    updateEmployeeProfile: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    GetBirthDay: {
        error: false,
        message: "",
        data: [],
        totalCount: 0,
      },

    getAnniversary: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
    },
    getProjectListFilter: {
        error: false,
        message: "",
        data: [],
        totalCount: 0,
        },
    getProjectUpcomingFilter: {
        error: false,
        message: "",
        data: [],
        totalCount: 0,
    },
        getCivilProjectFilter: {
        error: false,
        message: "",
        projectData: [],
        totalCount: 0,
    },
    liasonDocumentTaskPlanApproval: [],
    projectFileFilterList:[],
    liaisonProcessFilterList:[],
    apfPanelList: {
        error: false,
        message: "",
        data: [],
    },
    apfPendingProjectFileList:{
        error: false,
        message: "",
        data: [],
    },
    apfTaskPlanList:{
        error: false,
        message: "",
        data: [],
        totalCount: 0,
    },
    getApfTaskPlanDetail:{
        error: false,
        message: "",
        data: [],
    },
    apfTaskProjectFilterList:{
        error: false,
        message: "",
        data: [],
    },    
    apfTaskPanelFilterList:{
        error: false,
        message: "",
        data: [],
    },    
    apfTaskProjectFileFilterList:{
        error: false,
        message: "",
        data: [],
    },
    apfTaskFilterList:{
        error: false,
        message: "",
        data: [],
    },
    apfTaskPlanQueryList: {
        error: false,
        message: "",
        data: [],
        totalCount: 0,
    },
    getApfTaskPlanQueryDetail:{
        error: false,
        message: "",
        data: [],
    },
    getLiaisonDocumentationReport: {
        error: false,
        message: "",
        data: [],
        totalCount: 0,
        },
    getBDLandDisputeType:{
            error: false,
            message: "",
            data: [],
        },
    getBDLandDisputeList: {
        error: false,
        message: "",
        data: [],
        totalCount: 0,
    },
    bdLandDisputeDetail:{
        error: false,
        message: "",
        data: [],
    },
    liaisonDocumentationReportProjectFilter:{
        error: false,
        message: "",
        data: [],
    },
    liaisonDocProjectFileFilter:{
        error: false,
        message: "",
        data: [],
    },
    liaisonDocReportCategoryFilter:{
        error: false,
        message: "",
        data: [],
    },
    courtCaseTypeList:{
        error: false,
        message: "",
        data: [],
    },
    courtCaseStatusList:{
        error: false,
        message: "",
        data: [],
    },
    courtCaseJurisdictionList:{
        error: false,
        message: "",
        data: [],
    },  
    directorList:{
        error: false,
        message: "",
        data: [],
    },
    courtCaseList: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    courtCaseDetail:{
        error: false,
        message: "",
        data: [],
    },
    trainingContentList:{
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    bdProjectTaskRejectionReasonList:{
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    bdAgreementTypeList:{
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    bdProjectReportList:{
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    bdProjectTaskReportList:{
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    projectProposedNameList:{
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getTraining:{
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0  
    },
    apfTaskPlanTaskFilterList:{
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getLiaisonProcessPlanProject:{
        error: false,
        message: "",
        data: [],
    },
    getLiaisonProcessPlanProcess:{
        error: false,
        message: "",
        data: [],
    },
    getLiaisonPaymentList:{
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getLiaisonPaymentDetail:{
        error: false,
        message: "",
        data: [],
    },
    getLiaisonPaymentSurveyNumber:{
        error: false,
        message: "",
        data: [],
    },
    getLiaisonPaymentTaskPlan:{
        error: false,
        message: "",
        data: [],
        
    },
    getFunctionalityPermissionDetail:{
        error: false,
        message: "",
        data: [],
    },
    getLiaisonPaymentFilterProject:{
        error: false,
        message: "",
        data: [],
    },
    getLiaisonPaymentFilterRequestedBy:{
        error: false,
        message: "",
        data: [],

    },
    liaisonDocReportOwnerFilter:{
        error: false,
        message: "",
        data: [],
    },
    projectTaskPlanFilterTaskOwner:[],
    projectTaskPlanFilterTask:[],
    liasonDocumentTaskPlanPending: [],
    getLiaisonApplicableProcessPlan:{
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    liasonDocumentTaskPlanExport: [],
    departmentList:[],
    liaisonDocumentTaskPlanApprovalDetail:{
        "error": false,
        "message": "",
        "data": []
    },
    liaisonDocumentTaskPlanDetail:{
        "error": false,
        "message": "",
        "data": []

    }


}

const Report_reducer = (state = initialState, { type, payload }) => {
    switch(type)
    {
        case GET_PROJECT_AUTHORITY:
            return Object.assign({}, state, { projectAuthority: payload});                
        case GET_BD_USER:
            return Object.assign({}, state, { user: payload});      
        case GET_PROJECT_PHASE:
            return Object.assign({}, state, { projectPhase: payload });
        case GET_DISTRICT:
            return Object.assign({}, state, { districts: payload });
        case GET_TALUK:
            return Object.assign({}, state, { taluks: payload });        
        case GET_HOBLI:
            return Object.assign({}, state, { hobli: payload });
        case GET_PROJECT_BROKERS:
            return Object.assign({}, state, { projectBrokers: payload });
        case GET_PROJECT_LIST:
            return Object.assign({}, state, { projectList: payload });

        case GET_PROJECT_PLAN_FILE_LIST:
        return Object.assign({}, state, { projectPlanFileList: payload });

        case GET_LIAISON_CATEGORY_LIST:
        return Object.assign({}, state, { liaisonCategoryList: payload });

        case GET_LIAISON_PROCESS_LIST:
        return Object.assign({}, state, { fileLiaisonProcessList: payload });

        case GET_PROJECT_TASK_PLAN_MANDOTORY_DOCUMENT:
        return Object.assign({}, state, { taskPlanMandotoryDocumentList: payload });    





    


        case GET_PROJECT_FLOW_LIST:
            return Object.assign({}, state, { projectFlowList: payload });
        case GET_VILLAGE_LIST:
            return Object.assign({}, state, { villages: payload });
        case GET_PROJECT_OWNERS_NAMES:
            return Object.assign({}, state, { projectOwners: payload });
        case GET_PROJECT_NAMES:
            return Object.assign({}, state, { projectNames: payload });        
        case GET_PROJECT_TASK_STATUS:
            return Object.assign({}, state, { projectTaskStatus: payload });
        case GET_PROJECT_DELAY_REASON:
            return Object.assign({}, state, { projectTaskDelayReason: payload });
        case GET_DOCUMENT_TYPES:
            return Object.assign({}, state, { projectTaskDocumentType: payload });
        case GET_PROJECT_TASK_PLAN_DETAILS:
            return Object.assign({}, state, { ProjectTaskPlanDetail: payload });
        case GET_PROJECT_PLAN:
            return Object.assign({}, state, { projectPlans: payload });        
        case GET_PROJECT_FILE_LIST:
            return Object.assign({}, state, { projectFileList: payload });
        case GET_LAND_OWNER_NAMES:
            return Object.assign({}, state, { landOwnerNames: payload });
        case GET_LIASON_TASK_PLAN:
            return Object.assign({}, state, { liasonTaskPlan: payload });
        case GET_LIASON_PROCESS_PLAN:
            return Object.assign({}, state, { liasonProcessPlan: payload });
        case GET_PROJECT_BD_OWNER:
            return Object.assign({}, state, { projectBdOwner: payload });
        case GET_PROJECT_LEGAL_OWNER:
            return Object.assign({}, state, { projectLegalOwner: payload });
        case GET_LIASON_DOCUMENT_TASK_PLAN:
            return Object.assign({}, state, { liasonDocumentTaskPlan: payload });
        case GET_PROJECT_SURVEY_NO_TASK_APPROVAL:
            return Object.assign({}, state, { projectSurveyNoTaskApprovals: payload });
        case GET_PROJECT_SURVEY_NO_TASK_APPROVAL_DETAILS:
            return Object.assign({}, state, { projectSurveyNoTaskPlanApprovalDetail: payload });
        case GET_MY_WEB_NOTIFICATION:
            return Object.assign({}, state, { notificationList: payload });     
        case GET_UPCOMING_PROJECT_LIST:
            return Object.assign({}, state, { upComingProjectList: payload });
        case GET_AUTHORIZATION:
            return Object.assign({}, state, { authorizationList: payload });
        case GET_MENU_PERMISSION:
            return Object.assign({}, state, { menuForPermissionList: payload });        
        case GET_FUNCTIONALITY:
            return Object.assign({}, state, { getFunctionalityList: payload });

           
        //New
        case GET_LAND_OWNER_LIST:
            return Object.assign({}, state, { landOwnerList: payload });
        case GET_SURVEY_NO_LIST:
                return Object.assign({}, state, { surveyNumberList: payload });
        case GET_SURVEY_DETAIL_LIST:
                return Object.assign({}, state, { surveyNumberDetail: payload });
        case GET_FILE_ACQUISITION_INFO_LIST:
                return Object.assign({}, state, { fileAcquisitionInfoList: payload });
        case GET_ACQUISITION_MODE_LIST:
                return Object.assign({}, state, { fileAcquisitionModeList: payload });
        case GET_ACQUISITION_INFO_DETAIL:
                return Object.assign({}, state, { fileAcquisitionInfoDetail: payload });
        case GET_PROJECT_LIST_WITH_EXTENT:
            return Object.assign({}, state, { projectListWithExtent: payload });
        case GET_FEASIBILITY_ITEM_LIST:
                return Object.assign({}, state, { feasibilityItemList: payload });
        case GET_FEASIBILITY_REPORT_LIST:
                return Object.assign({}, state, { feasibilityReportList: payload });
        case GET_FEASIBILITY_REPORT_DETAIL:
                return Object.assign({}, state, { feasibilityReportDetail: payload });
        case GET_LAND_OWNER_DETAIL:
            return Object.assign({}, state, { landOwnerDetail: payload });
        case GET_LAND_BROKER_LIST:
            return Object.assign({}, state, { landBrokerList: payload });
        case GET_LAND_BROKER_DETAIL:
                return Object.assign({}, state, { landBrokerDetail: payload });   

        case GET_LAND_OWNNER_VILLAGE_LIST:
            return Object.assign({}, state, { landOwnerVillages: payload });  

        case GET_LIAISON_DOCUMENT_CATEGORY_LIST:
                return Object.assign({}, state, { liaisonDocumentCategoryList: payload });      

        case GET_PROJECT_FUND_SOURCE_LIST:
                return Object.assign({}, state, {projectFundSourceList: payload });
        case GET_PROJECT_FUND_REQUIREMENT_DETAIL:
        return Object.assign({}, state, {projectFundRequirementDetail: payload });
        case GET_PROJECT_FUND_LIST:
            return Object.assign({}, state, {projectFundList: payload });
        case GET_PROJECT_FUND_DETAIL:
            return Object.assign({}, state, {projectFundDetail: payload });



        case GET_PROJECT_FILE_PAYEMENT_DETAIL:
            return Object.assign({}, state, {projectFilePaymentDetail: payload });
        case GET_BD_PAYMENT_LIST:
            return Object.assign({}, state, {bdPaymentList: payload });
        case GET_BD_PAYMENT_DETAIL:
            return Object.assign({}, state, {bdPaymentDetail: payload });
        case GET_BD_PAYMENT_MILESTONE:
            return Object.assign({}, state, {bdPaymentMilestone: payload });
        case GET_PROJECT_BROKER_LIST:
            return Object.assign({}, state, {projectBrokerList: payload });
        case GET_PROJECT_BROKER_DETAIL:
            return Object.assign({}, state, {projectBrokerDetail: payload });
        case GET_PROJECT_BROKER_PAYMENT_INFO:
            return Object.assign({}, state, {projectBrokerPaymentInfo: payload });
        case GET_PROJECT_LAND_OWNER:
            return Object.assign({}, state, {projectLandOwner: payload });
        case GET_PROJECT_OWNER_SURVEY_NUMBER_LIST:
            return Object.assign({}, state, {projectOwnerSurveyNoList: payload });
        case GET_PROJECT_FILE_ADVANCE_PAYMENT_DETAIL:
            return Object.assign({}, state, {projectFileAdvancePaymentDetail: payload });    

        case GET_LIAISON_PROCESS_PLAN_FILE:
            return Object.assign({}, state, {liaisonProcessPlanFile: payload });

        case GET_LIASON_PROCESS_PLAN_APPLICATION:
                return Object.assign({}, state, { liasonProcessPlanApplication: payload });

        case GET_LIAISON_PROCESS_PLAN_APPLICATION_FILE_DETAIL:
                return Object.assign({}, state, {liaisonProcessPlanApplicationFileDetail: payload });

        case GET_PROJECTC_MILESTONE:
            return Object.assign({}, state, {projectMilestone: payload });

        case GET_BD_REGISTRATION_ITEM:
            return Object.assign({}, state, {bdRegistrationItem: payload }); 

        case GET_SUBREGISTER_OFFICE:
            return Object.assign({}, state, {subRegisterOffice: payload });

        case GET_PROJECT_FILE_DOCUMENT:
            return Object.assign({}, state, {projectFileDocument: payload });   

        case GET_DEPENDENT_RELATIONSHIP:
                return Object.assign({}, state, {getDependentRelationship: payload });    

        case GET_BD_PAYMENT_ADVANCE_DETAIL:
            return Object.assign({}, state, {bdPaymentAdvanceDetail: payload });

        case GET_PROJECT_DETAIL:
            return Object.assign({}, state, {projectDetail: payload });

        case GET_PROJECT_DROP_REASON_LIST:
            return Object.assign({}, state, {projectDropReason: payload });

        case GET_COMPANY_PROJECT_MAP:
            return Object.assign({}, state, { companyProjectMap: payload });
        case GET_PROJECT:
            return Object.assign({}, state, { projectDetails: payload });
        case GET_COMPANY:
            return Object.assign({}, state, { companyDetails: payload });
        case GET_DROPPED_PROJECT_LIST:
            return Object.assign({}, state, { droppedProjectList: payload });

        case GET_INSERTED_LAND_OWNER_DATA:
            return Object.assign({}, state, { insertedLandOwnerData: payload });

        case GET_INSERTED_LAND_BROKER_DATA:
            return Object.assign({}, state, { insertedLandBrokerData: payload });
       
        case GET_LAND_OWNER_ACCOUNT_DETAIL:
            return Object.assign({}, state, { landOwnerAccountDetail: payload });
        case GET_LAND_BROKER_ACCOUNT_DETAIL:
            return Object.assign({}, state, { landBrokerAccountDetail: payload });

                 
        case GET_LIAISON_PROCESS_PLAN_FILTER_OWNER_LIST:
            return Object.assign({}, state, { processPlanFilterOwners: payload });
                
        case GET_LIAISON_PROCESS_PLAN_APPLICATION_FILTER_OWNER_LIST:
            return Object.assign({}, state, { processPlanApplicationFilterOwners: payload });

        case GET_LIAISON_DOC_TASK_PLAN_FILTER_OWNER_LIST:
            return Object.assign({}, state, { liaisonDocFilterOwners: payload });

        case GET_USER_PROFILE:
            return Object.assign({}, state, { userProfileDetails: payload });
        case UPDATE_EMPLOYEE_PROFILE:
            return Object.assign({}, state, { updateEmployeeProfile: payload });
        case GET_BIRTHDAY:
            return Object.assign({}, state, { GetBirthDay: payload });

        case GET_EMPLOYEE_WORK_ANNIVERSARY:
            return Object.assign({}, state, { getAnniversary: payload });

        case GET_PROJECT_LIST_FILTER:
            return Object.assign({}, state, { getProjectListFilter: payload });
        case GET_PROJECT_UPCOMING_FILTER:
            return Object.assign({}, state, { getProjectUpcomingFilter: payload });

        case GET_CIVIL_PROJECT_FILTER:
            return Object.assign({}, state, { getCivilProjectFilter: payload });
        case GET_LIASON_DOCUMENT_TASK_PLAN_APPROVAL:
                return Object.assign({}, state, { liasonDocumentTaskPlanApproval: payload });

        case GET_PROJECT_FILE_FILTER:
            return Object.assign({}, state, { projectFileFilterList: payload });
        
        case GET_LIAISON_PROCESS_FILTER:
            return Object.assign({}, state, { liaisonProcessFilterList: payload });
            
        case GET_APF_PANEL:
            return Object.assign({}, state, { apfPanelList: payload });
        case GET_APF_PENDING_PROJECT_FILE:
            return Object.assign({}, state, { apfPendingProjectFileList: payload });
        case GET_APF_TASK_PLAN:
            return Object.assign({}, state, { apfTaskPlanList: payload });
                          
        case GET_APF_TASK_PLAN_DETAIL:
            return Object.assign({}, state, { getApfTaskPlanDetail: payload });
        
        case GET_APF_TASK_PLAN_PROJECT_FILTER:
            return Object.assign({}, state, { apfTaskProjectFilterList: payload });
        case GET_APF_TASK_PLAN_PANEL_FILTER:
            return Object.assign({}, state, { apfTaskPanelFilterList: payload });
        case GET_APF_TASK_PLAN_PROJECT_FILE_FILTER:
            return Object.assign({}, state, { apfTaskProjectFileFilterList: payload });
        case GET_APF_TASK_FILTER:
            return Object.assign({}, state, { apfTaskFilterList: payload });
        case GET_APF_TASK_PLAN_QUERY:
            return Object.assign({}, state, { apfTaskPlanQueryList: payload });
        case GET_APF_TASK_PLAN_QUERY_DETAIL:
            return Object.assign({}, state, { getApfTaskPlanQueryDetail: payload });
        case GET_LIAISON_DOCUMENTATION_REPORT:
            return Object.assign({}, state, { getLiaisonDocumentationReport: payload });
        case GET_BD_LAND_DISPUTE_TYPE:
            return Object.assign({}, state, { getBDLandDisputeType: payload });
        case GET_BD_LAND_DISPUTE_LIST:
            return Object.assign({}, state, { getBDLandDisputeList: payload });
        case GET_BD_LAND_DISPUTE_DETAIL:
            return Object.assign({}, state, { bdLandDisputeDetail: payload });
        case GET_LIAISON_DOCUMENTATION_REPORT_PROJECT_FILTER:
            return Object.assign({}, state, { liaisonDocumentationReportProjectFilter: payload });
        case GET_LIAISON_DOCUMENTATION_REPORT_PROJECT_FILE_FILTER:
            return Object.assign({}, state, { liaisonDocReportProjectFileFilter: payload });
        case GET_LIAISON_DOCUMENTATION_REPORT_CATEGORY_FILTER:
            return Object.assign({}, state, { liaisonDocReportCategoryFilter: payload });
        case GET_COURT_CASE_TYPE:
            return Object.assign({}, state, { courtCaseTypeList: payload });
        case GET_COURT_CASE_STATUS:
            return Object.assign({}, state, { courtCaseStatusList: payload });
        case GET_COURT_CASE_JURISDICTION:
            return Object.assign({}, state, { courtCaseJurisdictionList: payload });
        case GET_DIRECTOR:
            return Object.assign({}, state, { directorList: payload });
        case GET_COURT_CASE:
            return Object.assign({}, state, { courtCaseList: payload });
        case GET_COURT_CASE_DETAIL:
            return Object.assign({}, state, { courtCaseDetail: payload });
        case GET_TRAINING_CONTENT:
            return Object.assign({}, state, { trainingContentList: payload });
        case GET_BD_PROJECT_TASK_REJECTION_REASON:
            return Object.assign({}, state, { bdProjectTaskRejectionReasonList: payload });                                                                                                                                                                                                        
        case GET_BD_AGREEMENT_TYPE:
            return Object.assign({}, state, { bdAgreementTypeList: payload });
        case GET_BD_PROJECT_REPORT:
            return Object.assign({}, state, { bdProjectReportList: payload });
        case GET_BD_PROJECT_TASK_REPORT:
            return Object.assign({}, state, { bdProjectTaskReportList: payload });
        case GET_PROJECT_PROPOSED_NAME:
            return Object.assign({}, state, { projectProposedNameList: payload });                                                                                                                                          
        case GET_TRAINING:
            return Object.assign({}, state, { getTraining: payload });
        case GET_APF_TASK_PLAN_TASK_FILTER:
            return Object.assign({}, state, { apfTaskPlanTaskFilterList: payload });
                
        case GET_LIAISON_PROCESS_PLAN_PROJECT:
            return Object.assign({}, state, { getLiaisonProcessPlanProject: payload });
        case GET_LIAISON_PROCESS_PLAN_PROCESS:
            return Object.assign({}, state, { getLiaisonProcessPlanProcess: payload });
        case GET_LIAISON_PAYMENT_LIST:
            return Object.assign({}, state, { getLiaisonPaymentList: payload });
        case GET_LIAISON_PAYMENT_DETAIL:
            return Object.assign({}, state, { getLiaisonPaymentDetail: payload });
        case GET_LIAISON_PAYMENT_SURVEY_NUMBER:
            return Object.assign({}, state, { getLiaisonPaymentSurveyNumber: payload });
        case GET_LIAISON_PAYMENT_TASK_PLAN:
            return Object.assign({}, state, { getLiaisonPaymentTaskPlan: payload });
        case GET_FUNCTIONALITY_PERMISSION_DETAIL:
            return Object.assign({}, state, { getFunctionalityPermissionDetail: payload });
        case GET_LIAISON_PAYMENT_FILTER_PROJECT:
            return Object.assign({}, state, { getLiaisonPaymentFilterProject: payload });
        case GET_LIAISON_PAYMENT_FILTER_REQUESTED_BY:
            return Object.assign({}, state, { getLiaisonPaymentFilterRequestedBy: payload }); 
        case GET_LIAISON_DOCUMENTATION_REPORT_OWNER_FILTER:
            return Object.assign({}, state, { liaisonDocReportOwnerFilter: payload });     
        
        case GET_PROJECT_NAMES1:
            return Object.assign({}, state, { projectNames1: payload });  
        case GET_PROJECT_TASK_PLAN_FILTER_TASK_OWNER:
            return Object.assign({}, state, { projectTaskPlanFilterTaskOwner: payload });
        case GET_PROJECT_TASK_PLAN_FILTER_TASK:
            return Object.assign({}, state, { projectTaskPlanFilterTask: payload });
        case GET_LIAISON_DOCUMENT_TASK_PLAN_PENDING:
            return Object.assign({}, state, { liasonDocumentTaskPlanPending: payload });
        
        case GET_LIAISON_APPLICABLE_PROCESS_PLAN:
            return Object.assign({}, state, { getLiaisonApplicableProcessPlan: payload });

        case GET_LIASON_DOCUMENT_TASK_PLAN_EXPORT:
            return Object.assign({}, state, { liasonDocumentTaskPlanExport: payload });
        
        case GET_DEPARTMENT:
            return Object.assign({}, state, { departmentList: payload });
        case GET_LIAISON_DOCUMENT_TASK_PLAN_APPROVAL_DETAIL:
            return Object.assign({}, state, { liaisonDocumentTaskPlanApprovalDetail: payload });
        case GET_LIAISON_DOCUMENT_TASK_PLAN_DETAIL:
            return Object.assign({}, state, { liaisonDocumentTaskPlanDetail: payload });
        default:  
            return state;
    }
}

export default Report_reducer;