import moment from 'moment'


export const dateFormat = (date, dateFormatType=null) =>{
    if(!dateFormatType){
        dateFormatType = 'DD-MM-yyyy'
    }
    date = moment(date);
    if(date.isValid()){
        return moment(date).format(dateFormatType)
    }else{
        return ""
    }
}
// export const dateTimeFormat = (date, dateFormatType=null) =>{
//     if(!dateFormatType){
//         dateFormatType = 'dd-MM-yyyy hh:mm a'
//     }
//     return format(date, dateFormatType);
// }

// export const timeFormat = (time, timeFormatType=null) =>{
//     console.log("=========", time)
//     if(!timeFormatType){
//         timeFormatType = 'HH:MM a'
//     }
//     return format(time, timeFormatType);
// }

// export const timeFormatFromDate = (date = null, timeFormatType=null) =>{
//     if(!timeFormatType){
//         timeFormatType = 'hh:MM'
//     }
//     if(date){
//         date = new Date()
//     }
//     return format(date, timeFormatType);
// }