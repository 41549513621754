import React, { useEffect } from 'react';
import {Container, Box, CssBaseline, Avatar, Typography, TextField, Grid, FormControlLabel, Checkbox, Button, Copyright } from '@mui/material';

import { useFormik, Formik, Form } from 'formik';
import {Link, useNavigate} from 'react-router-dom';
import * as yup from 'yup';
// import {login_action } from '../../redux/action'
import { useSelector, useDispatch } from 'react-redux';
import {userLogin_action } from '../../Redux/User'


const validationSchema = yup.object({
    // emailID: yup.string().email().required(),
        emailID: yup.string().required(),

    password : yup.string().required()
  });

const Login = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const onSubmit = async(values, {setSubmitting, resetForm}) =>{
        localStorage.clear();
        setSubmitting(true)
        await userLogin_action(values)(dispatch)
        resetForm({})
        navigate('/')
    }

    return (
        <div style={{background  : "#F4F4F4", height : "100vh", paddingTop : "10%"}}>
            <Container component="main" maxWidth="xs" sx={{background : "#fff"}}>
            <CssBaseline />
            <Box
                sx={{
                    
                    // marginTop: 8,
                    textAlign : "left",
                    background : "#FFF"
                    // display: 'flex',
                    // flexDirection: 'column',
                    // alignItems: 'center',
                    // padding : '5px'
                }}
            >
            {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            </Avatar> */}
            <img src={require("../../Icons/Logo.png")} height={110} />
            <Typography component="h1" variant="h5" sx={{textAlign : "left", fontSize : "25px", fontWeight : "bold"}}>
                Sign in
            </Typography>
            <Formik
                initialValues= {{
                    emailID : "",//"venkataramanaiah@knsgroup.in", //venkataramanaiah@knsgroup.in
                    password : ""//"respectevery0ne" //respectevery0ne
                }}
                validationSchema={validationSchema}
                onSubmit={onSubmit}>
                {({errors, handleSubmit, handleChange, handleBlur, isSubmitting, isValid, touched, values}) =>
            <Form>
            <Box noValidate sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    fullWidth
                    id="email"
                    label="Email"
                    variant="standard"
                    autoComplete="email"
                    name="emailID" onBlur={handleBlur}  onChange={handleChange} 
                    value={values.emailID}  
                    error={errors.emailID && Boolean(errors.emailID)}
                    helperText={errors.emailID && errors.emailID}
                />
                <TextField
                    margin="normal"
                    fullWidth
                    id="password"
                    label="Password"
                    variant="standard"
                    type="password"
                    autoComplete="current-password"
                    name="password" onBlur={handleBlur}  onChange={handleChange} 
                    value={values.password}  
                    error={errors.password && Boolean(errors.password)}
                    helperText={errors.password && errors.password}
                />
                <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember me"
                    sx={{color : "#343434"}}
                />
                <Grid container  
                    sx={{
                        textAlign : "left",
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <Link href="#" variant="body2" to="/register">
                            {/* <Grid item sx={{
                                textAlign : "left",
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                color : "#343434"
                            }} > */}
                                
                                {"Don't have an account? Sign Up"}
                            {/* </Grid> */}
                        </Link>
                </Grid>

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="warning"
                    sx={{ mt: 3, mb: 2, background : "#FECD2F", color : "#343434"}}
                    disabled={isSubmitting}
                >
                Sign In
                </Button>
                
            </Box>
            </Form>}
            </Formik>
            </Box>
            {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
        </Container>
        </div>
    );
};

export default Login;